import { Reducer } from 'redux';
import { AnalyticsTypes, AnalyticsState } from './types';

export const initialState: AnalyticsState = {
  records: [],
  total: 0,
  loading: true,
  moreData: {},
};

const reducer: Reducer<AnalyticsState> = (state = initialState, action) => {
  switch (action.type) {
    case AnalyticsTypes.SERVICES_REQUEST:
    case AnalyticsTypes.MASTERS_REQUEST:
    case AnalyticsTypes.CLIENTS_REQUEST: {
      return { ...state, loading: true, records: [], total: 0 };
    }
    case AnalyticsTypes.CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.payload.records,
        total: action.payload.total,
      };
    }
    case AnalyticsTypes.SERVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.payload,
        total: action.payload.length,
      };
    }
    case AnalyticsTypes.MASTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.payload.records,
        total: action.payload.total,
        moreData: action.payload.moreData || {},
      };
    }
    case AnalyticsTypes.CLIENTS_FAILURE:
    case AnalyticsTypes.CLEAN: {
      return { ...state, loading: false, records: [], total: 0 };
    }
    default: {
      return state;
    }
  }
};

export { reducer as AnalyticsReducer };
