import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { AppContextProvider } from './context/app-context';
import { history } from './store';
import { Patch } from './Patch';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { env } from './const/env';

const stripePromise = loadStripe(env.stripeKey);

export const App = () => (
  <ConnectedRouter history={history}>
    <AppContextProvider>
      <Elements stripe={stripePromise}>
        <Patch />
      </Elements>
    </AppContextProvider>
  </ConnectedRouter>
);
