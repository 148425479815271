import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table, Tag, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { tableXScroll } from '../../const';
import { AppContext } from '../../context/app-context';
import { IApplicationState } from '../../models/IApplicationState';
import Button from 'antd/es/button';
import Pricing from './Pricing';
import { SUBSCRIPTION_COLUMNS } from './constants';
import { cancelSubscription, getSubscriptions } from '../../services/subscriptions.service';
import moment from 'moment';
import { setBreadcrumbs } from '../../store/app/actions';
import { createBreadcrumb } from '../../store/app/utils';
import { getSubscriptionRequest } from '../../store/subscription/actions';
import confirm from 'antd/lib/modal/confirm';

export const Subscriptions = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mainContainerHeight } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);

  const columns = React.useMemo(() => SUBSCRIPTION_COLUMNS(t), [t]);

  const { locationId } = useSelector((state: IApplicationState) => ({
    locationId: state.location.currentLocation?._id as string,
  }));

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Subscriptions'))]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  const updateData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getSubscriptions(locationId);
      setSubscriptions(response.data);
    } catch (error) {}
    setLoading(false);
  }, [locationId, setLoading]);

  useEffect(() => {
    if (locationId) {
      updateData();
    }
  }, [locationId, updateData]);

  const handleSubscriptionCancel = () => confirm({
    content: t('Are you sure that you want to cancel this subscription?'),
    icon: <ExclamationCircleOutlined />,
    async onOk() {
      try {
        await cancelSubscription(locationId);
        await updateData();
        dispatch(getSubscriptionRequest(locationId));
      } catch (error) {}
    },
  });

  const prepareData = (subscriptions: any[]) => subscriptions.map((subscription: any) => ({
    ...subscription,
    status: (
      <Tag color={subscription.status === 'canceled' ? 'error' : 'geekblue'}>
        {subscription.status.toUpperCase()}
      </Tag>
    ),
    nextBillingDate: subscription.nextBillingDate ? moment(subscription.nextBillingDate).format('DD.MM.YYYY') : 'N/A',
    nextPaymentAmount: subscription.nextPaymentAmount ? `$${subscription.nextPaymentAmount.toFixed(2)}` : 'N/A',
    lastPaymentAmount: subscription.lastPaymentAmount ? `$${subscription.lastPaymentAmount.toFixed(2)}` : 'N/A',
    actions: (
      <div className="flex justify-content-space-between">
        {subscription.status !== 'canceled' && (
          <Button
            danger
            size="small"
            onClick={handleSubscriptionCancel}
          >
            {t('Cancel')}
          </Button>
        )}
      </div>
    ),
  }));

  return (
    <div>
      {loading ? (
        <Spin tip="Loading..." className="spin-container" />
      ) : (
        <div>
          <Pricing />
          <Table
            scroll={{ x: tableXScroll, y: mainContainerHeight }}
            loading={loading}
            rowKey={(item: any) => item._id}
            columns={columns}
            dataSource={prepareData(subscriptions)}
          />
        </div>
      )}
    </div>
  );
};
