const createTimeZone = (offset: number, name: string): TimeZone => ({ name, offset });

export const timezones: TimeZone[] = [
  createTimeZone(-720, '(GMT -12:00) Eniwetok, Kwajalein'),
  createTimeZone(-660, '(GMT -11:00) Midway Island, Samoa'),
  createTimeZone(-600, '(GMT -10:00) Hawaii'),
  createTimeZone(-570, '(GMT -9:30) Taiohae'),
  createTimeZone(-540, '(GMT -9:00) Alaska'),
  createTimeZone(-480, '(GMT -8:00) Pacific Time (US &amp; Canada)'),
  createTimeZone(-420, '(GMT -7:00) Mountain Time (US &amp; Canada)'),
  createTimeZone(-360, '(GMT -6:00) Central Time (US &amp; Canada), Mexico City'),
  createTimeZone(-300, '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima'),
  createTimeZone(-270, '(GMT -4:30) Caracas'),
  createTimeZone(-240, '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz'),
  createTimeZone(-210, '(GMT -3:30) Newfoundland'),
  createTimeZone(-180, '(GMT -3:00) Brazil, Buenos Aires, Georgetown'),
  createTimeZone(-120, '(GMT -2:00) Mid-Atlantic'),
  createTimeZone(-60, '(GMT -1:00) Azores, Cape Verde Islands'),
  createTimeZone(0, '(GMT) Western Europe Time, London, Lisbon, Casablanca'),
  createTimeZone(60, '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris'),
  createTimeZone(120, '(GMT +2:00) Kaliningrad, South Africa'),
  createTimeZone(180, '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg'),
  createTimeZone(210, '(GMT +3:30) Tehran'),
  createTimeZone(240, '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi'),
  createTimeZone(270, '(GMT +4:30) Kabul'),
  createTimeZone(300, '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent'),
  createTimeZone(330, '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'),
  createTimeZone(345, '(GMT +5:45) Kathmandu, Pokhara'),
  createTimeZone(360, '(GMT +6:00) Almaty, Dhaka, Colombo'),
  createTimeZone(390, '(GMT +6:30) Yangon, Mandalay'),
  createTimeZone(420, '(GMT +7:00) Bangkok, Hanoi, Jakarta'),
  createTimeZone(480, '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong'),
  createTimeZone(525, '(GMT +8:45) Eucla'),
  createTimeZone(540, '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk'),
  createTimeZone(570, '(GMT +9:30) Adelaide, Darwin'),
  createTimeZone(600, '(GMT +10:00) Eastern Australia, Guam, Vladivostok'),
  createTimeZone(630, '(GMT +10:30) Lord Howe Island'),
  createTimeZone(660, '(GMT +11:00) Magadan, Solomon Islands, New Caledonia'),
  createTimeZone(690, '(GMT +11:30) Norfolk Island'),
  createTimeZone(720, '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka'),
  createTimeZone(765, '(GMT +12:45) Chatham Islands'),
  createTimeZone(780, '(GMT +13:00) Apia, Nukualofa'),
  createTimeZone(840, '(GMT +14:00) Line Islands, Tokelau'),
];

interface TimeZone {
  name: string;
  offset: number;
}
