import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Drawer, List, Popover, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getLogsRequest } from '../../store/log/actions';
import { IApplicationState } from '../../models/IApplicationState';
import moment from 'moment';
import { capitalize, getTimeFormat } from '../../services/utils';

type Props = {};

const LogsDrawer: React.FC<Props> = () => {
  const [state, setState] = useState({
    visible: false,
  });

  const dispatch = useDispatch();

  const { loading, logs, total, locationId, is12H } = useSelector((state: IApplicationState) => ({
    loading: state.log.loading,
    logs: state.log.logs,
    total: state.log.total,
    locationId: state.location.currentLocation?._id,
    is12H: state.location.currentLocation?.is12H,
  }));

  const timeFormat = useMemo(() => getTimeFormat(is12H), [is12H]);

  useEffect(() => {
    if (!locationId) {
      return;
    }
    dispatch(getLogsRequest());
    // eslint-disable-next-line
  }, [locationId]);

  const { t } = useTranslation();

  const showDrawer = () => {
    setState({ ...state, visible: true });
  };

  const onClose = () => {
    setState({ ...state, visible: false });
  };

  const loadMore =
    !loading && total !== logs.length ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={() => dispatch(getLogsRequest())}>{t('Download more')}</Button>
      </div>
    ) : null;

  return (
    <>
      <Button
        className="ml-2"
        type="primary"
        onClick={showDrawer}
      >
        {t('Reservation changes')}
      </Button>
      <Drawer title={t('Reservation changes')} placement="right" onClose={onClose} width={350} visible={state.visible}>
        <Spin spinning={loading}>
          {logs.map((log) => (
            <List key={log._id} style={{ margin: 16 }} size="small" header={null} footer={null} bordered>
              {!log.updateBy && (
                <ListItem style={{ justifyContent: 'center' }}>
                  <Typography.Text mark>{t(log.isGroup ? 'New group reservation' : 'New reservation')}</Typography.Text>
                  <span></span>
                </ListItem>
              )}

              <ListItem>
                <Typography.Text>{t('Date of reservation')}</Typography.Text>
                {!(log.data && log.data.from.reservedAt) ? (
                  <span>{moment(log.reservation.reservedAt).format(`${timeFormat}, DD MMMM YYYY`)}</span>
                ) : (
                  <ReservationUpdatedData>
                    <Typography.Text delete>
                      {moment(log.data.from.reservedAt).format(`${timeFormat}, DD MMMM YYYY`)}
                    </Typography.Text>
                    <Typography.Text> {moment(log.data.to.reservedAt).format(`${timeFormat}, DD MMMM YYYY`)}</Typography.Text>
                  </ReservationUpdatedData>
                )}
              </ListItem>

              {!(log.data && log.data.from.status) ? null : (
                <ListItem>
                  <Typography.Text>{t('Status')}</Typography.Text>
                  <ReservationUpdatedData>
                    <Typography.Text delete>{t(log.data.from.status)}</Typography.Text>
                    <Typography.Text>{t(log.data.to.status)}</Typography.Text>
                  </ReservationUpdatedData>
                </ListItem>
              )}

              {!(log.data && log.data.from.comment) ? null : (
                <ListItem>
                  <Typography.Text>{t('Comment or additional information')}</Typography.Text>
                  <ReservationUpdatedData>
                    <Typography.Text delete>{t(log.data.from.comment)}</Typography.Text>
                    <Typography.Text>{t(log.data.to.comment)}</Typography.Text>
                  </ReservationUpdatedData>
                </ListItem>
              )}

              {!(log.data && log.data.from.discounts) ? null : (
                <ListItem>
                  <Typography.Text>{t('Discounts')}</Typography.Text>
                  <ReservationUpdatedData>
                    <Typography.Text delete>
                      {log.data.from.discounts.map((d: any) => d.name).toString()}
                    </Typography.Text>
                    <Typography.Text>{log.data.to.discounts.map((d: any) => d.name).toString()}</Typography.Text>
                  </ReservationUpdatedData>
                </ListItem>
              )}

              <ListItem>
                <Typography.Text>{t('Master')}</Typography.Text>
                {!(log.data && log.data.from.master) ? (
                  <Popover
                    content={
                      <ClearParagraph
                        copyable={{
                          text: `+${log.reservation.master?.countryCode}${log.reservation.master?.phone}`,
                        }}
                      >
                        {`+${log.reservation.master?.countryCode}${log.reservation.master?.phone}`}
                      </ClearParagraph>
                    }
                    title={t('Phone')}
                    trigger="click"
                  >
                    <ClearParagraph>{log.reservation.master?.name}</ClearParagraph>
                  </Popover>
                ) : (
                  <ReservationUpdatedData>
                    <Popover
                      content={
                        <ClearParagraph
                          copyable={{
                            text: `+${log.data.from.master.countryCode}${log.data.from.master.phone}`,
                          }}
                        >
                          {`+${log.data.from.master.countryCode}${log.data.from.master.phone}`}
                        </ClearParagraph>
                      }
                      title={t('Phone')}
                      trigger="click"
                    >
                      <ClearParagraph delete>{log.data.from.master.name}</ClearParagraph>
                    </Popover>
                    <Popover
                      content={
                        <ClearParagraph
                          copyable={{
                            text: `+${log.data.to.master.countryCode}${log.data.to.master.phone}`,
                          }}
                        >
                          {`+${log.data.to.master.countryCode}${log.data.to.master.phone}`}
                        </ClearParagraph>
                      }
                      title={t('Phone')}
                      trigger="click"
                    >
                      <ClearParagraph>{log.data.to.master.name}</ClearParagraph>
                    </Popover>
                  </ReservationUpdatedData>
                )}
              </ListItem>

              {log.reservation && (
                <ListItem>
                  <Typography.Text>{t(log.reservation.client ? 'Customer' : 'Clients')}</Typography.Text>
                  <span>
                    {log.reservation.clients ? (
                      log.reservation.clients.map((c: any, index: number) => {
                        return (
                          <Popover
                            key={index}
                            content={
                              <ClearParagraph
                                copyable={{
                                  text: `+${c.client.countryCode}${c.client.phone}`,
                                }}
                              >{`+${c.client.countryCode}${c.client.phone}`}</ClearParagraph>
                            }
                            title={t('Phone')}
                            trigger="click"
                          >
                            <ClearParagraph>{c.client.name}</ClearParagraph>
                          </Popover>
                        );
                      })
                    ) : (
                      <Popover
                        content={
                          <ClearParagraph
                            copyable={{
                              text: `+${log.reservation.client.countryCode}${log.reservation.client.countryCode}`,
                            }}
                          >{`+${log.reservation.client.countryCode}${log.reservation.client.countryCode}`}</ClearParagraph>
                        }
                        title={t('Phone')}
                        trigger="click"
                      >
                        <ClearParagraph>{capitalize(log.reservation.client.name)}</ClearParagraph>
                      </Popover>
                    )}
                  </span>
                </ListItem>
              )}

              <ListItem>
                <Typography.Text>{t('Service')}</Typography.Text>
                {!(log.data && log.data.from.service) ? (
                  <span>{log.reservation?.service?.name}</span>
                ) : (
                  <ReservationUpdatedData>
                    <Typography.Text delete>{log.data.from.service.name}</Typography.Text>
                    <Typography.Text>{log.data.to.service.name}</Typography.Text>
                  </ReservationUpdatedData>
                )}
              </ListItem>

              {!(log.data && log.data.from.duration) ? null : (
                <ListItem>
                  <Typography.Text>{t('Duration')}</Typography.Text>
                  <ReservationUpdatedData>
                    <Typography.Text delete>
                      {moment('2015-01-01').startOf('day').minutes(log.data.from.duration).format('HH:mm')}
                    </Typography.Text>
                    <Typography.Text>
                      {moment('2015-01-01').startOf('day').minutes(log.data.to.duration).format('HH:mm')}
                    </Typography.Text>
                  </ReservationUpdatedData>
                </ListItem>
              )}

              {!(log.data && log.data.from.pauseAfter) ? null : (
                <ListItem>
                  <Typography.Text>{t('Pause after')}</Typography.Text>
                  <ReservationUpdatedData>
                    <Typography.Text delete>
                      {moment('2015-01-01').startOf('day').minutes(log.data.from.pauseAfter).format('HH:mm')}
                    </Typography.Text>
                    <Typography.Text>
                      {moment('2015-01-01').startOf('day').minutes(log.data.to.pauseAfter).format('HH:mm')}
                    </Typography.Text>
                  </ReservationUpdatedData>
                </ListItem>
              )}

              {log.updateBy && (
                <ListItem>
                  <Typography.Text>{t('Modified by')}</Typography.Text>

                  {log.updateBy && log.updateBy.name && (
                    <Popover
                      content={
                        <ClearParagraph
                          copyable={{
                            text: `+${log.updateBy.countryCode}${log.updateBy.phone}`,
                          }}
                        >
                          {`+${log.updateBy.countryCode}${log.updateBy.phone}`}
                        </ClearParagraph>
                      }
                      title={t('Phone')}
                      trigger="click"
                    >
                      <ClearParagraph>{log.updateBy.name}</ClearParagraph>
                    </Popover>
                  )}
                </ListItem>
              )}

              <ListItem>
                <Typography.Text>{t('Date of change')}</Typography.Text>{' '}
                <span>{moment(log.createdAt).format(`${timeFormat}, DD MMMM YYYY`)}</span>
              </ListItem>
            </List>
          ))}

          {loadMore}
        </Spin>
      </Drawer>
    </>
  );
};

export default LogsDrawer;

const { Paragraph } = Typography;

export const ClearParagraph = styled(Paragraph)`
  margin-bottom: 0px !important;
`;

export const ListItem = styled(List.Item)`
  display: flex;
  align-items: end;
`;

export const ReservationUpdatedData = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
`;
