import { Button, Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetSettingsStepForm } from '../WidgetSettingsStepForm/WidgetSettingsStepForm';

export const WidgetSettingsStep = (props: WidgetSettingsStepProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      about: props.model.about,
      name: props.model.name,
      style: props.model.style,
    });
  }, []);

  const handleSubmit = () => props.onNext();
  const handleBack = () => props.onBack();

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Row gutter={16}>
        <WidgetSettingsStepForm onChange={props.onChange} />
        <Col className="text-right" span={24}>
          <Button className="mr-2" type="primary" onClick={handleBack}>{t('Back')}</Button>
          <Button type="primary" htmlType="submit">{t('Next')}</Button>
        </Col>
      </Row>
    </Form>
  );
};

interface WidgetSettingsStepProps {
  model: any;
  onBack: () => void;
  onChange: (payload: any) => void;
  onNext: () => void;
}
