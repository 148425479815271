import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Modal, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import styled from 'styled-components';
import { userClass } from '../../const';
import { createUserRequest, updateUserRequest } from '../../store/user/actions';
import { IUser, IUserInput, IUserUpdateInput } from '../../models/IUser';
import { CountriesFormItem } from '../../components/CountriesFormItem/CountriesFormItem';
import { capitalize } from 'lodash';

type Props = {
  user: IUser | null;
  onCloseHandler: () => void;
  noButton?: boolean;
};
const AddNewClient: React.FC<Props> = ({ user, onCloseHandler, noButton }) => {
  const dispatch = useDispatch();

  const [modalVisibility, setModalVisibility] = useState(false);

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const { loading, discounts, locationId } = useSelector((state: IApplicationState) => ({
    loading: state.user.loading,
    discounts: state.discount.discounts,
    locationId: state.location.currentLocation?._id,
  }));

  useEffect(() => {
    if (!loading) onCancelHandler();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        name: user.name,
        email: user.email,
        phone: user.phone,
        comment: user.comment,
        countryCode: user.countryCode,
        discounts: [],
        // discounts: (user.discounts as IDiscount[]).map((discount: IDiscount) => discount._id),
        class: user.class,
      });
      setModalVisibility(true);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setModalVisibility(false);
    return form.resetFields;
    // eslint-disable-next-line
  }, []);

  const openModalHandler = () => setModalVisibility(true);
  const onCancelHandler = () => {
    if (!loading) {
      form.resetFields();
      setModalVisibility(false);
      onCloseHandler();
    }
  };

  const onFinish = () => {
    const client = form.getFieldsValue();
    if (user) {
      dispatch(updateUserRequest(client as IUserUpdateInput, user._id));
    } else {
      dispatch(createUserRequest(client as IUserInput, locationId as string));
    }
  };

  const banUserHandler = () => {
    const client = form.getFieldsValue();

    dispatch(updateUserRequest({ ...client, ban: !user?.ban } as IUserUpdateInput, user?._id as string));
  };

  return (
    <>
      {!noButton && (
        <Button
          style={{ width: '100%' }}
          className={'phone-button-margin-full'}
          type="primary"
          onClick={openModalHandler}
        >
          {t('Create a customer')}
        </Button>
      )}

      {!modalVisibility && <Form form={form} />}

      <Modal
        title={user ? user.name : t('New customer')}
        visible={modalVisibility}
        onCancel={onCancelHandler}
        okText={t('Create')}
        cancelText={t('Cancel')}
        footer={null}
      >
        <Spin spinning={loading}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item name="name" label={t('Name')} rules={[{ required: true, message: t('Required field') }]}>
              <Input placeholder={t('Enter customer name')} />
            </Form.Item>

            <CountriesFormItem />

            <Form.Item name="email" label={t('E-mail')} rules={[{ type: 'email', message: t('Empty field or wrong data') }]}>
              <Input placeholder={t('Enter customer email')} />
            </Form.Item>

            <Form.Item name="subscription" valuePropName="checked">
              <Checkbox>{t('Subscription')}</Checkbox>
            </Form.Item>

            <Form.Item name="class" label={t('Account type')}>
              <Select allowClear placeholder={t('Select account type')}>
                {userClass.map((cls: string, index) => (
                  <Select.Option key={index} value={cls}>
                    {t(capitalize(cls))}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="discounts" label={t('Discounts')}>
              <Select
                mode="multiple"
                optionLabelProp="label"
                placeholder={t('Select discounts')}
                style={{ width: '100%' }}
              >
                {discounts
                  .filter((discount) => discount.applyWhen === 'everyTime')
                  .map((discount, index) => (
                    <Select.Option key={index} value={discount._id} label={discount.name}>
                      {discount.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item name="comment" label={t('Comment or additional information')}>
              <Input.TextArea rows={2} />
            </Form.Item>

            <RightRow>
              {user && (
                <Button danger={!user.ban} type="primary" style={{ margin: '0 8px' }} onClick={banUserHandler}>
                  {t(user.ban ? 'Unban' : 'Ban')}
                </Button>
              )}
              <Button loading={loading} htmlType="submit" style={{ margin: '0 8px' }} type="primary">
                {t(user ? 'Update' : 'Create')}
              </Button>
            </RightRow>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AddNewClient;

const RightRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;
