import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import {
  reservationRequest,
  setUpDate,
  updateGroupReservationRequest,
  updateReservationRequest,
} from '../../store/reservation/actions';
import * as BigCalendar from 'react-big-calendar';
import './Calendar.css';
import moment from 'moment';
import { Spin, Modal, Result, notification, message } from 'antd';
import { IMaster } from '../../models/IUser';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { IWorkingHours } from '../../models/IWorkingHours';
import { capitalize, getTimeFormat } from '../../services/utils';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getServicesRequest } from '../../store/service/actions';
import { getDiscountsRequest } from '../../store/discount/actions';
import { ExclamationCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { ResourceCalendarHeader } from './ResourceHeader/CalendarResourceHeader';
import { pick } from 'lodash';
import { setBreadcrumbs } from '../../store/app/actions';
import { createBreadcrumb } from '../../store/app/utils';
import { Toolbar } from './Toolbar/Toolbar';
import { NewReservation } from './NewReservation';

const { confirm } = Modal;

const localizer: any = BigCalendar.momentLocalizer(moment); // or globalizeLocalize

const DragAndDropCalendar: any = withDragAndDrop(BigCalendar.Calendar as any);

const CalendarCard = styled.div``;
const CalendarCardTitle = styled.p`
  margin-bottom: 3px;
`;

const Calendar: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const plan = searchParams.get('plan');
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [masters, setMasters] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [locationWorkingTime, setLocationWorkingTime] = useState(null as any);

  const [reservationId, setReservationId] = useState<string | null>(null);

  const localUtcOffset = useMemo(() => moment().utcOffset(), []);

  const { date, locationId, mastersReservations, loading, workingHours, discounts, services, user, locationUtcOffset, is12H } = useSelector(
    (state: IApplicationState) => ({
      date: state.reservation.date,
      loading: state.reservation.loading || state.discount.loading || state.service.loading,
      discounts: state.discount.discounts,
      services: state.service.services,
      workingHours: state.location.currentLocation?.workingHours as IWorkingHours,
      mastersReservations: state.reservation.masterData,
      locationId: state.location.currentLocation?._id as string,
      user: state.auth.user,
      locationUtcOffset: state.location.currentLocation?.utcOffset || 0,
      is12H: state.location.currentLocation?.is12H,
    })
  );

  const timeFormat = useMemo(() => getTimeFormat(is12H), [is12H]);

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Calendar'))]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    if (!plan) {
      return
    }
    const Paddle = window.Paddle;
    Paddle.Checkout.open({
      product: plan,
      email: user?.email,
      passthrough: JSON.stringify({
        masterId: user?._id,
        locationId: locationId,
      }),
      closeCallback: () => {
        history.push(window.location.pathname);
      },
      successCallback: () => {
        message.success(t('You have subscribed successfully.'));
      },
      success: `${window.location.origin}/calendar`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  useEffect(() => {
    if (workingHours) {
      const day: string = moment(date).locale('en').format('dddd').toLocaleLowerCase();
      // @ts-ignore
      const wk = workingHours[day.toLocaleLowerCase()];

      if (wk) {
        let start, end;
        if (wk.length === 1) {
          [start, end] = wk[0].split('-');
        } else {
          const [from, to] = wk;
          start = from.split('-')[0];
          end = to.split('-')[1];
        }

        const startDate = moment(start, 'HH:mm');
        const endDate = moment(end, 'HH:mm');

        setLocationWorkingTime({
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
        });
      } else {
        setLocationWorkingTime(null);
      }
    }

    if (locationId) {
      if (discounts.length === 0) dispatch(getDiscountsRequest(locationId as string));
      if (services.length === 0) dispatch(getServicesRequest(locationId as string));
      if (date) dispatch(reservationRequest(date, locationId));
    }
    // eslint-disable-next-line
  }, [date, locationId]);

  useEffect(() => {
    const masters: any = [];
    const reservations: any = [];

    mastersReservations.forEach((master: IMaster) => {
      masters.push(pick(master, '_id', 'name', 'specialization', 'avatarUrl'));

      master.reservations.forEach((reservation: any) => {
        const groupPersons = reservation.clients
          ? `${reservation.clients.length} / ${reservation.service.maxPerson}`
          : '';

        reservations.push({
          id: reservation._id,
          status: reservation.status,
          comment: reservation.comment,
          title: capitalize(reservation.service.name),
          subTitle: reservation.clients ? `` : reservation.client.name,
          isGroup: reservation.clients !== undefined,
          groupPersons: reservation.cancelIfNoRequiredClients
            ? groupPersons + ` ( Min ${reservation.service.minPerson} )`
            : groupPersons,
          duration: reservation.duration,
          pauseAfter: reservation.pauseAfter,
          start: moment.utc(reservation.reservedAt).subtract(localUtcOffset - locationUtcOffset, 'minutes').toDate(),
          end: moment(reservation.reservedAt)
            .add(reservation.duration, 'minutes')
            .add(reservation.pauseAfter, 'minutes')
            .subtract(localUtcOffset - locationUtcOffset, 'minutes')
            .toDate(),
          resourceId: master._id,
        });
      });
    });
    setMasters(masters);
    setReservations(reservations);

    // eslint-disable-next-line
  }, [mastersReservations]);

  const handleSelect = (event: any) => {
    if (event.isGroup) {
      history.push('/calendar/group-reservation?id=' + event.id);
    } else {
      setReservationId(event.id);
    }
  };

  const onDrop = (event: any) => {
    if (event && event.event && event.event.status === 'done') {
      notification.warn({
        message: t('Warning'),
        description: t('It is impossible to change the completed reservation.'),
      });
      return;
    }
    confirm({
      title: t('Are you sure you want to change this reservation?'),
      icon: <ExclamationCircleOutlined />,
      okText: t('Yes'),
      cancelText: t('No'),
      onOk() {
        let masterId: any = event.event.resourceId;

        const reservationData: any = {
          reservedAt: moment(event.start).add(localUtcOffset - locationUtcOffset, 'minutes').toDate(),
        };

        if (event.resourceId !== event.event.resourceId) {
          reservationData.master = event.resourceId;
        }

        if (event.event.isGroup) {
          dispatch(updateGroupReservationRequest(reservationData, event.event.id, masterId as string));
        } else {
          dispatch(updateReservationRequest(reservationData, event.event.id, masterId as string));
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleCalendarNavigate = (date: Date) => dispatch(setUpDate(date.toISOString()));

  const handleUpdate = (reservation: any, reservationId: string, originMasterId: string) => {
    dispatch(updateReservationRequest(reservation, reservationId, originMasterId as string));
  };

  return (
    <>
      <NewReservation
        onUpdate={handleUpdate}
        reservationId={reservationId}
        onCloseHandler={() => setTimeout(() => setReservationId(null), 500)}
      />
      <div className={!locationWorkingTime ? 'calendar--empty' : ''}>
        <Spin spinning={loading}>
          <DragAndDropCalendar
            onNavigate={handleCalendarNavigate}
            selectable={false}
            onEventDrop={onDrop}
            events={reservations}
            localizer={localizer}
            defaultView={'day'}
            views={['day']}
            step={15}
            min={locationWorkingTime?.startDate || new Date()}
            max={locationWorkingTime?.endDate || new  Date()}
            components={{
              event: CustomEvent,
              eventWrapper: CustomEventWrapper,
              resourceHeader: ResourceCalendarHeader,
              toolbar: (props: BigCalendar.ToolbarProps) => (
                <Toolbar
                  {...props}
                  reservationId={reservationId}
                  onReservationIdChange={setReservationId}
                />
              ),
            }}
            formats={{
              eventTimeRangeFormat: (range: BigCalendar.DateRange) =>
                `${moment(range.start).format(timeFormat)} — ${moment(range.end).format(timeFormat)}`,
              timeGutterFormat: (date: Date) => moment(date).format(timeFormat),
            }}
            onSelectEvent={handleSelect}
            date={new Date(date)}
            resources={masters}
            resourceIdAccessor="_id"
          />
          {!locationWorkingTime && (
            <Result status="warning" title={t('Today we are closed')} />
          )}
        </Spin>
      </div>
    </>
  );
};

export default Calendar;

const CustomEvent: React.ComponentType = (props): any => {
  const anyProps: any = props;
  const { t } = useTranslation();

  return (
    <CalendarCard>
      <CalendarCardTitle>
        {t('Service')}: {anyProps.title}{' '}
        {anyProps.event.isGroup && (
          <React.Fragment>
            <UsergroupAddOutlined /> {anyProps.event.groupPersons}
          </React.Fragment>
        )}{' '}
      </CalendarCardTitle>
    </CalendarCard>
  );
};

const CustomEventWrapper: React.ComponentType = (props): any => {
  const anyProps: any = props;
  return (
    <div
      className={`custom-reservation custom-reservation-${anyProps.event.status} ${
        anyProps.selected ? 'custom-reservation-selected' : ''
      }`}
    >
      {anyProps.children}
    </div>
  );
};
