import { MasterActionTypes } from './types';
import { IMaster, IMasterUpdateInput } from '../../models/IUser';
import { IFeedback } from '../../models/IFeedback';
import { IQuery } from '../../models/IQuery';

type Error = string;

export const masterInfoSuccess = (data: IMaster, feedbacks?: IFeedback[]) => ({
  type: MasterActionTypes.MASTER_INFO_SUCCESS,
  payload: { master: data, feedbacks },
});

export const masterInfoRequest = (masterId: string) => ({
  type: MasterActionTypes.MASTER_INFO_REQUEST,
  payload: masterId,
});

export const deleteFeedbackSuccess = (feedbackId: string) => ({
  type: MasterActionTypes.DELETE_FEEDBACK_SUCCESS,
  payload: feedbackId,
});

export const deleteFeedbackRequest = (feedbackId: string) => ({
  type: MasterActionTypes.DELETE_FEEDBACK_REQUEST,
  payload: feedbackId,
});

export const updateMasterRequest = (master: IMasterUpdateInput, masterId: string) => ({
  type: MasterActionTypes.UPDATE_MASTER_REQUEST,
  payload: {
    master,
    masterId,
  },
});

export const deleteMasterRequest = (masterId: string, locationId: string) => ({
  type: MasterActionTypes.DELETE_MASTER_REQUEST,
  payload: { masterId, locationId },
});

export const onMasterRequestFailure = (data: Error) => ({
  type: MasterActionTypes.MASTER_REQUEST_FAILURE,
  payload: data,
});

export const searchMastersRequest = (locationId: string, pagination: IQuery, filterBy: any, queryData: any) => ({
  type: MasterActionTypes.SEARCH_MASTERS_REQUEST,
  payload: {
    locationId,
    pagination,
    filterBy,
    queryData,
  },
});

export const searchMastersSuccess = (masters: IMaster[], total: number) => ({
  type: MasterActionTypes.SEARCH_MASTERS_SUCCESS,
  payload: { masters, total },
});
