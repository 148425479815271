import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IApplicationState } from '../../models/IApplicationState';
import { IAuthCredential } from '../../models/IAuthCredential';
import { Button, Card, Form, Input, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import UserService from '../../services/user-service';

const userAuthService = new UserService();

const ResetPassword = (): JSX.Element => {
  const { t } = useTranslation();

  const [token, setToken] = useState<string>('');
  const history = useHistory();

  const loading: boolean = useSelector((state: IApplicationState) => state.auth.loading);

  const resetPasswordHandler = async (credential: IAuthCredential) => {
    try {
      const res = await userAuthService.resetPasswordRequest(credential.password || '', token);

      if (res.data.success) {
        message.success(t('Password has reset successfully.'));
        setTimeout(() => history.push('/auth?login'), 2000);
      }
    } catch (error) {
      console.log('error while requesting reset password api', (error as any).message);
    }
  };

  const onFinish = (values: any) => {
    resetPasswordHandler(values);
  };

  useEffect(() => {
    const search = window.location.search;
    const token = search.split('=');

    setToken(token[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card
        style={{
          minWidth: 500,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Spin spinning={loading}>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="password"
              label={t('Password')}
              rules={[
                {
                  required: true,
                  message: t('Please enter your password.'),
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder={t('Enter new password')} />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={t('Confirm Password')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('Please confirm your password.'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t('Enter new password again')} />
            </Form.Item>

            <Form.Item
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button type="primary" htmlType="submit">
                {t('Submit')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </>
  );
};

export default ResetPassword;
