import { CheckOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { minTableCellWidth, tableXScroll } from '../../../const';
import { AppContext } from '../../../context/app-context';
import { IServiceCategory } from '../../../models/IService';
import { deleteServiceCategory, getServiceCategories } from '../../../services/services.service';
import { setBreadcrumbs } from '../../../store/app/actions';
import { createBreadcrumb } from '../../../store/app/utils';
import { ServiceCategoryDialog } from '../ServiceCategoryDialog/ServiceCategoryDialog';

export const ServiceCategories = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mainContainerHeight } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState<IServiceCategory | null>(null);
  const [categoryDialogVisible, setCategoryDialogVisible] = useState(false);

  const updateData = useCallback(() => {
    setLoading(true);
    getServiceCategories()
      .then(response => setCategories(response.data))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }, [setLoading, setCategories]);

  const handleCategoryEdit = (category: IServiceCategory) => {
    if (category.userId) {
      setCategory(category);
      setCategoryDialogVisible(true);
    }
  };

  const handleCategoryDelete = (category: IServiceCategory) => {
    if (category.userId) {
      confirm({
        content: t('Are you sure that you want to delete this category?'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteServiceCategory(category._id)
            .then(() => updateData());
        },
      });
    }
  };

  const columns = useMemo(() => [
    {
      title: t('Category'),
      width: minTableCellWidth,
      dataIndex: 'name',
    },
    {
      title: t('Predefined'),
      width: minTableCellWidth,
      render: (category: IServiceCategory) => !category.userId && <CheckOutlined />,
    },
    {
      title: t('Operations'),
      width: minTableCellWidth,
      key: 'operations',
      render: (category: IServiceCategory) => (
        <>
          <Tooltip placement="top" title={t('Edit')}>
            <Button
              className="mr-1"
              disabled={!category.userId}
              icon={<EditOutlined />}
              shape="circle"
              type="primary"
              onClick={() => handleCategoryEdit(category)}
            />
          </Tooltip>
          <Tooltip placement="top" title={t('Delete')}>
            <Button
              danger
              disabled={!category.userId}
              icon={<DeleteOutlined />}
              shape="circle"
              onClick={() => handleCategoryDelete(category)}
            />
          </Tooltip>
        </>
      ),
    },
  ], []);

  useEffect(() => updateData(), []);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      createBreadcrumb(t('Services'), '/services'),
      createBreadcrumb(t('Service categories')),
    ]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  const handleAddCategoryClick = () => setCategoryDialogVisible(true);

  const handleCategoryDialogClose = () => {
    setCategoryDialogVisible(false);
    setCategory(null);
  };

  const handleCategorySave = () => updateData();

  return (
    <>
      <ServiceCategoryDialog
        category={category}
        visible={categoryDialogVisible}
        onClose={handleCategoryDialogClose}
        onSave={handleCategorySave}
      />
      <Row>
        <Col span={24} className={window.innerWidth <= 800 ? 'mb-3' : ''}>
          <Button
            className="phone-button-margin-full"
            type="primary"
            onClick={handleAddCategoryClick}
          >
            {t('Add a new category')}
          </Button>
        </Col>
        <Col className="mt-3" span={24}>
          <Table
            style={{ maxHeight: mainContainerHeight - 300 }}
            scroll={{ x: tableXScroll, y: mainContainerHeight }}
            size="small"
            loading={loading}
            rowKey={(item: any) => item._id}
            columns={columns}
            dataSource={categories}
          />
        </Col>
      </Row>
    </>
  );
};
