import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { APP_AUTH_DATA_TOKEN } from './const';
import { Router } from './Router';
import { noToken, onLoadRequest } from './store/auth/actions';

export const Patch = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem(APP_AUTH_DATA_TOKEN);
    if (token) {
      dispatch(onLoadRequest(token));
    } else {
      dispatch(noToken());
    }
  }, [dispatch]);

  return <Router />;
};
