import React, { useEffect } from 'react';
import { Form, Button, Col, Row, Card, Input } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { updateLocationRequest } from '../../store/location/actions';
import { ILocationUpdateInput } from '../../models/ILocation';
import { CountriesFormItem } from '../../components/CountriesFormItem/CountriesFormItem';

type Props = {};

const ContactInformation: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { location } = useSelector((state: IApplicationState) => ({
    location: state.location.currentLocation,
  }));

  useEffect(() => {
    if (!location) return;
    form.setFieldsValue({
      emails: location.emails,
      phones: location.phones,
    });
    // eslint-disable-next-line
  }, [location]);

  const onFinish = (values: any) =>
    dispatch(updateLocationRequest(values as ILocationUpdateInput, location?._id as string));

  return (
    <Wrapper>
      <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
        <Form.List name="emails">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Row key={field.key} gutter={16}>
                    <Col span={window.innerWidth >= 800 ? 11 : 24}>
                      <Form.Item
                        {...field}
                        label={t('E-mail')}
                        name={[field.name, 'email']}
                        fieldKey={['email']}
                        rules={[
                          {
                            type: 'email',
                            required: true,
                            message: t('Required field'),
                          },
                        ]}
                      >
                        <Input placeholder={t('Enter an email')} />
                      </Form.Item>
                    </Col>

                    <Col span={window.innerWidth >= 800 ? 11 : 22}>
                      <Form.Item
                        {...field}
                        label={t('E-mail type')}
                        name={[field.name, 'type']}
                        fieldKey={['type']}
                        rules={[{ required: true, message: t('Required field') }]}
                      >
                        <Input placeholder={t('Enter an email type')} />
                      </Form.Item>
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                      span={2}
                    >
                      <RemoveItemIcon
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Col>
                  </Row>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    <PlusOutlined /> {t('Add e-mail')}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Form.List name="phones">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Row key={field.key} gutter={16}>
                    <Col span={window.innerWidth >= 800 ? 11 : 24}>
                      <CountriesFormItem
                        fieldKey={['phone']}
                        countryCodeName={[field.name as unknown as string, 'countryCode']}
                        phoneNumberName={[field.name as unknown as string, 'phone']}
                      />
                    </Col>

                    <Col span={window.innerWidth >= 800 ? 11 : 22}>
                      <Form.Item
                        {...field}
                        label={t('Phone type')}
                        name={[field.name, 'type']}
                        fieldKey={['type']}
                        rules={[{ required: true, message: t('Required field') }]}
                      >
                        <Input placeholder={t('Enter a phone number type')} />
                      </Form.Item>
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                      span={2}
                    >
                      <RemoveItemIcon
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Col>
                  </Row>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    <PlusOutlined /> {t('Add phone')}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

export default ContactInformation;

const RemoveItemIcon = styled(MinusCircleOutlined)`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-top: 3px;
`;

const Wrapper = styled(Card)`
  margin: 0 auto;
  display: block;
  max-width: 800px;
`;
