import axiosInstance from './axios-instance';

export const getSubscriptionPlans = (signal: AbortSignal) => axiosInstance.get('/subscriptions/plans', { signal });

export const getSubscriptions = (locationId: string) => axiosInstance.get('/subscriptions', { params: { locationId }});

export const getCurrentSubscription = (locationId: string) => axiosInstance.get('/subscriptions/current', { params: { locationId }});

export const createSubscription = (locationId: string, priceId: string, paymentMethodId?: string) =>
  axiosInstance.post('/subscriptions', { paymentMethodId, priceId }, { params: { locationId }});

export const cancelSubscription = (locationId: string) => axiosInstance.delete('/subscriptions', { params: { locationId }});
