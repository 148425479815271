import React, { useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Area, AreaChart, Tooltip, XAxis } from 'recharts';
import './StatisticsCard.css';

export const StatisticsCard = (props: StatisticsCardProps) => {
  const { width, ref: cardRef } = useResizeDetector();

  const averageValue = useMemo(() =>
    Math.round(props.data.reduce((totalValue, item) => totalValue + item.value, 0) / (props.data.length * 100 || 1)) / 100, [props.data]);

  return (
    <div className="statistics-card flex flex-column mb-3" ref={cardRef}>
      <div className="flex-grow-1 px-3 py-2">
        <div className="text-18 text-bold">{props.unit ? `${averageValue}${props.unit}` : averageValue}</div>
        <div>{props.title}</div>
      </div>
      <div className="statistics-card--chart">
        <AreaChart width={width! + 10} height={100} data={props.data}>
          <XAxis dataKey="date" hide />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="value"
            name={props.title}
            unit={props.unit}
            stroke="#8884d8"
            fill="#8884d8"
          />
        </AreaChart>
      </div>
    </div>
  );
};

interface StatisticsCardProps {
  data: StatisticsElementInterface[];
  title: string;
  unit?: string;
}

export interface StatisticsElementInterface {
  date: string;
  value: number;
  [key: string]: any;
}
