import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LogsDrawer from '../../../components/sharedComponents/LogsDrawer';
import { IApplicationState } from '../../../models/IApplicationState';
import { IWorkingHours } from '../../../models/IWorkingHours';
import { updateReservationRequest } from '../../../store/reservation/actions';
import { NewReservation } from '../NewReservation';
import './Toolbar.css';

export const Toolbar = (props: CalendarToolbarProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isWorkingTime, setIsWorkingTime] = useState(false);

  const dateLabel = useMemo(() => moment(props.date).format('dddd MMM D'), [props.date]);

  const { date, workingHours } = useSelector((state: IApplicationState) => ({
    date: state.reservation.date,
    workingHours: state.location.currentLocation?.workingHours as IWorkingHours,
  }));

  useEffect(() => {
    if (workingHours) {
      const day: string = moment(date).locale('en').format('dddd').toLocaleLowerCase();
      // @ts-ignore
      setIsWorkingTime(!!workingHours[day.toLocaleLowerCase()]);
    }
    // eslint-disable-next-line
  }, [date, workingHours]);

  const onUpdateHandler = (reservation: any, reservationId: string, originMasterId: string) => {
    dispatch(updateReservationRequest(reservation, reservationId, originMasterId as string));
  };

  return (
    <Row align="middle" className="mb-3">
      <Col className="flex">
        <Button className="prev-button" icon={<LeftOutlined />} onClick={() => props.onNavigate('PREV')} />
        <Button className="today-button" onClick={() => props.onNavigate('TODAY')}>{t('Today')}</Button>
        <Button className="next-button" icon={<RightOutlined />} onClick={() => props.onNavigate('NEXT')} />
      </Col>
      <Col className="flex px-3" flex="auto">{dateLabel}</Col>
      {isWorkingTime && (
        <Col className="flex">
          <NewReservation
            shouldShowNewButton
            reservationId={props.reservationId}
            onCloseHandler={() => setTimeout(() => props.onReservationIdChange(null), 500)}
            onUpdate={onUpdateHandler}
          />
          <LogsDrawer />
        </Col>
      )}
    </Row>
  );
};

type CalendarToolbarProps = ToolbarProps & {
  reservationId: string | null;
  onReservationIdChange: (reservationId: string | null) => void;
};
