import { AuthActionTypes } from './types';
import { IAuthCredential } from '../../models/IAuthCredential';
import { IMaster, IMasterInput } from '../../models/IUser';

type Error = string;

export const signUpRequest = (credential: IMasterInput, captcha?: string) => ({
  type: AuthActionTypes.SIGN_UP_REQUEST,
  payload: { credential, captcha },
});
export const signUpSuccess = (master: IMaster) => ({
  type: AuthActionTypes.SIGN_UP_SUCCESS,
  payload: master,
});
export const signUpFailure = (data: Error[]) => ({
  type: AuthActionTypes.SIGN_UP_FAILURE,
  payload: data,
});

export const onLoadRequest = (token: string) => ({
  type: AuthActionTypes.ON_LOAD_REQUEST,
  payload: token,
});

export const noToken = () => ({
  type: AuthActionTypes.NO_TOKEN,
});

export const loginRequest = (credential: IAuthCredential) => ({
  type: AuthActionTypes.LOGIN_REQUEST,
  payload: credential,
});
export const loginSuccess = (master: IMaster) => ({
  type: AuthActionTypes.LOGIN_SUCCESS,
  payload: master,
});
export const loginFailure = (data: Error[]) => ({
  type: AuthActionTypes.LOGIN_FAILURE,
  payload: data,
});

export const logoutRequest = () => ({
  type: AuthActionTypes.LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: AuthActionTypes.LOGOUT_SUCCESS,
});

export const logoutFailure = () => ({
  type: AuthActionTypes.LOGOUT_FAILURE,
  payload: null,
});

export const forgotPasswordRequest = () => ({
  type: AuthActionTypes.FORGOT_PASSWORD_REQUEST,
  payload: null,
});

export const setVerified = () => ({ type: AuthActionTypes.SET_VERIFIED });
