import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { EmptyLayout } from './layouts/EmptyLayout';
import { MainLayout } from './layouts/MainLayout';
import { PublicLayout } from './layouts/PublicLayout';
import { administratorPermissionsRoles } from './services/auth-service';
import ClientAnalytics from './views/Analytics/ClientAnalytics';
import EmployeeAnalytics from './views/Analytics/EmployeeAnalytics';
import ServiceAnalytics from './views/Analytics/ServiceAnalytics';
import Auth from './views/Auth/Auth';
import ResetPassword from './views/Auth/ResetPassword';
import Calendar from './views/Calendar/Calendar';
import Client from './views/Clients/Client';
import Clients from './views/Clients/Clients';
import CreateLocation from './views/CreateLocation/CreateLocation';
import { Dashboard } from './views/Dashboard/Dashboard';
import Discounts from './views/Discounts/Discounts';
import { Employee } from './views/Employees/Employee/Employee';
import { Employees } from './views/Employees/Employees';
import { NewEmployee } from './views/Employees/NewEmployee/NewEmployee';
import Feedback from './views/Feedback/Feedback';
import { Home } from './views/Home/Home';
import { RegistrationConfirm } from './views/Home/RegistrationConfirm/RegistrationConfirm';
import { License } from './views/License/License';
import { PrivacyPolicy } from './views/PrivacyPolicy/PrivacyPolicy';
import { Profile } from './views/Profile/Profile';
import { ServiceCategories } from './views/Services/ServiceCategories/ServiceCategories';
import Services from './views/Services/Services';
import Settings from './views/Settings/Settings';
import Pricing from './views/Subscriptions/Pricing';
import { Subscriptions } from './views/Subscriptions/Subscriptions';
import { EditWidget } from './views/Widgets/EditWidget/EditWidget';
import { NewWidget } from './views/Widgets/NewWidget/NewWidget';
import { Widgets } from './views/Widgets/Widgets';

export const Router = () => (
  <Switch>
    <ProtectedRoute
      Component={Feedback}
      Layout={PublicLayout}
      path="/feedback"
      public
    />
    <ProtectedRoute
      Component={ResetPassword}
      Layout={PublicLayout}
      path="/auth/reset-password"
      public
    />
    <ProtectedRoute
      Component={Auth}
      Layout={PublicLayout}
      captcha
      path="/auth"
    />
    <ProtectedRoute
      Component={Dashboard}
      Layout={MainLayout}
      path="/dashboard"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Settings}
      Layout={MainLayout}
      path="/settings"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Subscriptions}
      Layout={MainLayout}
      path="/billing"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Pricing}
      Layout={MainLayout}
      path="/pricing"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Calendar}
      Layout={MainLayout}
      path="/calendar"
    />
    <ProtectedRoute
      Component={ClientAnalytics}
      Layout={MainLayout}
      path="/analytics/clients"
    />
    <ProtectedRoute
      Component={EmployeeAnalytics}
      Layout={MainLayout}
      path="/analytics/employees"
    />
    <ProtectedRoute
      Component={ServiceAnalytics}
      Layout={MainLayout}
      path="/analytics/services"
    />
    <ProtectedRoute
      path="/analytics"
      redirect="/analytics/clients"
    />
    <ProtectedRoute
      Component={Client}
      Layout={MainLayout}
      path="/clients/client"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Clients}
      Layout={MainLayout}
      path="/clients"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={NewEmployee}
      Layout={MainLayout}
      path="/employees/new"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Employee}
      Layout={MainLayout}
      path="/employees/:userId"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Employees}
      Layout={MainLayout}
      path="/employees"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Discounts}
      Layout={MainLayout}
      path="/discounts"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Profile}
      Layout={MainLayout}
      path="/profile"
    />
    <ProtectedRoute
      Component={ServiceCategories}
      Layout={MainLayout}
      path="/services/categories"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Services}
      Layout={MainLayout}
      path="/services"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={NewWidget}
      Layout={MainLayout}
      path="/widgets/new"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={EditWidget}
      Layout={MainLayout}
      path="/widgets/:widgetId"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Widgets}
      Layout={MainLayout}
      path="/widgets"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={Subscriptions}
      Layout={MainLayout}
      path="/billing"
      roles={administratorPermissionsRoles}
    />
    <ProtectedRoute
      Component={CreateLocation}
      Layout={EmptyLayout}
      path="/create-location"
      roles={administratorPermissionsRoles}
    />
    <Route
      path="/confirm-registration"
      render={() => <RegistrationConfirm />}
    />
    <Route
      path="/license"
      render={() => <License />}
    />
    <Route
      path="/privacy-policy"
      render={() => <PrivacyPolicy />}
    />
    <ProtectedRoute
      Component={Home}
      Layout={PublicLayout}
      path="/"
    />
  </Switch>
);
