import { AuthActionTypes } from './types';
import { takeEvery, put } from 'redux-saga/effects';
import AuthService from '../../services/auth-service';
import { loginFailure, loginSuccess, logoutFailure, logoutSuccess, signUpFailure, signUpSuccess } from './actions';
import { IAuthCredential } from '../../models/IAuthCredential';
import { APP_AUTH_DATA_REFRESH, APP_AUTH_DATA_TOKEN, APP_LANG, USER_ID } from '../../const';
import MasterService from '../../services/master-service';
import { IMasterInput } from '../../models/IUser';
import { setUpLocation } from '../location/actions';
import { push } from 'react-router-redux';
import * as moment from 'moment';
import i18n from '../../i18n';
const authService = new AuthService();
const masterService = new MasterService();

//TODO catch response fail
function* loginWorker(action: { type: string; payload: IAuthCredential }): Generator<any, any, any> {
  try {
    const response = yield masterService.login(action.payload);
    localStorage.setItem(APP_AUTH_DATA_TOKEN, response.data.token);
    localStorage.setItem(APP_AUTH_DATA_REFRESH, response.data.refreshToken);
    localStorage.setItem(USER_ID, response.data.user._id);
    yield put(loginSuccess(response.data.user));
    if (response.data.primaryLocation === null && !response.data.user.locations) {
      yield put(push(`/create-location${window.location.search}`));
    } else {
      yield put(
        setUpLocation({
          locations: response.data.locations,
          currentLocation: response.data.primaryLocation,
        })
      );
      yield put(push(`/dashboard${window.location.search}`));
    }
    const lang = window.localStorage.getItem(APP_LANG) || 'en';
    moment.locale(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem(APP_LANG, lang);

    setTimeout(() => {
      const recaptchaElm: any = document.getElementsByClassName('grecaptcha-badge');
      if (recaptchaElm && recaptchaElm[0]) recaptchaElm[0].style.visibility = 'hidden';
    }, 500);
  } catch (e) {
    console.log(e);
    yield put(loginFailure((e as { message: string[] }).message));
  }
}

function* onLoadWorker(action: { type: string; payload: string }): Generator<any, any, any> {
  try {
    const response = yield masterService.onLoad(action.payload);
    yield put(loginSuccess(response.data.user));
    if (
      response.data.primaryLocation === null &&
      (!response.data.user.locations || response.data.user.locations.length === 0)
    ) {
      yield put(push(`/create-location${window.location.search}`));
    } else {
      yield put(
        setUpLocation({
          locations: response.data.locations,
          currentLocation: response.data.primaryLocation,
        })
      );
    }
    const lang = window.localStorage.getItem(APP_LANG) || 'en';
    moment.locale(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem(APP_LANG, lang);
  } catch (e) {
    console.log(e);
    yield put(loginFailure((e as { message: string[] }).message));
  }
}

function* signUpWorker(action: { type: string; payload: { credential: IMasterInput; captcha: string } }): Generator<any, any, any> {
  try {
    const response = yield masterService.signUp(action.payload.credential, action.payload.captcha);
    localStorage.setItem(APP_AUTH_DATA_TOKEN, response.data.token);
    localStorage.setItem(APP_AUTH_DATA_REFRESH, response.data.refreshToken);
    localStorage.setItem(USER_ID, response.data.user._id);
    yield put(signUpSuccess(response.data.user));
    yield put(push(`/create-location${window.location.search}`));

    setTimeout(() => {
      const recaptchaElm: any = document.getElementsByClassName('grecaptcha-badge');
      if (recaptchaElm && recaptchaElm[0]) recaptchaElm[0].style.visibility = 'hidden';
    }, 500);
  } catch (e) {
    console.log(e);
    yield put(signUpFailure(e as any));
  }
}

function* logoutWorker(): Generator<any, any, any> {
  try {
    yield authService.logout(localStorage.getItem(APP_AUTH_DATA_TOKEN) as string);
    localStorage.removeItem(APP_AUTH_DATA_TOKEN);
    localStorage.removeItem(APP_AUTH_DATA_REFRESH);
    localStorage.removeItem(USER_ID);
    yield put(logoutSuccess());
  } catch (e) {
    localStorage.removeItem(APP_AUTH_DATA_TOKEN);
    localStorage.removeItem(APP_AUTH_DATA_REFRESH);
    localStorage.removeItem(USER_ID);
    yield put(logoutFailure());
  }
  window.location.href = '/auth?login';
}

export function* watchAuth() {
  yield takeEvery(AuthActionTypes.LOGIN_REQUEST, loginWorker);
  yield takeEvery(AuthActionTypes.ON_LOAD_REQUEST, onLoadWorker);
  yield takeEvery(AuthActionTypes.LOGOUT_REQUEST, logoutWorker);
  yield takeEvery(AuthActionTypes.SIGN_UP_REQUEST, signUpWorker);
}

const authWatchers: any = [watchAuth()];

export default authWatchers;
