import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Table, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { useTranslation } from 'react-i18next';
import NewDiscount from './NewDiscount';
import { getDiscountsRequest } from '../../store/discount/actions';
import { IDiscount } from '../../models/IDiscount';
import { AppContext } from '../../context/app-context';
import { minTableCellWidth, tableXScroll } from '../../const';
import { setBreadcrumbs } from '../../store/app/actions';
import { createBreadcrumb } from '../../store/app/utils';
import { hasAdministratorPermissions } from '../../services/auth-service';

const Discounts = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { mainContainerHeight } = useContext(AppContext);

  const [discount, setDiscount] = useState<IDiscount | null>(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
  });

  const { loading, locationId, discounts, total, authenticatedUser } = useSelector((state: IApplicationState) => ({
    loading: state.discount.loading,
    locationId: state.location.currentLocation?._id,
    discounts: state.discount.discounts as IDiscount[],
    total: state.discount.total,
    authenticatedUser: state.auth.user,
  }));

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Discounts'))]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    setPagination({ ...pagination, total });
    // eslint-disable-next-line
  }, [loading, total]);

  useEffect(() => {
    if (locationId)
      dispatch(
        getDiscountsRequest(locationId as string, {
          offset: pagination.pageSize * (pagination.current - 1),
          limit: pagination.pageSize,
        })
      );
    // eslint-disable-next-line
  }, [locationId]);

  const editServiceHandler = (discount: IDiscount) => setDiscount(discount);

  const columns: any = [
    {
      title: t('Enabled'),
      width: minTableCellWidth,
      render: (enabled: any) => <Tag color={enabled ? 'green' : 'volcano'}>{t(enabled ? 'Yes' : 'No')}</Tag>,
      dataIndex: 'enabled',
    },
    {
      title: t('[Thing] Name'),
      width: minTableCellWidth,
      dataIndex: 'name',
    },
    {
      title: t('Amount'),
      width: minTableCellWidth,
      dataIndex: 'amount',
    },
    {
      title: t('Type of quantity'),
      width: minTableCellWidth,
      dataIndex: 'typeOfAmount',
    },
    {
      title: t('When to add'),
      width: minTableCellWidth,
      render: (cond: string) => t(cond),
      dataIndex: 'applyWhen',
    },
    {
      title: t('discount'),
      width: minTableCellWidth,
      dataIndex: 'discount',
    },
  ];

  if (authenticatedUser && hasAdministratorPermissions(authenticatedUser)) {
    columns.push({
      title: t('Operations'),
      key: 'operation',
      width: minTableCellWidth,
      render: (discount: IDiscount) => (
        <Button onClick={() => editServiceHandler(discount)} type={'dashed'}>
          {t('Edit')}
        </Button>
      ),
    });
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setPagination(pagination);
    dispatch(
      getDiscountsRequest(locationId as string, {
        offset: pagination.pageSize * (pagination.current - 1),
        limit: pagination.pageSize,
      })
    );
  };

  const onSearch = (search: string) => {
    dispatch(
      getDiscountsRequest(
        locationId as string,
        {
          offset: 0,
          limit: pagination.pageSize,
        },
        { key: 'name', value: search }
      )
    );
  };

  return (
    <>
      <Row>
        <Col span={window.innerWidth <= 800 ? 24 : 7} style={{ marginBottom: window.innerWidth <= 800 ? '16px' : 0 }}>
          <NewDiscount discount={discount} onCloseHandler={() => setDiscount(null)} />
        </Col>
        <Col span={window.innerWidth <= 800 ? 24 : 16} offset={window.innerWidth <= 800 ? 0 : 1}>
          <Input.Search
            className={'phone-button-margin-full'}
            loading={loading}
            placeholder={t('Enter key word')}
            style={{ width: '100%' }}
            onSearch={onSearch}
          />
        </Col>
        <Col span={24} style={{ marginTop: '16px' }}>
          <Table
            style={{ maxHeight: mainContainerHeight - 300 }}
            scroll={{ x: tableXScroll, y: mainContainerHeight }}
            size="small"
            loading={loading}
            onChange={handleTableChange}
            rowKey={(item: IDiscount) => item._id}
            pagination={pagination}
            columns={columns}
            dataSource={discounts}
          />
        </Col>
      </Row>
    </>
  );
};

export default Discounts;
