import { Col, Row, Steps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Loader from '../../../components/sharedComponents/Loader';
import { IApplicationState } from '../../../models/IApplicationState';
import { WidgetType } from '../../../models/ILocation';
import { createLocationWidget } from '../../../services/locations.service';
import { setBreadcrumbs } from '../../../store/app/actions';
import { createBreadcrumb } from '../../../store/app/utils';
import { BUTTON_STYLES } from '../constants';
import { ButtonSettingsStep } from './ButtonSettingsStep/ButtonSettingsStep';
import { GeneralSettingsStep } from './GeneralSettingsStep/GeneralSettingsStep';
import { ResultStep } from './ResultStep/ResultStep';
import { WidgetSettingsStep } from './WidgetSettingsStep/WidgetSettingsStep';

export const NewWidget = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const locationId = useSelector((state: IApplicationState) => state.location.currentLocation?._id);

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({
    about: '',
    buttonColors: BUTTON_STYLES[0].defaultColors,
    buttonStyle: BUTTON_STYLES[0].name,
    buttonText: t('Book online'),
    locations: [],
    name: '',
    style: null,
    type: null,
  });

  const stepIncrement = useMemo(() => model.type === WidgetType.Website ? 1 : 0, [model.type]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      createBreadcrumb(t('Booking widgets'), '/widgets'),
      createBreadcrumb(t('New widget')),
    ]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  const handleCancel = () => history.goBack();

  const handleStepPrev = (step: number) => () => setCurrentStep(step - 1);
  const handleStepNext = (step: number) => () => setCurrentStep(step + 1);

  const handleModelChange = (state: any) => setModel(state);

  const handleSave = () => {
    setLoading(true);
    createLocationWidget({ ...model, locationId })
      .then(response => history.push(`/widgets/${response.data._id}`))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  };

  return loading ? <Loader /> : (
    <Row>
      <Col span={24}>
        <Steps size="small" current={currentStep}>
          <Steps.Step title={t('General settings')} />
          {model.type === WidgetType.Website && <Steps.Step title={t('Button settings')} />}
          <Steps.Step title={t('Widget settings')} />
          <Steps.Step title={model.type === WidgetType.Website ? t('Widget code') : t('Widget link')} />
        </Steps>
      </Col>
      <Col className="mt-4" span={24}>
        {currentStep === 0 && (
          <GeneralSettingsStep
            model={model}
            onCancel={handleCancel}
            onChange={handleModelChange}
            onNext={handleStepNext(0)}
          />
        )}
        {model.type === WidgetType.Website && currentStep === 0 + stepIncrement && (
          <ButtonSettingsStep
            model={model}
            onBack={handleStepPrev(0 + stepIncrement)}
            onChange={handleModelChange}
            onNext={handleStepNext(0 + stepIncrement)}
          />
        )}
        {currentStep === 1 + stepIncrement && (
          <WidgetSettingsStep
            model={model}
            onBack={handleStepPrev(1 + stepIncrement)}
            onChange={handleModelChange}
            onNext={handleStepNext(1 + stepIncrement)}
          />
        )}
        {currentStep === 2 + stepIncrement && (
          <ResultStep
            model={model}
            onBack={handleStepPrev(2 + stepIncrement)}
            onSave={handleSave}
          />
        )}
      </Col>
    </Row>
  );
};
