import { VerticalLeftOutlined, VerticalRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Calendar } from 'antd';
import moment from 'moment';
import Header from 'rc-picker/lib/panels/Header';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { APP_LANG } from '../../../const';
import { IApplicationState } from '../../../models/IApplicationState';
import { convertDayToNumber } from '../../../services/utils';
import './AppSidebarCalendar.css';

export const AppSidebarCalendar: React.FC<AppSidebarCalendarProps> = props => {
  const appLang = useMemo(() => localStorage.getItem(APP_LANG), []);
  const { date, location } = useSelector((state: IApplicationState) => ({
    date: state.reservation.date,
    location: state.location.currentLocation,
  }));

  useEffect(() => {
    const weekDay = convertDayToNumber(location?.first_day_of_week || 'sunday');
    moment.locale(appLang || 'en', { week: { dow: weekDay || 0 }});
  }, [appLang, location]);

  return (
    <Calendar
      value={moment(date)}
      locale={{
        lang: {
          locale: appLang || 'en',
          placeholder: 'Select date',
          rangePlaceholder: ['Start date', 'End date'],
          today: 'Today',
          now: 'Now',
          backToToday: 'Back to today',
          ok: 'Ok',
          clear: 'Clear',
          month: 'Month',
          year: 'Year',
          timeSelect: 'Select time',
          dateSelect: 'Select date',
          monthSelect: 'Choose a month',
          yearSelect: 'Choose a year',
          decadeSelect: 'Choose a decade',
          yearFormat: 'YYYY',
          dateFormat: 'M/D/YYYY',
          dayFormat: 'D',
          dateTimeFormat: 'M/D/YYYY HH:mm:ss',
          monthFormat: 'MMMM',
          monthBeforeYear: true,
          previousMonth: 'Previous month (PageUp)',
          nextMonth: 'Next month (PageDown)',
          previousYear: 'Last year (Control + left)',
          nextYear: 'Next year (Control + right)',
          previousDecade: 'Last decade',
          nextDecade: 'Next decade',
          previousCentury: 'Last century',
          nextCentury: 'Next century',
        },
        timePickerLocale: {
          placeholder: 'Select time',
        },
        dateFormat: 'YYYY-MM-DD',
        dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
        weekFormat: 'YYYY-wo',
        monthFormat: 'YYYY-MM',
      }}
      fullscreen={false}
      mode="month"
      headerRender={({ value, onChange }) => (
        <Header
          prefixCls="skeedee-calendar-header"
          prevIcon={<LeftOutlined />}
          nextIcon={<RightOutlined />}
          superPrevIcon={<VerticalRightOutlined />}
          superNextIcon={<VerticalLeftOutlined />}
          onPrev={() => onChange(value.clone().month(value.month() - 1))}
          onNext={() => onChange(value.clone().month(value.month() + 1))}
          onSuperPrev={() => onChange(value.clone().month(0))}
          onSuperNext={() => onChange(value.clone().month(11))}
        >
          {value.format('LL')}
        </Header>
      )}
      onPanelChange={props.onDatePick}
      onSelect={props.onDatePick}
    />
  );
};

type AppSidebarCalendarProps = {
  onDatePick: (date: moment.Moment) => void;
};
