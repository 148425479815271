import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Col, message, Row, Statistic, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { useTranslation } from 'react-i18next';
import { searchUsersRequest } from '../../store/user/actions';
import { copyToClipboard } from '../../services/utils';
import { IUser } from '../../models/IUser';
import AddNewClient from './AddNewClient';
import { AppContext } from '../../context/app-context';
import { tableXScroll } from '../../const';
import { getDiscountsRequest } from '../../store/discount/actions';
import { useHistory } from 'react-router-dom';
import { COLUMNS } from './constants';
import { UserSearchBar } from '../../components/UserSearchBar/UserSearchBar';
import { createBreadcrumb } from '../../store/app/utils';
import { setBreadcrumbs } from '../../store/app/actions';
import confirm from 'antd/lib/modal/confirm';
import UserService from '../../services/user-service';

const Clients = () => {
  const dispatch = useDispatch();
  const [searchBy, setSearchBy] = useState('name');
  const [search, setSearch] = useState('');
  const history = useHistory();

  const { t } = useTranslation();
  // eslint-disable-next-line
  const [user, setUser] = useState<IUser | null>(null);

  const { mainContainerHeight } = useContext(AppContext);

  const [queryData, setQueryData] = useState({
    query: {} as any,
    sort: {} as any,
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
  });

  const { loading, locationId, users, total, authenticatedUser } = useSelector((state: IApplicationState) => ({
    loading: state.user.loading || state.discount.loading,
    locationId: state.location.currentLocation?._id,
    users: state.user.users,
    total: state.user.total,
    authenticatedUser: state.auth.user,
  }));

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Clients'))]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    setPagination({ ...pagination, total });
    // eslint-disable-next-line
  }, [loading, total, users]);

  const updateData = () => {
    dispatch(
      searchUsersRequest(
        locationId as string,
        {
          offset: pagination.pageSize * (pagination.current - 1),
          limit: pagination.pageSize,
        },
        { key: searchBy, value: search },
        queryData
      )
    );
    dispatch(getDiscountsRequest(locationId as string));
  };

  useEffect(() => {
    if (!locationId) {
      return;
    }
    updateData();
    // eslint-disable-next-line
  }, [locationId]);

  const tableValue = (val: any) => (
    <div
      onClick={() => {
        copyToClipboard(val);
        message.success(`${t('Copied')}: ${val}`);
      }}
    >
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    </div>
  );

  const handleClientEdit = (user: IUser) => setUser(user);
  const handleClientView = (user: IUser) => history.push(`/clients/client?userId=${user._id}`);

  const handleClientDelete = (user: IUser) => {
    confirm({
      content: t('Are you sure that you want to delete this customer?'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        new UserService()
          .deleteUser(user._id, locationId!)
          .then(() => updateData());
      },
    });
  };

  const columns: any = COLUMNS({
    tableValue,
    t,
    authenticatedUser,
    handleClientDelete,
    handleClientEdit,
    handleClientView,
  });

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let query: any = {};

    let sort: any = {
      [sorter.field]: sorter.order === 'descend' ? 1 : -1,
    };

    if (!sorter.order) sort = {};

    Object.keys(filters).forEach((key) => {
      switch (key) {
        case 'subscription':
        case 'ban':
          if (Array.isArray(filters[key]))
            if (filters[key].length === 1) {
              query[key] = filters[key][0];
            }
          break;
        case 'count':
          if (filters['noReservation']) {
            query['noReservation'] = filters['noReservation'][0];
          }
          break;
      }
    });

    setQueryData({ sort, query });
    setPagination(pagination);
    dispatch(
      searchUsersRequest(
        locationId as string,
        {
          offset: pagination.pageSize * (pagination.current - 1),
          limit: pagination.pageSize,
        },
        { key: searchBy, value: search },
        { sort, query }
      )
    );
  };

  const onChangeFilterField = (field: string) => {
    setSearchBy(field);
  };
  const onChangeText = (searchQuery: string) => {
    dispatch(
      searchUsersRequest(
        locationId as string,
        {
          limit: 10,
          offset: 0,
        },
        { key: searchBy, value: searchQuery },
        queryData
      )
    );

    setSearch(searchQuery);
    setPagination({
      current: 1,
      pageSize: 10,
      total: 200,
      showQuickJumper: true,
    });
  };

  return (
    <>
      <Row>
        <Col span={window.innerWidth <= 800 ? 24 : 7} style={{ marginBottom: window.innerWidth <= 800 ? '16px' : 0 }}>
          <AddNewClient user={user} onCloseHandler={() => setUser(null)} />
        </Col>
        <Col span={window.innerWidth <= 800 ? 24 : 16} offset={window.innerWidth <= 800 ? 0 : 1}>
          <UserSearchBar
            field={searchBy}
            fields={{
              name: t('Name'),
              email: t('E-mail'),
              phone: t('Phone'),
            }}
            loading={loading}
            onFieldChange={onChangeFilterField}
            onQueryChange={onChangeText}
          />
        </Col>
        <Col span={24} style={{ marginTop: 16 }}>
          <Statistic className={'mobile-left-padding'} title={t('Total customers')} value={total} />
        </Col>
        <Col span={24} style={{ marginTop: 16 }}>
          <Table
            scroll={{ x: tableXScroll, y: mainContainerHeight }}
            size="small"
            loading={loading}
            onChange={handleTableChange}
            rowKey={(item: IUser) => item._id}
            pagination={pagination}
            columns={columns}
            dataSource={users}
          />
        </Col>
      </Row>
    </>
  );
};

export default Clients;
