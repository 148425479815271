import React from 'react';
import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

export const UserSearchBar: React.FC<UserSearchBarProps> = props => {
  const { t } = useTranslation();

  const fieldSelectAddon = (
    <Select className="select-before" defaultValue={props.field} onChange={field => props.onFieldChange(field)}>
      {Object.entries(props.fields).map(([name, label], index) => (
        <Select.Option key={index} value={name}>{label}</Select.Option>
      ))}
    </Select>
  );

  return (
    <div className="phone-button-margin-full">
      <Input.Search
        addonBefore={fieldSelectAddon}
        loading={props.loading}
        placeholder={t('Enter key word')}
        style={{ width: '100%' }}
        onSearch={query => props.onQueryChange(query)}
      />
    </div>
  );
};

type UserSearchBarProps = {
  field: string;
  fields: Record<string, string>;
  loading: boolean;
  onFieldChange: (field: string) => void;
  onQueryChange: (query: string) => void;
};
