import { LogActionTypes } from './types';

export const getLogsRequest = () => ({
  type: LogActionTypes.LOG_REQUEST,
});

export const getLogsSuccess = (logs: any[], total: number) => ({
  type: LogActionTypes.LOG_SUCCESS,
  payload: { logs, total },
});

export const addLog = (log: any) => ({
  type: LogActionTypes.ADD_LOG,
  payload: log,
});
export const clearLogs = () => ({
  type: LogActionTypes.CLEAR_LOGS,
});
