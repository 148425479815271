import { IWorkingHours } from './IWorkingHours';
import { IMaster, IUser } from './IUser';
import { IDatabaseRecord } from './IDatabaseRecord';
import { IService } from './IService';
import { IDiscount } from './IDiscount';

type WidgetStyle = 'white' | 'black';
type Currency = 'dollar' | 'euro';
type LocationType = 'free' | 'premium' | 'diamond';

export interface IEmail {
  email: string;
  type: string;
}

export interface IWidget extends IDatabaseRecord {
  locations: ILocation['_id'][];
  style: string;
  about: string;
  type: WidgetType;
  name: string;
  buttonText: string;
  buttonStyle: string;
  buttonColors: string[];
}

export enum WidgetType {
  Website = 'website',
  SocialMedia = 'social-media',
}

export interface IPhone {
  phone: string;
  countryCode: string;
  type: string;
}
export interface ICoordinate {
  lat: number;
  lng: number;
}
export interface ILocation extends IDatabaseRecord {
  masters: [IMaster['_id'] | IMaster];
  owner: IMaster['_id'] | IMaster;
  name: string;
  currency: Currency;
  first_day_of_week: string;
  address: string;
  category: string[];
  notAfter: number;
  city: string;
  phones: IPhone[];
  widget: IWidget['_id'] | IWidget;
  avatarUrl: string;
  emails: IEmail[];
  widgetStyle: WidgetStyle;
  coordinate: ICoordinate;
  closeReason: ILocationCloseReason | null;
  workingHours: IWorkingHours;
  services: [IService['_id'] | IService];
  discounts: [IDiscount['_id'] | IDiscount];
  clients: [IUser['_id'] | IUser];
  locationType: LocationType;
  country: string;
  utcOffset: number;
  is12H?: boolean;
}

export interface ILocationBaseStatistics {
  masters: [
    {
      _id: IMaster['_id'];
      name: string;
      totalReservations: number;
      earnMoney: number;
    }
  ];
  services: [{ count: number; service: IService }];
  earnMoney: number;
  totalReservations: number;
}

export interface ILocationInput {
  name: string;
  address: string;
  category?: string[];
  country: string;
  avatarUrl?: string;
  city: string;
  emails: IEmail[];
  phones?: IPhone[];
  countryCode: string;
  currency: string;
  widgetStyle?: WidgetStyle;
  phone: string;
  workingHours: IWorkingHours;
  closeReason?: ILocationCloseReason;
  coordinate?: ICoordinate;
  locationType?: LocationType;
  utcOffset: number;
  is12H?: boolean;
}

export interface ILocationCloseReason {
  title: string;
  description: string;
  until: {
    from: Date;
    to: Date;
  };
}

export interface ILocationUpdateInput {
  owner?: string;
  name?: string;
  address?: string;
  category?: string[];
  emails?: IEmail[];
  avatarUrl?: string;
  currency: string;
  widgetStyle?: WidgetStyle;
  phones?: IPhone[];
  workingHours?: IWorkingHours;
  closeReason?: ILocationCloseReason | null;
  coordinate?: ICoordinate;
  locationType?: LocationType;
  utcOffset: number;
  is12H?: boolean;
}
