import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Tag, Tooltip } from 'antd';
import React from 'react';
import { minTableCellWidth } from '../../const';
import { IUser } from '../../models/IUser';
import { hasAdministratorPermissions } from '../../services/auth-service';

export const COLUMNS = ({ t, handleClientEdit, authenticatedUser, tableValue, handleClientDelete, handleClientView }: any) => [
  {
    title: t('Operations'),
    key: 'operation',
    width: 120,
    render: (user: IUser) => (
      <React.Fragment>
        <Tooltip placement="top" title={t('Profile')}>
          <Button
            className="mr-1"
            icon={<EyeOutlined />}
            shape="circle"
            type="primary"
            onClick={() => handleClientView(user)}
          />
        </Tooltip>
        {(authenticatedUser && hasAdministratorPermissions(authenticatedUser)) && (
          <Tooltip placement="top" title={t('Edit')}>
            <Button
              className="mr-1"
              icon={<EditOutlined />}
              shape="circle"
              type="primary"
              onClick={() => handleClientEdit(user)}
            />
          </Tooltip>
        )}
        <Tooltip placement="top" title={t('Delete')}>
          <Button
            danger
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => handleClientDelete(user)}
          />
        </Tooltip>
      </React.Fragment>
    ),
  },
  {
    title: t('Name'),
    dataIndex: 'name',
    width: minTableCellWidth,
    render: tableValue,
  },
  {
    title: t('E-mail'),
    dataIndex: 'email',
    width: minTableCellWidth,
    render: tableValue,
  },
  {
    title: t('Phone'),
    dataIndex: 'phone',
    width: minTableCellWidth,
    render: tableValue,
  },
  {
    title: t('Subscription'),
    width: minTableCellWidth,
    render: (subscription: boolean) => (
      <Tag color={!subscription ? 'volcano' : 'green'}>{t(subscription ? 'Yes' : 'No')}</Tag>
    ),
    dataIndex: 'subscription',
    filters: [
      {
        text: t('Yes'),
        value: true,
      },
      {
        text: t('No'),
        value: false,
      },
    ],
  },
  {
    title: t('Comment'),
    width: minTableCellWidth,
    dataIndex: 'comment',
  },
  {
    title: t('Banned'),
    width: minTableCellWidth,
    render: (ban: boolean) => <Tag color={ban ? 'volcano' : 'green'}>{t(ban ? 'Yes' : 'No')}</Tag>,
    dataIndex: 'ban',
    filters: [
      {
        text: t('Yes'),
        value: true,
      },
      {
        text: t('No'),
        value: false,
      },
    ],
  },
];
