import { Button, Form, Spin, Switch } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../models/IApplicationState';
import { changeNotifications } from '../../../services/master.service';

export const NotificationSettings = () => {
  const { t } = useTranslation();

  const user = useSelector((state: IApplicationState) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(user ? {
    bookingConfirmation: user.notifications.bookingConfirmation,
    bookingChanging: user.notifications.bookingChanging,
    bookingCancelation: user.notifications.bookingCancelation,
  } : {
    bookingConfirmation: false,
    bookingChanging: true,
    bookingCancelation: true,
  });

  const setNotificationState = (property: string, value: boolean) => setModel(state => ({ ...state, [property]: value }));

  const handleBookingConfirmationChange = (value: any) => setNotificationState('bookingConfirmation', value);
  const handleBookingChangingChange = (value: any) => setNotificationState('bookingChanging', value);
  const handleBookingCancelationChange = (value: any) => setNotificationState('bookingCancelation', value);

  const handleSave = async () => {
    setLoading(true);
    try {
      await changeNotifications(model);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Spin spinning={loading} tip={t('Loading')}>
      <Form layout="vertical">
        <Form.Item name="bookingConfirmation" label={t('Booking confirmation')}>
          <Switch checked={model.bookingConfirmation} onChange={handleBookingConfirmationChange} />
        </Form.Item>
        <Form.Item name="bookingChanging" label={t('Booking changing')}>
          <Switch checked={model.bookingChanging} onChange={handleBookingChangingChange} />
        </Form.Item>
        <Form.Item name="bookingCancelation" label={t('Booking cancelation')}>
          <Switch checked={model.bookingCancelation} onChange={handleBookingCancelationChange} />
        </Form.Item>
        <div className="text-right">
          <Button
            className="mx-2"
            type="primary"
            onClick={handleSave}
          >
            {t('Save')}
          </Button>
        </div>
      </Form>
    </Spin>
  );
};
