import RequestService from './request-service';
import { IAuthCredential } from '../models/IAuthCredential';
import { AxiosResponse } from 'axios';
import { IMasterInput, IMasterUpdateInput } from '../models/IUser';

export default class MasterService extends RequestService {
  public onLoad = async (token: string): Promise<AxiosResponse> => this.instance.post('master/on-load', { token });

  public login = async (credential: IAuthCredential): Promise<AxiosResponse> =>
    this.instance.post('master/login', credential);

  public signUp = (masterInput: IMasterInput, captcha?: string, isEmployee?: boolean): Promise<AxiosResponse> =>
    this.instance.post('master/create-master', {
      master: masterInput,
      captcha,
      isEmployee,
    });

  public updateMaster = (userDataToUpdate: IMasterUpdateInput, masterId: string): Promise<AxiosResponse> =>
    this.instance.put('master', userDataToUpdate, { params: { masterId } });

  public deleteMaster = (masterId: string, locationId: string): Promise<AxiosResponse> =>
    this.instance.delete('master', { params: { masterId, locationId } });

  public getMasterInfo = (masterId: string): Promise<AxiosResponse> =>
    this.instance.get('master', { params: { masterId } });

  public getMasterReservations = (): Promise<AxiosResponse> => this.instance.get('master/reservations');
}
