import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Skeleton, Spin, Table } from 'antd';
import RangePickerAnalytics from './components/RangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/app-context';
import { tableXScroll } from '../../const';
import { FlexedCol, StyledRow, StyledStatistic } from './Analytics';
import { clearStatistics } from '../../store/location/actions';
import { exportAnalyticsData, mastersRequest } from '../../store/analytics/actions';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { ANALYTICS_EMPLOYEE_COLUMNS } from './constants';
import { createBreadcrumb } from '../../store/app/utils';
import { setBreadcrumbs } from '../../store/app/actions';

type Props = {};
const EmployeeAnalytics: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mainContainerHeight } = useContext(AppContext);

  const { loading, records, locationId, moreData } = useSelector((state: IApplicationState) => ({
    records: state.analytics.records,
    loading: state.analytics.loading,
    moreData: state.analytics.moreData,
    locationId: state.location.currentLocation?._id,
  }));

  const [state, setState] = useState({
    rangeDates: [moment().startOf('month'), moment(new Date())] as any,
  });

  const filteredRecords = useMemo(() => records.filter((record: any) => record._id), [records]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      createBreadcrumb(t('Analytics'), '/analytics'),
      createBreadcrumb(t('Employees')),
    ]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    if (!(state.rangeDates && state.rangeDates.length === 2)) {
      dispatch(clearStatistics());
      return;
    }
    if (!locationId) {
      return;
    }
    dispatch(
      mastersRequest(
        {
          from: state.rangeDates[0].toString(),
          to: state.rangeDates[1].toString(),
        },
        locationId
      )
    );
    // eslint-disable-next-line
  }, [state.rangeDates, locationId]);

  const handleExport = () => {
    if (state.rangeDates && state.rangeDates.length === 2) {
      if (locationId) {
        dispatch(
          exportAnalyticsData(
            state.rangeDates[0].toString(),
            state.rangeDates[1].toString(),
            'masters',
            locationId as string
          )
        );
      }
    }
  };

  const columns = React.useMemo(() => ANALYTICS_EMPLOYEE_COLUMNS(t), [t]);

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <RangePickerAnalytics onDataChange={(dates: any) => setState({ ...state, rangeDates: dates })} />

        <StyledRow gutter={16}>
          <FlexedCol span={12} className="mb-2">
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <StyledStatistic title={t('Total reservations')} value={moreData?.totalReservations} className="mr-3" />
                <StyledStatistic title={t('Total money earned')} value={moreData?.earnMoney} precision={2} />
              </>
            )}
          </FlexedCol>

          {/* <FlexedCol span={12}>
            {loading ? (
              <Skeleton />
            ) : (
              <React.Fragment>
                <StyledStatistic
                  title={
                    <span>
                      {t('Total reservations')} <UsergroupAddOutlined />
                    </span>
                  }
                  value={moreData?.totalGroupReservations}
                />
                <StyledStatistic
                  title={
                    <span>
                      {t('Total money earned')} <UsergroupAddOutlined />
                    </span>
                  }
                  value={moreData?.earnMoneyGroup}
                  precision={2}
                />
              </React.Fragment>
            )}
          </FlexedCol> */}
          <Col span={24}>
            <Table
              scroll={{ x: tableXScroll, y: mainContainerHeight }}
              size={'small'}
              rowKey={(item: any) => item._id}
              columns={columns}
              dataSource={filteredRecords}
            />
          </Col>
          <Button className={'mobile-left-margin'} onClick={handleExport}>
            {t('Export filtered data')}
          </Button>
        </StyledRow>
      </Spin>
    </Wrapper>
  );
};

export default EmployeeAnalytics;

const Wrapper = styled.div`
  width: fit-content;
  margin: var(--large);
  display: flex;
  flex-direction: column;
  width: auto;
  padding: var(--small);
`;
