import { LocationActionTypes } from './types';
import { takeEvery, put, select } from 'redux-saga/effects';
import LocationService from '../../services/location-service';
import {
  addMasterSuccess,
  clientLocationSuccess,
  createLocationSuccess,
  getLocationStatisticsSuccess,
  getLocationWidgetSuccess,
  locationSuccess,
  onLocationRequestFailure,
  selectLocationSuccess,
  setUpLocation,
} from './actions';
import MasterService from '../../services/master-service';
import { IMasterInput } from '../../models/IUser';
import { push } from 'react-router-redux';
import { notification } from 'antd';
import { ILocationInput, ILocationUpdateInput } from '../../models/ILocation';
import i18n from 'i18next';
import { IApplicationState } from '../../models/IApplicationState';

const locationService = new LocationService();
const masterService = new MasterService();

// //TODO catch response fail
function* locationWorker(action: { type: string; payload: string }): Generator<any, any, any> {
  try {
    const response = yield locationService.getLocationInfo(action.payload);
    yield put(
      setUpLocation({
        locations: response.data.locations,
        currentLocation: response.data.primaryLocation,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

function* addMasterWorker(action: { type: string; payload: IMasterInput }): Generator<any, any, any> {
  try {
    const response = yield masterService.signUp(action.payload, undefined, true);
    if (response) {
      const master = {
        _id: response.data.user._id,
        name: response.data.user.name,
        phone: response.data.user.phone,
      };
      yield put(addMasterSuccess(master));
      yield put(push(`/dashboard?${window.location.search}`));
      notification.success({
        message: i18n.t('Master has been added successfully.'),
      });
    }
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

function* updateLocationWorker(action: {
  type: string;
  payload: { locationId: string; locationUpdateInput: ILocationUpdateInput };
}): Generator<any, any, any> {
  try {
    const response = yield locationService.updateLocation(
      action.payload.locationUpdateInput,
      action.payload.locationId
    );
    yield put(locationSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

function* createLocationWorker(action: { type: string; payload: ILocationInput }): Generator<any, any, any> {
  try {
    const response = yield locationService.createLocation(action.payload);
    yield put(createLocationSuccess(response.data));
    // const _goback = searchParams.get("_goback");
    yield put(push(`/dashboard${window.location.search}`));
    notification.success({
      message: i18n.t('The branch was successfully created.'),
    });
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

function* selectLocationWorker(action: { type: string; payload: string }): Generator<any, any, any> {
  try {
    const response = yield locationService.getLocationInfo(action.payload);
    yield put(selectLocationSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

function* clientLocationWorker(action: { type: string; payload: string[] }): Generator<any, any, any> {
  try {
    const response = yield locationService.getLocationsInfo(action.payload);
    yield put(clientLocationSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

function* getLocationWidgetWorker(action: { type: string; payload: string }): Generator<any, any, any> {
  try {
    const response = yield locationService.getLocationsWidget(action.payload);
    yield put(getLocationWidgetSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

function* createLocationWidgetWorker(action: { type: string; payload: any }): Generator<any, any, any> {
  try {
    const locationId = yield select((state: IApplicationState) => state.location.currentLocation?._id);
    const response = yield locationService.createLocationsWidget({
      locationId,
      ...action.payload,
    });
    yield put(getLocationWidgetSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

function* updateLocationWidgetWorker(action: { type: string; payload: any }): Generator<any, any, any> {
  try {
    const response = yield locationService.updateLocationsWidget(action.payload);
    yield put(getLocationWidgetSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

function* getLocationStatisticsWorker(action: { type: string; payload: { from: Date; to: Date } }): Generator<any, any, any> {
  try {
    const locationId = yield select((state: IApplicationState) => state.location.currentLocation?._id);
    const response = yield locationService.getLocationStatisticsWorker({
      locationId,
      ...action.payload,
    });
    yield put(getLocationStatisticsSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure((e as { message: string }).message));
  }
}

export function* watchLocation() {
  yield takeEvery(LocationActionTypes.LOCATION_REQUEST, locationWorker);
  yield takeEvery(LocationActionTypes.SELECT_LOCATION_REQUEST, selectLocationWorker);
  yield takeEvery(LocationActionTypes.CREATE_LOCATION_REQUEST, createLocationWorker);
  yield takeEvery(LocationActionTypes.ADD_MASTER_REQUEST, addMasterWorker);
  yield takeEvery(LocationActionTypes.UPDATE_LOCATION_REQUEST, updateLocationWorker);
  yield takeEvery(LocationActionTypes.CLIENT_LOCATION_REQUEST, clientLocationWorker);
  yield takeEvery(LocationActionTypes.GET_LOCATION_WIDGET_REQUEST, getLocationWidgetWorker);
  yield takeEvery(LocationActionTypes.CREATE_LOCATION_WIDGET_REQUEST, createLocationWidgetWorker);
  yield takeEvery(LocationActionTypes.UPDATE_LOCATION_WIDGET_REQUEST, updateLocationWidgetWorker);
  yield takeEvery(LocationActionTypes.GET_LOCATION_STATISTICS_REQUEST, getLocationStatisticsWorker);
}

const locationWatchers: any = [watchLocation()];

export default locationWatchers;
