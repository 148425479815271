import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Skeleton,
  Spin,
  Tabs,
  TimePicker,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { IMaster, IUser } from '../../models/IUser';
import { IReservation, IReservationInput } from '../../models/IReservation';
import styled from 'styled-components';
import moment from 'moment';
import {
  cleanErrors,
  clearSelectedReservation,
  createReservationRequest,
  deleteReservationRequest,
  getReservationRequest,
} from '../../store/reservation/actions';
import { capitalize, copyToClipboard, dateToMinutes, getReservationDifference } from '../../services/utils';
import { IService } from '../../models/IService';
import { reservationStatuses } from '../../const';
import { searchUsersRequest } from '../../store/user/actions';
import { RowLabel, RowLabelDot } from '../Services/NewService';
import { IDiscount } from '../../models/IDiscount';
import { ListItem, ReservationUpdatedData } from '../../components/sharedComponents/LogsDrawer';
import { CountriesFormItem } from '../../components/CountriesFormItem/CountriesFormItem';

const { confirm } = Modal;

type Props = {
  reservationId: IReservation['_id'] | null;
  shouldShowNewButton?: boolean;
  onCloseHandler: () => NodeJS.Timeout;
  onUpdate: (reservation: any, reservationId: string, originMasterId: string) => void;
};

let debounceTimer: any;

export const NewReservation: React.FC<Props> = ({ reservationId, shouldShowNewButton, onCloseHandler, onUpdate }) => {
  const dispatch = useDispatch();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [repeatModalVisibility, setRepeatModalVisibility] = useState(false);
  const [skeletonVisibility, setSkeletonVisibility] = useState(false);
  const [reservationPrice, setReservationPrice] = useState(0 as any);
  const [priceModal, setPriceModal] = useState({
    visibility: false,
    selectedDiscounts: [] as any,
    service: null as any,
  });

  const [form] = Form.useForm();
  const [repeatForm] = Form.useForm();

  const { t } = useTranslation();

  const {
    loading,
    discounts,
    services,
    locationAdmin,
    locationId,
    masters,
    users,
    loadingUsers,
    selectedReservation,
    currency,
    error,
  } = useSelector((state: IApplicationState) => ({
    loading: state.reservation.loading,
    error: state.reservation.error,
    selectedReservation: state.reservation.selectedReservation as any,
    discounts: state.discount.discounts,
    services: state.service.services.filter((service) => !(service.maxPerson > 1)),
    users: state.user.users,
    loadingUsers: state.user.loading,
    locationAdmin: state.location.currentLocation?.owner,
    currency: state.location.currentLocation?.currency,
    locationId: state.location.currentLocation?._id,
    masters: state.location.currentLocation?.masters as IMaster[],
  }));

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!loading && selectedReservation === null) {
      if (!loading) {
        if (error === null) {
          form.resetFields();
          repeatForm.resetFields();
          setModalVisibility(false);
          timeout = onCloseHandler();
          setRepeatModalVisibility(false);
          dispatch(clearSelectedReservation());
        } else {
          dispatch(cleanErrors());
        }
      }
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (reservationId) {
      setSkeletonVisibility(true);
      dispatch(getReservationRequest(reservationId));
      setModalVisibility(true);
      const cells: any = document.getElementsByClassName('rbc-event');
      const cellLength = cells.length;
      for (let i = 0; i < cellLength; i++) {
        cells[i].classList.remove('rbc-selected');
      }
    }
    // eslint-disable-next-line
  }, [reservationId]);

  useEffect(() => {
    if (selectedReservation) {
      setSkeletonVisibility(false);
      form.setFieldsValue({
        service: (selectedReservation.service as IService)._id,
        master: (selectedReservation.master as IMaster)._id,
        duration: moment(new Date().setHours(0, 0)).add(selectedReservation.duration, 'minutes'),
        // pauseAfter: moment(new Date().setHours(0, 0)).add(selectedReservation.pauseAfter, 'minutes'),
        comment: selectedReservation.comment,
        status: selectedReservation.status,
        //@ts-ignore
        discounts: selectedReservation.discounts.map((discount) => discount._id),
        reservedAt: moment(selectedReservation.reservedAt),
      });

      setReservationPrice(calculatePrice(selectedReservation.service.price, selectedReservation.discounts));
    }
    // eslint-disable-next-line
  }, [selectedReservation]);

  useEffect(() => {
    setModalVisibility(false);

    // eslint-disable-next-line
  }, []);

  const calculatePrice = (servicePrice: any, discounts: any) => {
    const percentDiscounts = discounts.filter((discount: any) => discount.typeOfAmount === '%');
    const moneyDiscounts = discounts.filter((discount: any) => discount.typeOfAmount !== '%');

    let finalPrice;

    if (servicePrice.includes('-')) {
      let prices = servicePrice.split('-');
      prices = prices.map((p: string) => {
        let price = Number(p);
        moneyDiscounts.forEach((discount: any) => (price = price - discount.amount));
        percentDiscounts.forEach((discount: any) => (price = price - (price / 100) * discount.amount));

        return Number(price).toFixed(2);
      });

      finalPrice = prices.join(' - ');
    } else {
      let price = Number(servicePrice);

      moneyDiscounts.forEach((discount: any) => (price = price - discount.amount));
      percentDiscounts.forEach((discount: any) => (price = price - (price / 100) * discount.amount));

      finalPrice = price;
    }

    return finalPrice;
  };

  const openModalHandler = () => setModalVisibility(true);
  const onCancelHandler = () => {
    if (!loading) {
      if (error === null) {
        form.resetFields();
        repeatForm.resetFields();
        setModalVisibility(false);
        onCloseHandler();
        setRepeatModalVisibility(false);
        dispatch(clearSelectedReservation());
      } else {
        dispatch(cleanErrors());
      }
    }
  };

  const onFinish = () => {
    const reservationForm = form.getFieldsValue();
    reservationForm.locationAdmin = locationAdmin;
    reservationForm.duration = dateToMinutes(reservationForm.duration);
    // reservationForm.pauseAfter = dateToMinutes(reservationForm.pauseAfter);
    reservationForm.reservedAt = reservationForm.reservedAt.toDate();

    let price;

    if (typeof reservationPrice === 'string') {
      if (reservationPrice.includes('-')) {
        const prices = reservationPrice.split('-');
        price = (Number(prices[0]) + Number(prices[1])) / 2;
      } else {
        price = Number(reservationPrice);
      }
    } else {
      price = reservationPrice;
    }

    reservationForm.price = price;

    if (reservationId) {
      onUpdate(
        getReservationDifference(selectedReservation, reservationForm),
        selectedReservation._id,
        (selectedReservation.master as IMaster)._id
      );
    } else {
      dispatch(createReservationRequest(locationId as string, reservationForm as IReservationInput));
    }
  };

  const onServiceChange = (serviceId: string) => {
    const service = services.filter((service) => service._id === serviceId)[0];
    const reservationForm = form.getFieldsValue();

    if (reservationForm.discounts) {
      const selectedDiscounts = discounts.filter((discount) => reservationForm.discounts.includes(discount._id));
      setReservationPrice(calculatePrice(service.price, selectedDiscounts));
    } else {
      setReservationPrice(calculatePrice(service.price, []));
    }

    form.setFieldsValue({
      duration: moment(new Date().setHours(0, 0)).add(service.duration, 'minutes'),
      // pauseAfter: moment(new Date().setHours(0, 0)).add(service.pauseAfter, 'minutes'),
    });
  };

  const onDiscountChange = () => {
    const reservationForm = form.getFieldsValue();

    if (reservationForm.service) {
      const service = services.filter((service) => service._id === reservationForm.service)[0];

      if (reservationForm.discounts) {
        const selectedDiscounts = discounts.filter((discount) => reservationForm.discounts.includes(discount._id));
        setReservationPrice(calculatePrice(service.price, selectedDiscounts));
      } else {
        setReservationPrice(Number(service.price));
      }
    }
  };

  const onUserSearch = (val: any) => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      if (locationId && val)
        dispatch(
          searchUsersRequest(
            locationId as string,
            {
              offset: 0,
              limit: 25,
            },
            { key: 'name', value: val },
            { query: {}, sort: {} }
          )
        );
    }, 500);
  };

  const onUserSelect = (event: any) => {
    const user = users.filter((user) => user._id === event)[0];

    form.setFieldsValue({
      discounts: user.discounts,
    });
  };

  const deleteReservationHandler = () => {
    confirm({
      title: t('Are you sure you want to delete this reservation?'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteReservationRequest(selectedReservation._id));
      },
    });
  };

  const openPriceModalHandler = () => {
    const reservationForm = form.getFieldsValue();
    if (reservationForm.service) {
      const service = services.filter((service) => service._id === reservationForm.service)[0];

      if (reservationForm.discounts) {
        const selectedDiscounts = discounts.filter((discount) => reservationForm.discounts.includes(discount._id));
        setPriceModal({
          ...priceModal,
          visibility: true,
          selectedDiscounts,
          service,
        });
      } else {
        setPriceModal({
          ...priceModal,
          visibility: true,
          selectedDiscounts: [],
          service,
        });
      }
    }
  };

  const repeatReservationCancelHandler = () => {
    setRepeatModalVisibility(false);
  };

  const repeatReservationHandler = (values: any) => {
    const newReservationData = {
      client: selectedReservation.client._id,
      comment: selectedReservation.comment,
      discounts: selectedReservation.discounts.map((d: any) => d._id),
      duration: selectedReservation.duration,
      locationAdmin: selectedReservation.locationAdmin,
      master: selectedReservation.master._id,
      // pauseAfter: selectedReservation.pauseAfter,
      price: selectedReservation.price,
      reservedAt: values.reservedAt.toDate(),
      service: selectedReservation.service._id,
    };
    dispatch(createReservationRequest(locationId as string, newReservationData as IReservationInput));
  };

  return (
    <>
      {shouldShowNewButton && (
        <Button className={'phone-button-margin'} type="primary" onClick={openModalHandler}>
          {t('Add a new reservation')}
        </Button>
      )}

      {(!repeatModalVisibility || !selectedReservation) && <Form form={repeatForm} />}
      {(!modalVisibility || skeletonVisibility) && <Form form={form} />}

      <Modal
        title={t('Repeat the reservation')}
        visible={repeatModalVisibility}
        onCancel={repeatReservationCancelHandler}
        okText={t('Repeat')}
        cancelText={t('Cancel')}
        footer={null}
      >
        {selectedReservation && (
          <React.Fragment>
            <List size="small" header={null} footer={null}>
              <ListItem>
                <Typography.Text>{t('Master')}</Typography.Text>
                <ReservationUpdatedData>
                  <Typography.Text>{selectedReservation.master.name}</Typography.Text>
                </ReservationUpdatedData>
              </ListItem>

              <ListItem>
                <Typography.Text>{t('Service')}</Typography.Text>
                <ReservationUpdatedData>
                  <Typography.Text>{selectedReservation.service.name}</Typography.Text>
                </ReservationUpdatedData>
              </ListItem>

              <ListItem>
                <Typography.Text>{t('Discounts')}</Typography.Text>
                <ReservationUpdatedData>
                  <Typography.Text>{selectedReservation.discounts.map((d: any) => d.name).join(', ')}</Typography.Text>
                </ReservationUpdatedData>
              </ListItem>

              <ListItem>
                <Typography.Text>{t('Price')}</Typography.Text>
                <ReservationUpdatedData>
                  <Typography.Text>
                    <Popover
                      onVisibleChange={(ev) => (ev ? openPriceModalHandler() : null)}
                      placement="topLeft"
                      title={t('Price')}
                      content={
                        priceModal.selectedDiscounts.length !== 0 ? (
                          <List
                            header={null}
                            footer={`${reservationPrice} ${currency}`}
                            dataSource={priceModal.selectedDiscounts}
                            renderItem={(discount: IDiscount) => (
                              <List.Item
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <span style={{ display: 'block' }}>{capitalize(discount.name)}</span>
                                <span style={{ display: 'block' }}>
                                  - {discount.amount} {discount.typeOfAmount}
                                </span>
                              </List.Item>
                            )}
                          />
                        ) : (
                          <p>{`${reservationPrice} ${currency}`}</p>
                        )
                      }
                      trigger="click"
                    >
                      <Col span={24}>
                        <React.Fragment>
                          <p style={{ margin: '0px 0px 10px 10px' }}>
                            {reservationPrice} {currency} <InfoCircleOutlined style={{ color: '#1890ff' }} />
                          </p>
                        </React.Fragment>
                      </Col>
                    </Popover>
                  </Typography.Text>
                </ReservationUpdatedData>
              </ListItem>
            </List>

            <Form form={repeatForm} onFinish={repeatReservationHandler} layout={'vertical'}>
              <Form.Item
                rules={[{ required: true, message: t('Required field') }]}
                name="reservedAt"
                label={t('Date of reservation')}
              >
                <DatePicker
                  format={`DD MMMM [${t('at')}] HH:mm`}
                  minuteStep={1}
                  showTime={{ format: 'HH:mm' }}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <RightRow>
                <Button loading={loading} htmlType="submit" style={{ margin: '0 8px' }} type="primary">
                  {t('Create')}
                </Button>
              </RightRow>
            </Form>
          </React.Fragment>
        )}
      </Modal>
      <Modal
        title={selectedReservation && selectedReservation.client ? (selectedReservation.client as IUser).name : null}
        visible={modalVisibility}
        onCancel={onCancelHandler}
        okText={t('Create')}
        cancelText={t('Cancel')}
        footer={null}
      >
        {skeletonVisibility ? (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        ) : (
          <Spin spinning={loading}>
            <Form layout="vertical" form={form} onFinish={onFinish}>
              {reservationId ? (
                <div>
                  <div className={'ant-col ant-form-item-label'}>
                    <label>{t('Phone')}</label>
                  </div>
                  <br />
                  {selectedReservation && (
                    <>
                      <div
                        style={{ marginBottom: 16 }}
                        onClick={() => {
                          copyToClipboard(
                            `${(selectedReservation.client as IUser).countryCode}${
                              (selectedReservation.client as IUser).phone
                            }`
                          );
                          message.success(
                            `${t('Copied')}: ${(selectedReservation.client as IUser).countryCode}${
                              (selectedReservation.client as IUser).phone
                            }`
                          );
                        }}
                      >
                        {(selectedReservation.client as IUser).countryCode}
                        {selectedReservation && (selectedReservation.client as IUser).phone}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <Row gutter={16}>
                  <Col span={24}>
                    <Tabs defaultActiveKey="1">
                      <Tabs.TabPane tab={t('Find a customer')} key="1">
                        <Form.Item
                          name="client"
                          rules={[{ required: true, message: t('Required field') }]}
                          label={t('Customer')}
                        >
                          <Select
                            allowClear
                            loading={loadingUsers}
                            notFoundContent={null}
                            showSearch
                            filterOption={false}
                            placeholder={t('Start entering customer name')}
                            onSearch={onUserSearch}
                            onChange={onUserSelect}
                            style={{ width: '100%' }}
                          >
                            {users.map((user, index) => (
                              <Select.Option
                                label={`${user.name} ( ${user.countryCode}${user.phone} )`}
                                value={user._id}
                                key={index}
                              >
                                {user.name} ( {user.countryCode}
                                {user.phone} )
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab={t('Create a new customer')} key="2">
                        <Form.Item name="name" label={t('Name')} rules={[{ message: t('Required field') }]}>
                          <Input placeholder={t('Enter customer name')} />
                        </Form.Item>

                        <CountriesFormItem />
                      </Tabs.TabPane>
                    </Tabs>
                  </Col>
                </Row>
              )}
              <Divider style={{ marginTop: 0, marginBottom: 12 }} />
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="master"
                    rules={[{ required: true, message: t('Required field') }]}
                    label={t('Master')}
                  >
                    <Select
                      disabled={selectedReservation && selectedReservation.status === 'done'}
                      placeholder={t('Select a master')}
                      style={{ width: '100%' }}
                      optionLabelProp="label"
                    >
                      {masters?.map((masters, index) => (
                        <Select.Option
                          disabled={selectedReservation && selectedReservation.status === 'done'}
                          key={index}
                          value={masters._id}
                          label={masters.name}
                        >
                          {masters.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={reservationId ? 12 : 24}>
                  <Form.Item
                    name="service"
                    rules={[{ required: true, message: t('Required field') }]}
                    label={t('Services')}
                  >
                    <Select
                      onChange={onServiceChange}
                      style={{ width: '100%' }}
                      placeholder={t('Select services')}
                      optionLabelProp="label"
                      disabled={selectedReservation && selectedReservation.status === 'done'}
                    >
                      {services?.map((service, index) => (
                        <Select.Option
                          disabled={selectedReservation && selectedReservation.status === 'done'}
                          key={index}
                          value={service._id}
                          label={service.name}
                        >
                          {service.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {reservationId && (
                  <Col span={12}>
                    <Form.Item
                      name="status"
                      rules={[{ required: true, message: t('Required field') }]}
                      label={t('Status')}
                    >
                      <Select
                        style={{ width: '100%' }}
                        disabled={selectedReservation && selectedReservation.status === 'done'}
                      >
                        {reservationStatuses?.map((status, index) => (
                          <Select.Option
                            disabled={selectedReservation && selectedReservation.status === 'done'}
                            key={index}
                            value={status}
                            label={t(`[Reservation status] ${status}`)}
                          >
                            {t(`[Reservation status] ${status}`)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Form.Item name="discounts" label={t('Discounts')}>
                <Select
                  mode="multiple"
                  onChange={onDiscountChange}
                  placeholder={t('Select discounts')}
                  style={{ width: '100%' }}
                  optionLabelProp="label"
                  disabled={selectedReservation && selectedReservation.status === 'done'}
                >
                  {discounts.map((discount, index) => (
                    <Select.Option
                      disabled={selectedReservation && selectedReservation.status === 'done'}
                      key={index}
                      value={discount._id}
                      label={discount.name}
                    >
                      {discount.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Row gutter={16}>
                <Col span={window.innerWidth >= 800 ? 12 : 24}>
                  <Form.Item
                    name="duration"
                    label={t('Duration')}
                    rules={[{ required: true, message: t('Required field') }]}
                  >
                    <TimePicker
                      disabled={selectedReservation && selectedReservation.status === 'done'}
                      style={{ width: '100%' }}
                      minuteStep={1}
                      format={'HH:mm'}
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={window.innerWidth >= 800 ? 12 : 24}>
                  <Form.Item
                    name="pauseAfter"
                    label={t('Pause after')}
                    rules={[{ required: true, message: t('Required field') }]}
                  >
                    <TimePicker
                      disabled={selectedReservation && selectedReservation.status === 'done'}
                      style={{ width: '100%' }}
                      minuteStep={1}
                      format={'HH:mm'}
                    />
                  </Form.Item>
                </Col> */}
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    rules={[{ required: true, message: t('Required field') }]}
                    name="reservedAt"
                    label={t('Date of reservation')}
                  >
                    <DatePicker
                      format={`DD MMMM [${t('at')}] HH:mm`}
                      disabled={selectedReservation && selectedReservation.status === 'done'}
                      minuteStep={1}
                      showTime={{ format: 'HH:mm' }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Popover
                  onVisibleChange={(ev) => (ev ? openPriceModalHandler() : null)}
                  placement="topLeft"
                  title={t('Price')}
                  content={
                    priceModal.selectedDiscounts.length !== 0 ? (
                      <List
                        header={null}
                        footer={`${reservationPrice} ${currency}`}
                        dataSource={priceModal.selectedDiscounts}
                        renderItem={(discount: IDiscount) => (
                          <List.Item
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span style={{ display: 'block' }}>{capitalize(discount.name)}</span>
                            <span style={{ display: 'block' }}>
                              - {discount.amount} {discount.typeOfAmount}
                            </span>
                          </List.Item>
                        )}
                      />
                    ) : (
                      <p>{`${reservationPrice} ${currency}`}</p>
                    )
                  }
                  trigger="click"
                >
                  <Col span={24}>
                    <React.Fragment>
                      <RowLabel className="ant-form-item-required">
                        <RowLabelDot>*</RowLabelDot>
                        {t('Price')}
                      </RowLabel>
                      <p style={{ margin: '0px 0px 10px 10px' }}>
                        {reservationPrice} {currency}
                        <InfoCircleOutlined style={{ color: '#1890ff', marginLeft: '10px' }} />
                      </p>
                    </React.Fragment>
                  </Col>
                </Popover>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="comment" label={t('Comment or additional information')}>
                    <Input.TextArea disabled={selectedReservation && selectedReservation.status === 'done'} rows={3} />
                  </Form.Item>
                </Col>
              </Row>

              <RightRow>
                {reservationId && (
                  <React.Fragment>
                    <Button onClick={() => setRepeatModalVisibility(true)} style={{ margin: '0 8px' }} type="primary">
                      {t('Repeat')}
                    </Button>
                    <Button onClick={deleteReservationHandler} style={{ margin: '0 8px' }} type="primary" danger>
                      {t('Delete')}
                    </Button>
                  </React.Fragment>
                )}
                <Button
                  disabled={selectedReservation && selectedReservation.status === 'done'}
                  loading={loading}
                  htmlType="submit"
                  style={{ margin: '0 8px' }}
                  type="primary"
                >
                  {t(reservationId ? 'Update' : 'Create')}
                </Button>
              </RightRow>
            </Form>
          </Spin>
        )}
      </Modal>
    </>
  );
};

export const RightRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;
