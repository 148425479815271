import React, { useEffect } from 'react';
import { Form, Button, Col, Row, Card, Input, DatePicker } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { dateFormat } from '../../const';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { updateLocationRequest } from '../../store/location/actions';
import { ILocationUpdateInput } from '../../models/ILocation';

const { RangePicker } = DatePicker;
type Props = {};

const Wrapper = styled(Card)`
  margin: 0 auto;
  display: block;
  max-width: 800px;
`;

const ClosureSettings: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { location } = useSelector((state: IApplicationState) => ({
    location: state.location.currentLocation,
  }));

  useEffect(() => {
    if (location?.closeReason) {
      form.setFieldsValue({
        title: location.closeReason.title,
        description: location.closeReason.description,
        until: [moment(location.closeReason.until.from), moment(location.closeReason.until.to)],
      });
    }
    // eslint-disable-next-line
  }, [location]);

  const submitForm = () => {
    const closeReason = form.getFieldsValue();
    closeReason.until = {
      from: closeReason.until[0].toISOString(),
      to: closeReason.until[1].toISOString(),
    };

    dispatch(updateLocationRequest({ closeReason } as ILocationUpdateInput, location?._id as string));
  };

  const openNowHandler = () => {
    form.resetFields();
    dispatch(updateLocationRequest({ closeReason: null } as ILocationUpdateInput, location?._id as string));
  };

  return (
    <Wrapper>
      <Form layout="vertical" hideRequiredMark form={form} onFinish={submitForm}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item rules={[{ required: true, message: t('Required field') }]} name="title" label={t('Reason for closure')}>
              <Input placeholder={t('Enter a reason for closure')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              rules={[{ required: true, message: t('Required field') }]}
              name="description"
              label={t('Description')}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <Form.Item
              name="until"
              label={t('Closing period')}
              rules={[{ type: 'array', required: true, message: t('Required field') }]}
            >
              <RangePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>

        <div
          style={{
            textAlign: 'right',
          }}
        >
          {location?.closeReason && (
            <Button type="dashed" onClick={openNowHandler}>
              {t('Open now')}
            </Button>
          )}
          <Button style={{ margin: '0 8px' }} type="primary" htmlType="submit">
            {t('Save')}
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

export default ClosureSettings;
