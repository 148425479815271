import { Reducer } from 'redux';
import { SubscriptionTypes, SubscriptionState } from './types';

export const initialState: SubscriptionState = {
  subscription: null,
  loading: false,
  // error: null,
};

const reducer: Reducer<SubscriptionState> = (state = initialState, action) => {
  switch (action.type) {
    case SubscriptionTypes.FETCH_USER_SUBSCRIPTION: {
      return { ...state, loading: true };
    }

    case SubscriptionTypes.UPDATE_USER_SUBSCRIPTION: {
      return { ...state, loading: true };
    }
    case SubscriptionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        subscription: action.payload.subscription,
      };
    }
    case SubscriptionTypes.FETCH_USER_SUBSCRIPTION_FAIL: {
      return {
        subscription: null,
        loading: false,
      };
    }
    case SubscriptionTypes.UPDATE_USER_SUBSCRIPTION_SUCCESS: {
      return {
        subscription: null,
        loading: false,
      };
    }
    case SubscriptionTypes.UPDATE_USER_SUBSCRIPTION_FAIL: {
      return {
        subscription: null,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as SubscriptionReducer };
