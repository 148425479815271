import { Button, Form, Input, message, Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAuthContactUs } from '../../../models/IAuthCredential';
import UserService from '../../../services/user-service';

const userService = new UserService();

/* eslint-disable no-template-curly-in-string */

export const ContactDetailsFormDialog = (props: ContactDetailsFormDialogProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const validateMessages = useMemo(() => ({
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }), []);

  const handleFinish = async (payload: IAuthContactUs) => {
    setLoading(true);
    try {
      await userService.contactUsRequest(
        payload.name,
        payload.email,
        payload.companyName,
        payload.user.introduction
      );
      message.success(t('Your request has been sent.'));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Modal
      title={t('Contact Details')}
      visible={props.visible}
      onCancel={props.onClose}
      footer={null}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="nest-messages"
        onFinish={handleFinish}
        validateMessages={validateMessages}
      >
        <Form.Item name={'name'} label={t('Name')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={'email'} label={t('E-mail')} rules={[{ type: 'email', required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name={'companyName'} label={t('Company name')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={['user', 'introduction']} label={t('Description')} rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('Submit')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

interface ContactDetailsFormDialogProps {
  visible: boolean;
  onClose: () => void;
}
