import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import styled from 'styled-components';
import { applyWhen } from '../../const';
import { createDiscountRequest, deleteDiscountRequest, updateDiscountRequest } from '../../store/discount/actions';
import { IDiscount, IDiscountInput } from '../../models/IDiscount';
import { IUserUpdateInput } from '../../models/IUser';
import { capitalize } from 'lodash';

const RightRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;
type Props = {
  discount: IDiscount | null;
  onCloseHandler: () => void;
};
const NewDiscount: React.FC<Props> = ({ discount, onCloseHandler }) => {
  const dispatch = useDispatch();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [discountOption, setDiscountOption] = useState('');

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const { loading, locationId, discountsLength, currency } = useSelector((state: IApplicationState) => ({
    loading: state.discount.loading,
    discountsLength: state.discount.discounts.length,
    currency: state.location.currentLocation?.currency as string,
    locationId: state.location.currentLocation?._id as string,
  }));

  useEffect(() => {
    if (!loading) onCancelHandler();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    setModalVisibility(false);
    form.setFieldsValue({
      enabled: true,
      typeOfAmount: '%',
      applyWhen: 'oneTime',
    });
    // eslint-disable-next-line
  }, [discountsLength]);

  useEffect(() => {
    if (discount) {
      form.setFieldsValue({
        enabled: discount.enabled,
        typeOfAmount: discount.typeOfAmount,
        applyWhen: discount.applyWhen,
        name: discount.name,
        currency: discount.currency,
        discount: discount.discount,
        amount: discount.amount,
      });
      setModalVisibility(true);
    }
    // eslint-disable-next-line
  }, [discount]);

  useEffect(() => {
    setModalVisibility(false);
    form.setFieldsValue({
      enabled: true,
      typeOfAmount: '%',
      applyWhen: 'oneTime',
    });
    // eslint-disable-next-line
  }, []);

  const addServiceModalHandler = () => setModalVisibility(true);
  const onCancelHandler = () => {
    if (!loading) {
      form.resetFields();
      setModalVisibility(false);
      onCloseHandler();
    }
  };

  const onFinish = () => {
    const discountForm = form.getFieldsValue();

    if (discount) {
      dispatch(updateDiscountRequest(discountForm as IUserUpdateInput, discount._id as string));
    } else {
      dispatch(createDiscountRequest(discountForm as IDiscountInput, locationId));
    }
  };

  const onDiscountTypeChange = (type: string) => setDiscountOption(type);

  const deleteDiscountHandler = () => {
    console.log(discount);
    if (!discount) return;
    dispatch(deleteDiscountRequest(discount._id, locationId));
  };

  let discountOptions;

  switch (discountOption) {
    case 'firstTime':
    case 'oneTime':
      discountOptions = null;
      break;
    case 'every':
    case 'afterQuantity':
      discountOptions = (
        <Form.Item
          name="discount"
          label={t('Quantity of reservations')}
          rules={[{ required: true, message: t('Required field') }]}
        >
          <InputNumber placeholder={t('Enter quantity of reservations')} style={{ width: '100%' }} />
        </Form.Item>
      );
      break;
    case 'afterAmount':
      discountOptions = (
        <Form.Item name="discount" label={t('Total')} rules={[{ required: true, message: t('Required field') }]}>
          <InputNumber placeholder={t('Enter total amount')} style={{ width: '100%' }} />
        </Form.Item>
      );
      break;
    default:
      discountOptions = null;
  }

  return (
    <>
      <Button
        style={{ width: '100%' }}
        className={'phone-button-margin-full'}
        type="primary"
        onClick={addServiceModalHandler}
      >
        {t('Add a new discount')}
      </Button>

      {!modalVisibility && <Form form={form} />}

      <Modal
        title={discount ? discount.name : t('New discount')}
        visible={modalVisibility}
        onCancel={onCancelHandler}
        okText={t('Create')}
        cancelText={t('Cancel')}
        footer={null}
      >
        <Spin spinning={loading}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item name="name" label={t('[Thing] Name')} rules={[{ required: true, message: t('Required field') }]}>
              <Input placeholder={t('Enter discount name')} />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="amount" label={t('Amount')} rules={[{ required: true, message: t('Required field') }]}>
                  <InputNumber placeholder={t('Enter discount amount')} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true, message: t('Required field') }]}
                  name="typeOfAmount"
                  label={t('Type of quantity')}
                >
                  <Select placeholder={t('Select unit of discount amount')} style={{ width: '100%' }} optionLabelProp="label">
                    <Select.Option value={'%'} label={'%'}>
                      %
                    </Select.Option>
                    <Select.Option value={currency} label={t(capitalize(currency))}>
                      {t(capitalize(currency))}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="applyWhen" label={t('When to add')}>
                  <Select
                    optionLabelProp="label"
                    placeholder={t('Select condition of discounting')}
                    style={{ width: '100%' }}
                    onSelect={(type: any) => onDiscountTypeChange(type)}
                  >
                    {applyWhen.map((cond: string, index: number) => (
                      <Select.Option key={index} value={cond} label={t(`[When to add] ${cond}`)}>
                        {t(`[When to add] ${cond}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {discountOptions}

            <Form.Item name="enabled" valuePropName="checked">
              <Checkbox>{t('Enabled')}</Checkbox>
            </Form.Item>

            <RightRow>
              {discount && (
                <Button danger type="primary" style={{ margin: '0 8px' }} onClick={deleteDiscountHandler}>
                  {t('Delete')}
                </Button>
              )}
              <Button loading={loading} htmlType="submit" style={{ margin: '0 8px' }} type="primary">
                {t(discount ? 'Update' : 'Create')}
              </Button>
            </RightRow>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default NewDiscount;
