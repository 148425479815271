import React, { createContext, useState } from 'react';
import { NEW_LOCATION_DATA } from '../../const';
import { IFirstStep } from './FirstStep';
import { ISecondStep } from './SecondStep';

type CreateLocationSteps = {
  currentStep: number;
  firstStep: IFirstStep;
  secondStep: ISecondStep;
};

type Props = {
  children: React.ReactNode;
};

const createLocationSteps: CreateLocationSteps = JSON.parse(
  localStorage.getItem(NEW_LOCATION_DATA) || '{}'
) as CreateLocationSteps;

type Context = {
  currentStep: number;
  firstStep: IFirstStep;
  secondStep: ISecondStep;
  coordinate: {
    lat: any;
    lng: any;
  };
  setContext: (locationData: {
    firstStep?: IFirstStep;
    secondStep?: ISecondStep;
    currentStep?: number;
    coordinate?: any;
  }) => void;
};

const initialContext: Context = {
  currentStep: createLocationSteps.currentStep || 0,
  firstStep: createLocationSteps.firstStep,
  secondStep: createLocationSteps.secondStep,
  coordinate: {
    lat: 51.4,
    lng: 0,
  },
  setContext: (): void => {
    throw new Error('setContext function must be overridden');
  },
};

const CreateLocationContext = createContext<Context>(initialContext);

const CreateLocationContextProvider = ({ children }: Props): JSX.Element => {
  const [contextState, _setContext] = useState<Context>(initialContext);

  const setContext = (locationData: {
    firstStep?: IFirstStep;
    secondStep?: ISecondStep;
    currentStep?: number;
    coordinate?: any;
  }) => {
    const { setContext, ...values } = { ...contextState, ...locationData };

    localStorage.setItem(NEW_LOCATION_DATA, JSON.stringify(values));

    _setContext((prevState) => {
      return { ...prevState, ...locationData };
    });
  };

  return (
    <CreateLocationContext.Provider value={{ ...contextState, setContext }}>{children}</CreateLocationContext.Provider>
  );
};

export { CreateLocationContext, CreateLocationContextProvider };
