import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, FormInstance, Input, message, Row, Select, Tooltip, Upload } from 'antd';
import { CreateLocationContext } from './create-location-context';
import countries from '../../const/countries';
import countriesRu from '../../const/countries.ru';
import countriesUk from '../../const/countries.uk';
import { LoadingOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { APP_LANG } from '../../const';
import { IApplicationState } from '../../models/IApplicationState';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { GoogleMap, Marker, Autocomplete, useLoadScript } from '@react-google-maps/api';
import { env } from '../../const/env';
import { fetchCategories } from '../../services/categories.service';
import { CountriesFormItem } from '../../components/CountriesFormItem/CountriesFormItem';
import { IEmail, IPhone } from '../../models/ILocation';

export interface IFirstStep {
  address: string;
  avatarUrl: string;
  category: string[];
  city: string;
  country: string;
  name: string;
  emails: IEmail[];
  phones: IPhone[];
}

const Wrapper = styled.div``;

const containerStyle = {
  width: '100%',
  height: '300px',
};

let mapZoom = 10;
const LIBRARIES: ['places' | 'drawing' | 'geometry' | 'localContext' | 'visualization'] = ['places'];
type Props = {
  form: FormInstance;
  next: () => void;
};

const fetchGeoCodes = async ({ address, lat, lng }: { address?: string; lat?: number; lng?: number }) => {
  try {
    return await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?${address ? `address=${address}` : `latlng=${lat},${lng}`}&key=${env.googleMapKey}`
    );
  } catch (error) {
    throw error;
  }
};

const FirstStep: React.FC<Props> = ({ form, next }) => {
  const [autocomplete, setAutoComplete] = useState<any>(null);
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const selectedLang: any = localStorage.getItem(APP_LANG);
  const arr = !selectedLang || selectedLang === 'en' ? countries : selectedLang === 'ru' ? countriesRu : countriesUk;
  const [countriesArr, setCountriesArr] = useState(arr);
  const [state, setState] = useState({
    loading: false,
    avatarUrl: '',
  } as any);
  const [categories, setCategories] = useState<any[]>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: env.googleMapKey, // ,
    libraries: LIBRARIES,
  });

  const { setContext, firstStep, coordinate } = useContext(CreateLocationContext);

  const { userId } = useSelector((state: IApplicationState) => ({
    location: state.location.currentLocation,
    userId: state.auth.user?._id,
  }));

  const callCategoriesApi = async () => {
    try {
      const categories: any = await fetchCategories(userId!);
      setCategories(categories.data.categories);
    } catch (error) {
      throw error;
    }
  };
  const getCategories = () => {
    callCategoriesApi();
  };

  useEffect(() => {
    const address = form.getFieldValue('address');
    if (address) {
      setAddress(address);
    } else {
      axios
        .get('https://ipapi.co/json/')
        .then(response => setCoordinates(response.data.latitude, response.data.longitude))
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCategories();
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'new-password');
      //you can put any value but NOT "off" or "false" because they DO NOT works
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstStep) {
      form.setFieldsValue(firstStep);
      if (firstStep.avatarUrl) setState({ avatarUrl: firstStep.avatarUrl });
    }
    if ('geolocation' in navigator) {
      if (!(typeof coordinate.lat === 'number' && typeof coordinate.lng === 'number')) {
        navigator.geolocation.getCurrentPosition((position) => {
          setContext({
            coordinate: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstStep]);

  useEffect(() => {
    setCountriesArr(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLang]);

  const submitForm = ({ countryCode, email, phone, ...values }: any) => {
    values.avatarUrl = state.avatarUrl;
    values = {
      ...values,
      emails: [{ email, type: 'Default' }],
      phones: [{ countryCode, phone, type: 'Default' }],
    };
    setContext({ firstStep: values as IFirstStep });
    next();
  };

  const handleAvatarChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setState({ ...state, loading: true, avatarUrl: '' });
      return;
    }
    if (info.file.status === 'done') {
      setState({
        avatarUrl: info.file.response.avatarUrl,
        loading: false,
      });
    }

    if (info.file.status === 'error') {
      message.error('Cant upload avatar image');
      setState({ ...state, avatarUrl: '', loading: false });
    }
  };

  const setMapData = (data: any, lat?: number, lng?: number) => {
    const [geoCode]: any = data?.results || [];
    form.setFieldsValue({ address: geoCode?.formatted_address });
    setSelectedAddress(geoCode?.formatted_address);
    setContext({ coordinate: {
      lat: lat ?? geoCode.geometry.location.lat,
      lng: lng ?? geoCode.geometry.location.lng,
    }});
  };

  const setAddress = async (address: string) => {
    const { data }: any = await fetchGeoCodes({ address });
    setMapData(data);
  };

  const setCoordinates = async (lat: any, lng: any) => {
    const { data }: any = await fetchGeoCodes({ lat, lng });
    setMapData(data, lat, lng);
  }

  const handleMapClick = (ev: any) => setCoordinates(ev.latLng.lat(), ev.latLng.lng());

  const handlePlaceChange = () => {
    if (autocomplete === null) {
      console.log('Autocomplete is not loaded yet!');
      return;
    }
    const place = autocomplete.getPlace();
    form.setFieldsValue({
      address: place.formatted_address,
    });
    setSelectedAddress(place.formatted_address);
    const newLocation = place.geometry?.location;
    const newCoordinate = {
      lat: newLocation?.lat(),
      lng: newLocation?.lng(),
    };
    setContext({
      coordinate: newCoordinate,
    });
  };

  const uploadButton = (
    <div>
      {state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">{t('Upload')}</div>
    </div>
  );

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  if (typeof coordinate.lat === 'number' && typeof coordinate.lng === 'number') {
    mapZoom = 10;
  }

  return (
    <Wrapper>
      <Form
        autoComplete="new-password"
        form={form}
        style={{ width: '-webkit-fill-available' }}
        layout="vertical"
        onFinish={submitForm}
      >
        <Row gutter={16}>
          <ImgCrop rotate modalTitle={t('Edit image')}>
            <Upload
              name="location"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${env.uploadUrl}/upload-location`}
              beforeUpload={beforeUpload}
              onChange={handleAvatarChange}
            >
              {state.avatarUrl ? <img src={state.avatarUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </ImgCrop>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label={t('Company name')} rules={[{ required: true, message: t('Required field') }]}>
              <Input autoComplete="new-password" placeholder={t('Enter company name')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="category"
              label={
                <Tooltip placement="topLeft" title={t('You can choose or add your own.')}>
                  <span>
                    {t('Category')} <InfoCircleOutlined style={{ color: '#1890ff' }} />
                  </span>
                </Tooltip>
              }
              rules={[{ required: true, message: t('Required field') }]}
            >
              <Select
                mode="tags"
                // optionFilterProp="label"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option.props?.children?.toLowerCase().indexOf(input.toLowerCase()) !== -1
                }
                placeholder={t('Select categories')}
                style={{ width: '100%' }}
              >
                {categories?.length ? (
                  categories.map((category: any, index: any) => (
                    <Select.Option key={index} value={category.value}>
                      {!selectedLang || selectedLang === 'en'
                        ? category.en
                        : selectedLang === 'ru'
                        ? category.ru
                        : selectedLang === 'uk'
                        ? category.uk
                        : category.en}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option value={''}>No Option</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="country"
              label={t('Country')}
              // autoComplete="new-password"
              id="country_select_ant"
              rules={[{ required: true, message: t('Required field') }]}
            >
              <Select placeholder={t('Select a country')} style={{ width: '100%' }} showSearch>
                {countriesArr.map((country) => (
                  <Select.Option value={country.name} key={country.name}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="city" label={t('City')} rules={[{ required: true, message: t('Required field') }]}>
              <Input placeholder={t('Enter a city')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <CountriesFormItem label={t('Business phone')} />
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label={t('Business e-mail')}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: t('Empty field or wrong data'),
                },
              ]}
            >
              <Input
                placeholder={t('Enter an email')}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="address" label={t('Address')} rules={[{ required: true, message: t('Required field') }]}>
              <>
                {isLoaded && (
                  <Autocomplete
                    onLoad={(_autocomplete: any) => {
                      setAutoComplete(_autocomplete);
                    }}
                    onPlaceChanged={handlePlaceChange}
                  >
                    <Input
                      value={selectedAddress}
                      onChange={({ target }) => {
                        setSelectedAddress(target.value);
                      }}
                      type="text"
                    />
                  </Autocomplete>
                )}
              </>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                onClick={handleMapClick}
                center={{
                  lat: coordinate.lat,
                  lng: coordinate.lng,
                }}
                zoom={mapZoom}
              >
                {/* {coordinate.lat && coordinate.lng && ( */}
                <Marker position={coordinate} draggable onDragEnd={handleMapClick} />
                {/* )} */}
                <></>
              </GoogleMap>
            )}
          </Col>
        </Row>

        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button disabled={state.loading} type="primary" htmlType="submit">
            {t('Next')}
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

export default FirstStep;
