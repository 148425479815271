import React, { useEffect, useMemo, useState } from 'react';
import { Form, Modal, Button, Col, Row, Input, Select, Spin, Card, Upload, message, Checkbox } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { useLocation } from 'react-router-dom';
import { IService } from '../../models/IService';
import { IWorkingHours } from '../../models/IWorkingHours';
import { APP_LANG, languages, weekDays } from '../../const';
import {
  formWithWorkingDaysToCleanObject,
  workingHoursToFormData,
  compactObject,
} from '../../services/utils';
import { IMaster, IMasterInput, IMasterUpdateInput } from '../../models/IUser';
import { addMasterRequest } from '../../store/location/actions';
import { useTranslation } from 'react-i18next';
import { deleteMasterRequest, updateMasterRequest } from '../../store/master/actions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getServicesRequest } from '../../store/service/actions';
import { ILocation } from '../../models/ILocation';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { env } from '../../const/env';
import { TimeSlots } from '../TimeSlots/TimeSlots';
import { CountriesFormItem } from '../CountriesFormItem/CountriesFormItem';
import { hasAdministratorPermissions } from '../../services/auth-service';

const { confirm } = Modal;

const { Option } = Select;

type Props = {
  profile?: boolean;
  master?: IMaster;
};

const Wrapper = styled(Card)`
  margin: 0 auto;
  display: block;
  max-width: 800px;
`;

const EmployeeForm: React.FC<Props> = props => {
  const [form] = Form.useForm();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [role, setRole] = useState(props.master?.role || 'master');

  const [state, setState] = useState({
    loading: false,
    avatarUrl: '',
  } as any);

  const { workingHours, loadingMaster, loadingLocation, services, locationId, locations, authenticatedUser } = useSelector(
    (state: IApplicationState) => {
      return {
        workingHours: state.location.currentLocation?.workingHours,
        loadingLocation: state.location.loading,
        loadingMaster: state.master.loading,
        services: state.service.services,
        locationId: state.location.currentLocation?._id || state.service.loading,
        locations: state.location.locations,
        authenticatedUser: state.auth.user,
      } as {
        workingHours: IWorkingHours;
        loadingLocation: boolean;
        loadingMaster: boolean;
        services: IService[];
        locationId: string;
        authenticatedUser: IMaster | null;
        locations: ILocation[];
      };
    }
  );

  const hasUserAdministratorPermissions = useMemo(() => authenticatedUser ? hasAdministratorPermissions(authenticatedUser) : false, [authenticatedUser]);

  const loading = loadingLocation || loadingMaster;

  useEffect(() => {
    if (locationId) dispatch(getServicesRequest(locationId as string));
    // eslint-disable-next-line
  }, [locationId]);

  useEffect(() => {
    if (props.master) {
      setRole(props.master?.role);
    }
  }, [props.master]);

  useEffect(() => {
    if (!props.master) {
      form.resetFields();
      if (workingHours) {
        form.setFieldsValue({
          ...workingHoursToFormData(workingHours || {}),
          role: 'master',
        });
        setState({ ...state, avatarUrl: '' });
      }
    }
    // eslint-disable-next-line
  }, [search, props.master, workingHours]);

  useEffect(() => {
    if (locations.length === 1 && !props.master && !form.getFieldValue('primaryLocation')) {
      form.setFieldsValue({ primaryLocation: locations[0]._id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, locations.length]);

  useEffect(() => {
    if (props.master) {
      form.setFieldsValue({
        ...props.master,
        services: (props.master.services as (IService | string)[]).map(service =>
          typeof service == 'string' ? service : service._id
        ),
        language: window.localStorage.getItem(APP_LANG) || 'en',
        ...workingHoursToFormData(props.master.workingHours || {}),
      });
      setState({ ...state, avatarUrl: props.master.avatarUrl });
      // moment.locale(master.language, momentLangSetup[master.language]);
      // i18n.changeLanguage(master.language);
    }

    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.master]);

  const deleteMasterConfirmHandler = () => {
    confirm({
      title: t('Are you sure you want to delete this employee?'),
      icon: <ExclamationCircleOutlined />,
      content: t('After deletion, all his reservations will be transferred to the administrator.'),
      onOk() {
        dispatch(deleteMasterRequest(props.master?._id as string, locationId));
      },
    });
  };

  const submitForm = () => {
    if (!loading) {
      const masterData: any = {} as IMasterInput | IMasterUpdateInput;
      masterData.primaryLocation = locationId;

      const _values: any = compactObject({ ...form.getFieldsValue() });
      localStorage.setItem(APP_LANG, _values.language as string);
      const _formatedWorkingHours: any = {};
      for (const day of weekDays) {
        if (_values[`${day}-from-start`] && _values[`${day}-from-end`]) {
          const _fromStart = _values[`${day}-from-start`];
          const _fromEnd = _values[`${day}-from-end`];
          _formatedWorkingHours[`${day}-from`] = [_fromStart, _fromEnd];
        }
        if (_values[`${day}-to-start`] && _values[`${day}-to-end`]) {
          const toStart = _values[`${day}-to-start`];
          const toEnd = _values[`${day}-to-end`];
          _formatedWorkingHours[`${day}-to`] = [toStart, toEnd];
        }
      }

      formWithWorkingDaysToCleanObject(masterData, {
        name: _values.name,
        role: _values.role,
        phone: _values.phone,
        email: _values.email,
        services: _values.services,
        language: _values.language,
        specialization: _values.specialization,
        isPerformer: _values.isPerformer || false,
        ..._formatedWorkingHours,
      });

      masterData.avatarUrl = state.avatarUrl;
      window.localStorage.setItem(APP_LANG, _values.language);
      if (props.master) {
        dispatch(updateMasterRequest({ ...masterData, language: i18n.language }, props.master._id))
      } else {
        dispatch(
          addMasterRequest({
            ...masterData,
            language: _values.language || 'en',
            countryCode: _values.countryCode,
          })
        );
      }
    }
  };

  const handleAvatarChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setState({ ...state, loading: true, avatarUrl: '' });
      return;
    }
    if (info.file.status === 'done') {
      if (props.master) {
        dispatch(
          updateMasterRequest(
            { avatarUrl: info.file.response.avatarUrl } as IMasterUpdateInput,
            props.master._id
          )
        );
      }
      setState({
        avatarUrl: info.file.response.avatarUrl,
        loading: false,
      });
    }

    if (info.file.status === 'error') {
      message.error('Cant upload avatar image');
      setState({ ...state, avatarUrl: '', loading: false });
    }
  };

  const uploadButton = (
    <div>
      {state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">{t('Upload')}</div>
    </div>
  );

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleAddServicesSelect = () => form.setFieldsValue({ services: services.map(service => service._id) });

  return (
    <Wrapper>
      <Spin spinning={loading} tip={t('Loading')}>
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={16}>
            <ImgCrop rotate modalTitle={t('Edit image')}>
              <Upload
                name="master"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${env.uploadUrl}/upload-master`}
                beforeUpload={beforeUpload}
                onChange={handleAvatarChange}
              >
                {state.avatarUrl ? <img src={state.avatarUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </ImgCrop>
          </Row>
          <Row gutter={16}>
            <Col span={window.innerWidth >= 800 ? 12 : 24}>
              <Form.Item name="name" label={t('Name')} rules={[{ required: true, message: t('Required field') }]}>
                <Input placeholder={t('Enter employee name')} />
              </Form.Item>
            </Col>
            <Col span={window.innerWidth >= 800 ? 12 : 24}>
              <Form.Item name="role" label={t('Role')}>
                <Select onChange={role => setRole(role)}>
                  <Select.Option value="master">{t('Master')}</Select.Option>
                  <Select.Option disabled={!hasUserAdministratorPermissions} value="admin">{t('Administrator')}</Select.Option>
                  <Select.Option disabled={!hasUserAdministratorPermissions} value="owner">{t('Owner')}</Select.Option>
                  <Select.Option disabled={!hasUserAdministratorPermissions} value="manager">{t('Manager')}</Select.Option>
                  <Select.Option disabled={!hasUserAdministratorPermissions} value="marketer">{t('Marketing specialist')}</Select.Option>
                  <Select.Option disabled={!hasUserAdministratorPermissions} value="developer">{t('Developer')}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {!props.profile && role !== 'master' && (
            <Form.Item name="isPerformer" valuePropName="checked">
              <Checkbox>{t('The employee provides services to clients')}</Checkbox>
            </Form.Item>
          )}
          <Row gutter={16}>
            <Col span={window.innerWidth >= 800 ? 12 : 24}>
              <CountriesFormItem />
            </Col>
            <Col span={window.innerWidth >= 800 ? 12 : 24}>
              <Form.Item
                name="email"
                label={t('E-mail')}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: t('Empty field or wrong data'),
                  },
                ]}
              >
                <Input placeholder={t('Enter employee email')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="flex-grow-1">
              <Form.Item name="services" label={t('Services')}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('Select services')}
                  optionLabelProp="label"
                >
                  {services?.map((service) => (
                    <Option key={service._id} value={service._id} label={service.name}>
                      {service.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="flex-shrink-0" style={{ marginLeft: -8, paddingTop: 30 }}>
              <Button type="primary" onClick={handleAddServicesSelect}>{t('Select all services')}</Button>
            </Col>
          </Row>

          <Row gutter={16}>
            {locations.length > 1 && <Col span={window.innerWidth >= 800 ? 12 : 24}>
              <Form.Item name="primaryLocation" label={t('Main location')} rules={[{ required: true, message: t('Required field') }]}>
                <Select placeholder={t('Select the main branch')} style={{ alignItems: 'center' }}>
                  {locations.map((location) => (
                    <Select.Option key={location._id} value={location._id} label={location.name}>
                      {location.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>}
            <Col span={window.innerWidth >= 800 && locations.length > 1 ? 12 : 24}>
              <Form.Item name="specialization" label={t('Specialization')}>
                <Input placeholder={t('Enter employee specialization')} />
              </Form.Item>
            </Col>
          </Row>

          {props.profile && (
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="language" label={t('Language')}>
                  <Select style={{ alignItems: 'center' }}>
                    {languages.map((language) => (
                      <Select.Option key={language} value={language} label={t(`[Language] ${language}`)}>
                        {t(`[Language] ${language}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <div className={'ant-col ant-form-item-label'}>
            <label>{t('Working hours')}</label>
          </div>
          <TimeSlots form={form} />

          <div className="mt-4 text-right">
            {props.master && !props.profile && (
              <Button className="mr-2" type="primary" danger onClick={deleteMasterConfirmHandler}>
                {t('Delete')}
              </Button>
            )}
            <Button type="primary" htmlType="submit">
              {t(props.master ? 'Update' : 'Create')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Wrapper>
  );
};

export default EmployeeForm;
