import { Alert, Button, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { useTranslation } from 'react-i18next';
import { PricingCard, PricingPlan } from './PricingCard';
import { CheckoutFormDialog } from './CheckoutFormDialog/CheckoutFormDialog';
import { cancelSubscription, createSubscription, getSubscriptionPlans } from '../../services/subscriptions.service';
import { ContactDetailsFormDialog } from './ContactDetailsFormDialog/ContactDetailsFormDialog';

const Pricing = (): JSX.Element => {
  const { t } = useTranslation();
  const [planInterval, setPlanInterval] = useState<'year' | 'month'>('year');

  const [loading, setLoading] = useState<boolean>(false);

  const { locationId, subscription } = useSelector((state: IApplicationState) => ({
    locationId: state.location.currentLocation?._id as string,
    subscription: state.subscriptions.subscription,
  }));
  const [contactDetailsDialogVisible, setContactDetailsDialogVisible] = useState<boolean>(false);

  const [plans, setPlans] = useState<Record<'month' | 'year', PricingPlan[]>>({ month: [], year: [] });
  const [priceId, setPriceId] = useState<string | null>(null);
  const [checkoutDialogVisible, setCheckoutDialogVisible] = useState(false);

  const handleContactUs = () => setContactDetailsDialogVisible(true);
  const handleContactDetailsDialogClose = () => setContactDetailsDialogVisible(false);
  const handleCheckoutDialogClose = () => setCheckoutDialogVisible(false);

  const fetchPlans = async (abortController: AbortController) => {
    try {
      const response = await getSubscriptionPlans(abortController.signal);
      const plans = response.data.reduce((plans: Record<string, any[]>, plan: Record<string, any>) => {
        if (!plans[plan.interval]) {
          plans[plan.interval] = [].sort();
        }
        plans[plan.interval].push(plan);
        return plans;
      }, {});
      plans.month.sort((a: PricingPlan, b: PricingPlan) => a.name === 'Pro' || (a.name === 'Basic' && b.name === 'Solo') ? 1 : -1);
      plans.year.sort((a: PricingPlan, b: PricingPlan) => a.name === 'Pro' || (a.name === 'Basic' && b.name === 'Solo') ? 1 : -1);
      setPlans(plans);
    } catch (error) {}
    return abortController;
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchPlans(abortController);
    return () => abortController.abort();
  }, []);

  const handlePaymentMethodCreated = async (paymentMethodId: string) => {
    setLoading(true);
    try {
      setCheckoutDialogVisible(false);
      await createSubscription(locationId, priceId!, paymentMethodId);
      message.success(t('You have been subscribed successfully.') + t('Wait a while for subscription to be applied.'));
      await new Promise(resolve => setTimeout(resolve, 3e3));
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  const handlePay = async (priceId: string) => {
    if (subscription) {
      setLoading(true);
      try {
        if (subscription.status !== 'canceled') {
          await cancelSubscription(locationId);
        }
        await createSubscription(locationId, priceId!);
        message.success(t('The subscription has been changed successfully.') + t('Wait a while for subscription to be applied.'));
        await new Promise(resolve => setTimeout(resolve, 3e3));
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    } else {
      setPriceId(priceId);
      setCheckoutDialogVisible(true);
    }
  };

  return (
    <>
      <CheckoutFormDialog
        visible={checkoutDialogVisible}
        onClose={handleCheckoutDialogClose}
        onPaymentMethodCreated={handlePaymentMethodCreated}
      />
      <ContactDetailsFormDialog
        visible={contactDetailsDialogVisible}
        onClose={handleContactDetailsDialogClose}
      />

      <section className="sofbox-gradient-bg-top mb-4" id="iq-price">
        <div className="container">
          <div className="row justify-content-center position-relative">
            <div className="col-lg-12">
              {!!plans[planInterval].length && (
                <div className="pricing-container">
                  <div className="pricing-switcher">
                    <div className="col-lg-8">
                      <div className="title-box mb30">
                        <h2>{t('Prices')}</h2>

                        <p className="mb0">
                          {t('You need a separate subscription for each company location.')}
                          <br />
                          {t('Choose a subscription plan and add your business.')}
                        </p>
                      </div>
                    </div>
                    <p className="fieldset">
                      <input
                        type="radio"
                        name="duration-1"
                        value="monthly"
                        id="monthly-1"
                        onClick={() => setPlanInterval('month')}
                        defaultChecked={planInterval === 'month'}
                      />
                      <label htmlFor="monthly-1">{t('Monthly')}</label>
                      <input
                        type="radio"
                        name="duration-1"
                        value="yearly"
                        id="yearly-1"
                        onClick={() => setPlanInterval('year')}
                        defaultChecked={planInterval === 'year'}
                      />
                      <label htmlFor="yearly-1">{t('Yearly')}</label>
                      <span className="switch"></span>
                    </p>
                  </div>

                  {loading ? (
                    <div className="adding-margin">
                      <Spin tip="Loading...">
                        <Alert
                          message={t('Subscription has changed.')}
                          description={t('Please, hold on, we are changing your subscription plan.')}
                          type="info"
                        />
                      </Spin>
                    </div>
                  ) : (
                    <div>
                      <ul className="pricing-list bounce-invert">
                        {plans[planInterval].map(plan => (
                          <li key={plan.priceId}>
                            <ul className="pricing-wrapper">
                              <PricingCard plan={plan} onPay={handlePay} />
                            </ul>
                          </li>
                        ))}
                        <li>
                          <ul className="pricing-wrapper">
                            <li data-type="yearly" className="is-visible">
                              <div className="pricing-header">
                                <h2>Enterprise</h2>
                                <div className="price">
                                  <span className="value">on request</span>
                                  <div className="free"></div>
                                </div>
                              </div>
                              <div className="pricing-body">
                                <ul className="pricing-features">
                                  <li>
                                    <em>{t('Unlimited')}</em> {t('Staff members')}
                                  </li>
                                  <li>
                                    <em>{t('Unlimited')}</em> {t('Services')}
                                  </li>
                                  <li>
                                    <em>{t('Special (Conditions)')} </em> {t('Conditions')}
                                  </li>
                                  <li>
                                    <em>{t('Priority (Support)')} </em> {t('Support')}
                                  </li>
                                </ul>
                              </div>
                              <footer className="pricing-footer">
                                <Button
                                  type="primary"
                                  onClick={handleContactUs}
                                  className="select"
                                >
                                  {t('Contact Us')}
                                  {/* </Link> */}
                                </Button>
                                <div className="free">
                                  <br />
                                </div>
                              </footer>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
