import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EmployeeForm from '../../../components/EmployeeForm/EmployeeForm';
import MasterFeedback from '../../../components/MasterFeedback/MasterFeedback';
import { IApplicationState } from '../../../models/IApplicationState';
import { IMaster } from '../../../models/IUser';
import { setBreadcrumbs } from '../../../store/app/actions';
import { createBreadcrumb } from '../../../store/app/utils';
import { masterInfoRequest } from '../../../store/master/actions';

export const Employee: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();

  const master = useSelector((state: IApplicationState) => state.master.currentMaster as IMaster);

  useEffect(() => {
    dispatch(masterInfoRequest(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    if (master) {
      dispatch(setBreadcrumbs([
        createBreadcrumb(t('Employees'), '/employees'),
        createBreadcrumb(master.name),
      ]));
    }
    return () => void dispatch(setBreadcrumbs([]));
  }, [master, dispatch, t]);

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('Profile')} key="1">
          <EmployeeForm master={master} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Reviews')} key="2">
          <MasterFeedback />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
