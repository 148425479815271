import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IApplicationState } from '../../models/IApplicationState';

export const Home: React.FC = () => {
  const history = useHistory();
  const user = useSelector((state: IApplicationState) => state.auth.user);

  useEffect(() => {
    if (user) {
      history.push('/dashboard');
    } else {
      history.push('/auth');
    }
  }, [history, user]);

  return <></>;
};
