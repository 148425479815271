export interface AnalyticsState {
  readonly loading: boolean;
  readonly records: any;
  readonly total: number;
  readonly moreData: any;
}

export enum AnalyticsTypes {
  CLIENTS_REQUEST = '@@analytics/CLIENTS_REQUEST',
  CLIENTS_FAILURE = '@@analytics/CLIENTS_FAILURE',
  CLIENTS_SUCCESS = '@@analytics/CLIENTS_SUCCESS',

  MASTERS_REQUEST = '@@analytics/MASTERS_REQUEST',
  MASTERS_FAILURE = '@@analytics/MASTERS_FAILURE',
  MASTERS_SUCCESS = '@@analytics/MASTERS_SUCCESS',

  SERVICES_REQUEST = '@@analytics/SERVICES_REQUEST',
  SERVICES_FAILURE = '@@analytics/SERVICES_FAILURE',
  SERVICES_SUCCESS = '@@analytics/SERVICES_SUCCESS',

  EXPORT_DATA = '@@analytics/EXPORT_DATA',

  CLEAN = '@@analytics/CLEAN',
}
