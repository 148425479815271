import React from 'react';
import { Col, DatePicker, Row, Typography } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
  onDataChange: (dates: any) => void;
};
const RangePickerAnalytics: React.FC<Props> = ({ onDataChange }) => {
  const { t } = useTranslation();

  return (
    <StyledRow className={'mobile-left-padding'} gutter={16}>
      <Col span={24} style={{ marginBottom: 8 }}>
        <Typography.Text>{t('Statistics period')}</Typography.Text>
      </Col>

      <StyledRange
        defaultValue={[moment().startOf('month'), moment(new Date())] as any}
        ranges={{
          [t('This month')]: [moment().startOf('month'), moment().endOf('month')],
        } as any}
        onChange={onDataChange}
      />
    </StyledRow>
  );
};

export default RangePickerAnalytics;

const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;
const StyledRange = styled(DatePicker.RangePicker)`
  margin-left: 8px;
`;
