import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { IService } from '../../models/IService';
import { Button, Col, Input, Row, Table, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { minTableCellWidth, tableXScroll } from '../../const';
import NewService from './NewService';
import { deleteServiceRequest, getServicesRequest } from '../../store/service/actions';
import moment from 'moment';
import { AppContext } from '../../context/app-context';
import { createBreadcrumb } from '../../store/app/utils';
import { setBreadcrumbs } from '../../store/app/actions';
import { hasAdministratorPermissions } from '../../services/auth-service';
import confirm from 'antd/lib/modal/confirm';

type Props = {};
const Services: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [service, setService] = useState<IService | null>(null);
  const { mainContainerHeight } = useContext(AppContext);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
  });

  const { services, currency, locationId, loading, total, authenticatedUser } = useSelector((state: IApplicationState) => ({
    services: state.service.services,
    authenticatedUser: state.auth.user,
    total: state.service.total,
    loading: state.service.loading,
    currency: state.location.currentLocation?.currency as string,
    locationId: state.location.currentLocation?._id,
  }));

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Services'))]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    setPagination({ ...pagination, total });
    // eslint-disable-next-line
  }, [loading, total]);

  useEffect(() => {
    if (locationId) dispatch(getServicesRequest(locationId as string));
    // eslint-disable-next-line
  }, [locationId]);

  const handleServiceEdit = (service: IService) => setService(service);

  const handleServiceDelete = (service: IService) => {
    confirm({
      content: t('Are you sure that you want to delete this service?'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteServiceRequest(service._id, locationId!));
      },
    });
  };

  const columns: any[] = COLUMNS({ t, currency });

  if (authenticatedUser && hasAdministratorPermissions(authenticatedUser)) {
    columns.unshift({
      title: t('Operations'),
      width: minTableCellWidth,
      key: 'operation',
      render: (service: IService) => (
        <>
          <Tooltip placement="top" title={t('Edit')}>
            <Button
              className="mr-1"
              icon={<EditOutlined />}
              shape="circle"
              type="primary"
              onClick={() => handleServiceEdit(service)}
            />
          </Tooltip>
          <Tooltip placement="top" title={t('Delete')}>
            <Button
              danger
              icon={<DeleteOutlined />}
              shape="circle"
              onClick={() => handleServiceDelete(service)}
            />
          </Tooltip>
        </>
      ),
    });
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setPagination(pagination);
    dispatch(
      getServicesRequest(locationId as string, {
        offset: pagination.pageSize * (pagination.current - 1),
        limit: pagination.pageSize,
      })
    );
  };

  const onSearch = (search: string) => {
    // {key: 'name', value: search}
    dispatch(
      getServicesRequest(
        locationId as string,
        {
          offset: 0,
          limit: pagination.pageSize,
        },
        { key: 'name', value: search }
      )
    );
  };

  return (
    <>
      <Row>
        <Col span={window.innerWidth <= 800 ? 24 : 7} style={{ marginBottom: window.innerWidth <= 800 ? '16px' : 0 }}>
          <NewService service={service} onCloseHandler={() => setService(null)} />
        </Col>
        <Col span={window.innerWidth <= 800 ? 24 : 16} offset={window.innerWidth <= 800 ? 0 : 1}>
          <Input.Search
            className={'phone-button-margin-full'}
            loading={loading}
            placeholder={t('Enter key word')}
            style={{ width: '100%' }}
            onSearch={onSearch}
          />
        </Col>
        <Col span={24} style={{ marginTop: 16 }}>
          <Table
            scroll={{ x: tableXScroll, y: mainContainerHeight }}
            size="small"
            loading={loading}
            onChange={handleTableChange}
            rowKey={(item: IService) => item._id}
            pagination={pagination}
            columns={columns}
            dataSource={services}
          />
        </Col>
      </Row>
    </>
  );
};

export default Services;

const COLUMNS = ({ t, currency }: any) => [
  {
    title: t('[Thing] Name'),
    width: minTableCellWidth,
    dataIndex: 'name',
  },
  {
    title: t('Price'),
    width: minTableCellWidth,
    // @ts-ignore
    render: (price: string) => `${price} ${currency}`,
    dataIndex: 'price',
  },
  {
    title: t('Duration'),
    width: minTableCellWidth + 20,
    render: (duration: number) => {
      return moment.utc(moment.duration(duration, 'minutes').asMilliseconds()).format('HH:mm');
    },
    dataIndex: 'duration',
  },
  // {
  //   title: t('Pause after'),
  //   width: minTableCellWidth,
  //   render: (pauseAfter: number) => {
  //     return moment.utc(moment.duration(pauseAfter, 'minutes').asMilliseconds()).format('HH:mm');
  //   },
  //   dataIndex: 'pauseAfter',
  // },
  // {
  //   title: t('Maximum number'),
  //   width: minTableCellWidth,
  //   dataIndex: 'maxPerson',
  // },
  // {
  //   title: t('Cancel if the group is not collected'),
  //   width: minTableCellWidth,
  //   dataIndex: 'cancelIfNoRequiredClients',
  //   render: (cancelIfNoRequiredClients: any) => {
  //     if (cancelIfNoRequiredClients === undefined) return '-';

  //     return (
  //       <Tag color={!cancelIfNoRequiredClients ? 'volcano' : 'green'}>
  //         {t(cancelIfNoRequiredClients ? 'Yes' : 'No')}
  //       </Tag>
  //     );
  //   },
  // },
  // {
  //   title: t('When to check the reservation'),
  //   width: minTableCellWidth,
  //   dataIndex: 'checkBeforeInitIfGroupIsPresentTime',
  // },
  {
    title: t('Description'),
    width: minTableCellWidth,
    dataIndex: 'description',
  },
  {
    title: t('Category'),
    width: minTableCellWidth,
    dataIndex: 'category',
  },
  {
    title: t('Active'),
    width: minTableCellWidth,
    render: (active: boolean) => <Tag color={active ? 'green' : 'volcano'}>{t(active ? 'Yes' : 'No')}</Tag>,
    dataIndex: 'active',
    filters: [
      {
        text: t('Yes'),
        value: true,
      },
      {
        text: t('No'),
        value: false,
      },
    ],
  },
];