import { Reducer } from 'redux';
import { LogActionTypes, LogsState } from './types';

export const initialState: LogsState = {
  logs: [],
  total: 0,
  loading: false,
};

const reducer: Reducer<LogsState> = (state = initialState, action) => {
  switch (action.type) {
    case LogActionTypes.LOG_REQUEST: {
      return { ...state, loading: true };
    }
    case LogActionTypes.ADD_LOG: {
      return {
        ...state,
        logs: [action.payload, ...state.logs],
        total: state.total + 1,
      };
    }
    case LogActionTypes.LOG_SUCCESS: {
      return {
        ...state,
        loading: false,
        logs: [...state.logs, ...action.payload.logs],
        total: action.payload.total,
      };
    }
    case LogActionTypes.CLEAR_LOGS: {
      return {
        logs: [],
        total: 0,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as LogsReducer };
