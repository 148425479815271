import React, { useState } from 'react';
import { Alert } from 'antd';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { IAuthCredential } from '../../models/IAuthCredential';
import { Button, Card, Form, Input, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserService from '../../services/user-service';

const userAuthService = new UserService();

const ForgotPassword = (): JSX.Element => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState(false);

  const loading: boolean = useSelector((state: IApplicationState) => state.auth.loading);

  const forgotPasswordHandler = async (credential: IAuthCredential) => {
    try {
      await userAuthService.forgotPasswordRequest(credential.email || '');
      setAlert(true);
    } catch (error) {
      console.log('error while requesting forgot password api', (error as any).mesage);
    }
  };

  const onFinish = (values: any) => {
    forgotPasswordHandler(values);
  };

  return (
    <>
      <div
        style={{
          height: '70vh',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%',
        }}
      >
        <div style={{ maxWidth: '100%' }}>
          {alert && (
            <div style={{ maxWidth: 500, margin: '0 auto' }}>
              <Alert message={t(`Check your inbox for the next steps. If you don't receive an email, and it's not in your spam folder this could mean you signed up with a different address.`)} type="info" showIcon />
            </div>
          )}
          <br />
          <Card style={{ maxWidth: 500, margin: '0 auto' }}>
            <Spin spinning={loading}>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  label={t('E-mail')}
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: t('Empty field or wrong data'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('Enter your email')}
                    autoComplete="off"
                  />
                </Form.Item>

                <Form.Item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    {t('Submit')}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Link to={'/auth?login'}>{t('Back to log in')}</Link>
                </Form.Item>
              </Form>
            </Spin>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
