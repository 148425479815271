import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Col, Descriptions, PageHeader, Row, Skeleton, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import AddNewClient from './AddNewClient';
import { getCurrentUserRequest } from '../../store/user/actions';
import { IApplicationState } from '../../models/IApplicationState';
import { minTableCellWidth, tableXScroll } from '../../const';
import { IMaster } from '../../models/IUser';
import { AppContext } from '../../context/app-context';
import moment from 'moment';
import { IReservation } from '../../models/IReservation';
import { IService } from '../../models/IService';
import { setBreadcrumbs } from '../../store/app/actions';
import { createBreadcrumb } from '../../store/app/utils';
import { getTimeFormat } from '../../services/utils';

type Props = {};
const Client: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { mainContainerHeight } = useContext(AppContext);

  const userId = search.replace('?userId=', '');

  const { loading, user, currency, is12H } = useSelector((state: IApplicationState) => ({
    loading: state.user.loading,
    user: state.user.currentUser,
    currency: state.location.currentLocation?.currency,
    is12H: state.location.currentLocation?.is12H,
  }));

  const timeFormat = useMemo(() => getTimeFormat(is12H), [is12H]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
  });

  const { t } = useTranslation();
  // eslint-disable-next-line
  const [state, setState] = useState({
    user: null,
  });

  useEffect(() => {
    dispatch(setBreadcrumbs([
      createBreadcrumb(t('Clients'), '/clients'),
      createBreadcrumb(user?.name!),
    ]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [user, dispatch, t]);

  useEffect(() => {
    if (userId) {
      dispatch(getCurrentUserRequest(userId));
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (user) {
      setPagination({ ...pagination, total: user.reservations.length });
    }
    // eslint-disable-next-line
  }, [user]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setPagination(pagination);
  };

  const columns: any = COLUMNS({ currency, t, timeFormat }).map((column: any) => ({ ...column, title: t(column.title) }));

  return (
    <Wrapper>
      {loading && user === null ? (
        <Skeleton />
      ) : (
        <React.Fragment>
          <PageHeader ghost={false} onBack={() => window.history.back()} title={user && user.name ? user.name : ''}>
            <AddNewClient noButton={true} key={1} user={state.user} onCloseHandler={() => setState({ user: null })} />

            <Descriptions size="small" column={2}>
              <Descriptions.Item label={t('E-mail')}>{user?.email}</Descriptions.Item>
              <Descriptions.Item label={t('Phone')}>
                {user && user.countryCode ? `+${user.countryCode}` : ''}
                {user?.phone}
              </Descriptions.Item>
              <Descriptions.Item label={t('Ban')}>
                <Tag color={user?.ban ? 'volcano' : 'green'}>{user?.ban ? t('Yes') : t('No')}</Tag>
              </Descriptions.Item>
              <Descriptions.Item label={t('Subscription')}>
                <Tag color={!user?.subscription ? 'volcano' : 'green'}>{user?.subscription ? t('Yes') : t('No')}</Tag>
              </Descriptions.Item>
              <Descriptions.Item label={t('Total reservations')}>{user?.reservations.length}</Descriptions.Item>
            </Descriptions>
          </PageHeader>

          <Row>
            <Col span={24} style={{ marginTop: 16 }}>
              <Table
                scroll={{ x: tableXScroll, y: mainContainerHeight }}
                loading={loading}
                size="small"
                onChange={handleTableChange}
                rowKey={(item: IReservation) => item._id}
                pagination={pagination}
                columns={columns}
                dataSource={user?.reservations ? (user.reservations as IReservation[]) : []}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default Client;

const COLUMNS = ({ currency, t, timeFormat }: any) => [
  {
    title: t('Status'),
    width: minTableCellWidth,
    dataIndex: 'status',
    render: (status: string) => t(status),
  },
  {
    title: t('Master'),
    width: minTableCellWidth,
    dataIndex: 'master',
    render: (master: IMaster) => master?.name,
  },
  {
    title: t('Service'),
    width: minTableCellWidth,
    dataIndex: 'service',
    render: (service: IService) => service.name,
  },
  {
    title: t('Date of reservation'),
    width: minTableCellWidth,
    dataIndex: 'reservedAt',
    render: (reservedAt: string) => moment(reservedAt).format(`DD MMMM [${t('at')}] ${timeFormat}`),
  },
  {
    title: t('Price'),
    width: minTableCellWidth,
    render: (reservation: IReservation) => {
      const servicePrice = (reservation.service as IService).price;

      const percentDiscounts = reservation.discounts.filter((discount: any) => discount.typeOfAmount === '%');
      const moneyDiscounts = reservation.discounts.filter((discount: any) => discount.typeOfAmount !== '%');

      let finalPrice;

      if (servicePrice.includes('-')) {
        const prices = servicePrice.split('-');
        prices.forEach((p: string) => {
          let price = Number(p);
          moneyDiscounts.forEach((discount: any) => (price = price - discount.amount));
          percentDiscounts.forEach((discount: any) => (price = price - (price / 100) * discount.amount));
        });
        finalPrice = prices.join('-');
      } else {
        let price = Number(servicePrice);

        moneyDiscounts.forEach((discount: any) => (price = price - discount.amount));
        percentDiscounts.forEach((discount: any) => (price = price - (price / 100) * discount.amount));

        finalPrice = price;
      }

      return `${finalPrice} ${currency}`;
    },
  },
];

const Wrapper = styled.div`
  width: fit-content;
  margin: var(--large);
  display: flex;
  flex-direction: column;
  width: auto;
  padding: var(--small);
`;
