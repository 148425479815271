import { Button, Col, Divider, Form, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Loader from '../../../components/sharedComponents/Loader';
import { WidgetType } from '../../../models/ILocation';
import { getLocationWidget, updateLocationWidget } from '../../../services/locations.service';
import { copyToClipboard, getWidgetLinkOrCode } from '../../../services/utils';
import { setBreadcrumbs } from '../../../store/app/actions';
import { createBreadcrumb } from '../../../store/app/utils';
import { BUTTON_STYLES } from '../constants';
import { ButtonSettingsStepForm } from '../NewWidget/ButtonSettingsStepForm/ButtonSettingsStepForm';
import { GeneralSettingsStepForm } from '../NewWidget/GeneralSettingsStepForm/GeneralSettingsStepForm';
import { ResultStepForm } from '../NewWidget/ResultStepForm/ResultStepForm';
import { WidgetSettingsStepForm } from '../NewWidget/WidgetSettingsStepForm/WidgetSettingsStepForm';
import './EditWidget.css';

export const EditWidget: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { widgetId } = useParams<{ widgetId: string }>();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({
    about: '',
    buttonColors: BUTTON_STYLES[0].defaultColors,
    buttonStyle: BUTTON_STYLES[0].name,
    buttonText: t('Book online'),
    locations: [],
    name: '',
    style: null,
    type: null,
  });

  const linkOrCode = useMemo(() => model.type && getWidgetLinkOrCode(model.type, widgetId), [model.type, widgetId]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      createBreadcrumb(t('Booking widgets'), '/widgets'),
      createBreadcrumb(t('Edit widget')),
    ]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    setLoading(true);
    getLocationWidget(widgetId)
      .then(response => {
        const { data } = response;
        const values = {
          about: data.about,
          buttonColors: data.buttonColors,
          buttonStyle: data.buttonStyle,
          buttonText: data.buttonText,
          locations: data.locations,
          name: data.name,
          style: data.style,
          type: data.type,
        };
        form.setFieldsValue(values);
        setModel(values);
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }, [widgetId]);

  const handleSubmit = () => {
    setLoading(true);
    updateLocationWidget(widgetId, model)
      .then(() => history.push('/widgets'))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  };

  const handleModelChange = (state: any) => setModel(state);
  const handleFormChange = (values: any) => form.setFieldsValue(values);

  const handleCopy = () => {
    if (linkOrCode) {
      copyToClipboard(linkOrCode);
      message.success(`${t('Copied')}`);
    }
  };

  return loading ? (
    <>
      <Loader />
      <Form form={form} />
    </>
  ) : (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <GeneralSettingsStepForm
        model={model}
        onChange={handleModelChange}
        onFormChange={handleFormChange}
      />
      {model.type === WidgetType.Website && (
        <>
          <Divider orientation="left" orientationMargin={0}>{t('Button settings')}</Divider>
          <ButtonSettingsStepForm
            model={model}
            onChange={handleModelChange}
          />
        </>
      )}
      <Divider orientation="left" orientationMargin={0}>{t('Widget settings')}</Divider>
      <WidgetSettingsStepForm onChange={handleModelChange} />
      {model.type && linkOrCode && (
        <>
          <Divider orientation="left" orientationMargin={0}>{model.type === WidgetType.Website ? t('Widget code') : t('Widget link')}</Divider>
          <ResultStepForm
            linkOrCode={linkOrCode}
            widgetType={model.type}
          />
        </>
      )}
      <Col span={24} className="text-right">
        <Button className="mr-2" disabled={!linkOrCode} type="primary" onClick={handleCopy}>{t('Copy')}</Button>
        <Button type="primary" htmlType="submit">{t('Save')}</Button>
      </Col>
    </Form>
  );
};
