import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RouteComponentProps } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import { IMaster } from '../../../models/IUser';
import { env } from '../../../const/env';
import { PublicLayout } from '../../../layouts/PublicLayout';
import { Forbidden } from '../../sharedComponents/Forbidden';
import Loader from '../../sharedComponents/Loader';
import { ProtectedRouteProps } from '../ProtectedRoute';

export const RouteRenderComponent = ({ props, routeProps, loading, user }: RouteRenderComponentProps) => {
  const { pathname } = useLocation();

  if (props.redirect && (pathname === props.path || pathname === `${props.path}/`)) {
    return <Redirect to={props.redirect} />;
  }

  if (loading && user === null) {
    return <Loader />;
  }

  const Layout = props.Layout || PublicLayout;
  const Component = props.Component || (() => <></>);

  if (props.captcha) {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={env.captchaKey}>
        <Layout>
          <Component {...routeProps} />
        </Layout>
      </GoogleReCaptchaProvider>
    );
  }

  if (user) {
    const hasPermission = props.public || !props.roles || props.roles.includes(user.role);
    if (hasPermission) {
      return (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      );
    } else {
      return (
        <Layout>
          <Forbidden />
        </Layout>
      );
    }
  } else if (props.public) {
    return (
      <Layout>
        <Component {...routeProps} />
      </Layout>
    );
  } else {
    return <Redirect to="/auth?login" />;
  }
}

interface RouteRenderComponentProps {
  loading: boolean;
  props: ProtectedRouteProps;
  routeProps: RouteComponentProps;
  user: IMaster | null;
};
