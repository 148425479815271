import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Form, FormInstance, Input, notification, Row, Select } from 'antd';
import { weekDays } from '../../const';
import { formWithWorkingDaysToCleanObject, workingHoursToFormData, compactObject } from '../../services/utils';
import { IWorkingHours } from '../../models/IWorkingHours';
import { CreateLocationContext } from './create-location-context';
import { IEmail } from '../../models/ILocation';
import currencies from '../../const/currencies';
import { capitalize } from 'lodash';
import { TimeSlots } from '../../components/TimeSlots/TimeSlots';

export interface ISecondStep {
  countryCode: string;
  currency: string;
  emails: IEmail[];
  phone: string;
  first_day_of_week: string;
  workingHours: IWorkingHours;
}

const Wrapper = styled.div``;

type Props = {
  form: FormInstance;
  next: () => void;
  prev: () => void;
};
const SecondStep: React.FC<Props> = ({ form, prev, next }) => {
  const { t } = useTranslation();
  const [currenciesArr, setCurrenciesArr] = useState(currencies);

  const { secondStep, setContext } = useContext(CreateLocationContext);

  useEffect(() => {
    if (secondStep) {
      form.setFieldsValue({
        ...workingHoursToFormData(secondStep.workingHours || {}),
        countryCode: secondStep.countryCode,
        currency: secondStep.currency,
        first_day_of_week: secondStep.first_day_of_week,
      });
    }
    // eslint-disable-next-line
  }, [secondStep]);

  const submitForm = () => {
    const cleanData = {};
    const data = { secondStep: cleanData as ISecondStep };
    const _values: any = compactObject({ ...form.getFieldsValue() });
    const _formatedWorkingHours: any = {};
    for (const day of weekDays) {
      if (_values[`${day}-from-start`] && _values[`${day}-from-end`]) {
        const _fromStart = _values[`${day}-from-start`];
        const _fromEnd = _values[`${day}-from-end`];
        _formatedWorkingHours[`${day}-from`] = [_fromStart, _fromEnd];
      }
      if (_values[`${day}-to-start`] && _values[`${day}-to-end`]) {
        const toStart = _values[`${day}-to-start`];
        const toEnd = _values[`${day}-to-end`];
        _formatedWorkingHours[`${day}-to`] = [toStart, toEnd];
      }
    }
    const format_first_day_of_week = _values.first_day_of_week ? { first_day_of_week: _values.first_day_of_week } : {};
    formWithWorkingDaysToCleanObject(cleanData, {
      currency: _values.currency,
      ...format_first_day_of_week,
      ..._formatedWorkingHours,
    });
    if (data.secondStep.workingHours) {
      setContext(data);
      next();
    } else {
      notification.error({
        message: t('Specify working hours'),
        description: t('Empty field or wrong data'),
      });
    }
  };

  const onSearchCurrencies = (ev: any) => {
    if (ev === null || ev === '') {
      setCurrenciesArr(currencies);
    } else {
      setCurrenciesArr(currencies.filter((c) => c.name.toLowerCase().includes(ev.toLowerCase())));
    }
  };

  return (
    <Wrapper>
      <Form form={form} style={{ width: '-webkit-fill-available' }} layout="vertical" onFinish={submitForm}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="currency" rules={[{ required: true, message: t('Required field') }]} label={t('Currency')}>
              <Select
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        padding: 8,
                      }}
                    >
                      <Input
                        className="no-arrows-input"
                        placeholder={t('Start entering a currency')}
                        style={{ flex: 'auto' }}
                        onChange={(e) => onSearchCurrencies(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                placeholder={t('Select a currency')}
                style={{ alignItems: 'center' }}
              >
                {currenciesArr.map((currency, index) => (
                  <Select.Option key={index} value={currency.symbol}>
                    {currency.name} ( {currency.symbol} )
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="first_day_of_week"
              rules={[{ required: true, message: t('Required field') }]}
              label={t('First Day of Week')}
            >
              <Select placeholder={t('Select a day')} style={{ width: '100%' }}>
                {weekDays.map((day, index) => (
                  <Select.Option key={index} value={day}>
                    {t(capitalize(day))}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <p><b>{t('Working hours')}</b></p>
        <TimeSlots form={form} />

        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button style={{ margin: '0 8px' }} onClick={prev}>
            {t('Back')}
          </Button>

          <Button type="primary" htmlType="submit">
            {t('Next')}
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

export default SecondStep;
