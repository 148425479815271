import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { loginRequest } from '../../store/auth/actions';
import { IAuthCredential } from '../../models/IAuthCredential';
import { Button, Card, Form, Input, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {};

const LoginForm: React.FC<Props> = React.memo(() => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const loading: boolean = useSelector((state: IApplicationState) => state.auth.loading);

  const loginHandler = (credential: IAuthCredential) => dispatch(loginRequest(credential));

  const onFinish = (values: any) => {
    loginHandler(values);
  };

  return (
    <Card style={{ maxWidth: 500, margin: '0 auto' }}>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="email"
            label={t('E-mail')}
            rules={[
              {
                required: true,
                type: 'email',
                message: t('Empty field or wrong data'),
              },
            ]}
          >
            <Input placeholder={t('Enter your email')} />
          </Form.Item>

          <Form.Item
            label={t('Password')}
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              autoComplete="off"
              placeholder={t('Enter your password')}
            />
          </Form.Item>

          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button type="primary" htmlType="submit">
              {t('Sign in')}
            </Button>
            <Button style={{ margin: '0 8px' }} type="dashed">
              <Link to={'/auth?sign-up'}>{t('Register')}</Link>
            </Button>
          </Form.Item>
          <Form.Item>
            <Link to={'/auth?forgot-password'}>{t('Forgot password')}?</Link>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
});

export default LoginForm;
