import { IUser } from '../../models/IUser';
import { IReservation } from '../../models/IReservation';

export interface UserState {
  readonly loading: boolean;
  readonly needConfirm: boolean;
  readonly currentUser: IUser | null;
  readonly users: IUser[];
  readonly reservations: IReservation[];
  readonly total: number;
  readonly error: string | null;
}

export enum UserActionTypes {
  LOGIN_USER_REQUEST = '@@user/LOGIN_USER_REQUEST',
  LOGIN_USER_SUCCESS = '@@user/LOGIN_USER_SUCCESS',

  ON_LOAD_USER_SUCCESS = '@@user/ON_LOAD_USER_SUCCESS',
  ON_LOAD_USER_REQUEST = '@@user/ON_LOAD_USER_REQUEST',

  CONFIRM_USER_SUCCESS = '@@user/CONFIRM_USER_SUCCESS',
  CONFIRM_USER_REQUEST = '@@user/CONFIRM_USER_REQUEST',

  USER_RESERVATION_REQUEST = '@@user/USER_RESERVATION_REQUEST',
  USER_RESERVATION_SUCCESS = '@@user/USER_RESERVATION_SUCCESS',

  CLEAN_USER_RESERVATION_SUCCESS = '@@user/CLEAN_USER_RESERVATION_SUCCESS',

  NEW_PASSWORD_REQUEST = '@@user/CLEAN_USER_RESERVATION_SUCCESS',

  USER_LOGOUT = '@@user/USER_LOGOUT',

  SET_CURRENT_USER_SUCCESS = '@@user/SET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_REQUEST = '@@user/GET_CURRENT_USER_REQUEST',

  CREATE_USER_REQUEST = '@@user/CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = '@@user/CREATE_USER_SUCCESS',

  SEARCH_USERS_REQUEST = '@@user/SEARCH_USERS_REQUEST',
  SEARCH_USERS_SUCCESS = '@@user/SEARCH_USERS_SUCCESS',

  UPDATE_USER_REQUEST = '@@user/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = '@@user/UPDATE_USER_SUCCESS',

  DELETE_USER_REQUEST = '@@user/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = '@@user/DELETE_USER_SUCCESS',

  USER_REQUEST_FAILURE = '@@user/USER_REQUEST_FAILURE',

  CLEAN_ERRORS = '@@user/CLEAN_ERRORS',
}
