import axiosInstance from './axios-instance';

export const getServiceCategories = () => axiosInstance.get('/service/categories');

export const createServiceCategory = (payload: any) => axiosInstance.post('/service/categories', payload);

export const updateServiceCategory = (categoryId: string, payload: any) =>
  axiosInstance.put(`/service/categories/${categoryId}`, payload);

export const deleteServiceCategory = (categoryId: string) => axiosInstance.delete(`/service/categories/${categoryId}`);
