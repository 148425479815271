import { takeEvery, put, select } from 'redux-saga/effects';
import LogsService from '../../services/logs-service';
import { LogActionTypes } from './types';
import { getLogsSuccess } from './actions';
import { IApplicationState } from '../../models/IApplicationState';

const logsService = new LogsService();

function* getLogsWorker(): Generator<any, any, any> {
  try {
    const { locationId, offset } = yield select((state: IApplicationState) => ({
      locationId: state.location.currentLocation?._id,
      offset: state.log.logs.length,
    }));

    const response = yield logsService.getLogs(locationId, offset);

    yield put(getLogsSuccess(response.data.logs, response.data.total));
  } catch (e) {
    console.log(e);
  }
}

export function* watchLogs() {
  yield takeEvery(LogActionTypes.LOG_REQUEST, getLogsWorker);
}

const logsWatchers: any = [watchLogs()];

export default logsWatchers;
