import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement, StripeCardNumberElementOptions } from '@stripe/stripe-js';
import { Button, Col, Form, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CheckoutFormDialog.css';

const ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
  showIcon: true,
  style: {
    base: {
      color: '#424770',
      lineHeight: '24px',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export const CheckoutFormDialog = (props: CheckoutFormDialogProps) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (elements == null) {
      return;
    }
    setLoading(true);
    try {
      const { paymentMethod, error } = await stripe!.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
      });
      if (error) {
        console.error(error);
      } else {
        props.onPaymentMethodCreated(paymentMethod.id);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Modal
      title={t('Add a payment card')}
      visible={props.visible}
      onCancel={props.onClose}
      cancelText={t('Cancel')}
      footer={null}
    >
      <Form layout="vertical" className="checkout-form" onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="cardNumber" label="Card Number">
              <CardNumberElement options={ELEMENT_OPTIONS} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="expiry" label="Card Expiration">
              <CardExpiryElement options={ELEMENT_OPTIONS} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="cvc" label="CVC">
              <CardCvcElement options={ELEMENT_OPTIONS} />
            </Form.Item>
          </Col>
          <Col span={24} className="text-right mt-3">
            <Button
              disabled={!stripe || !elements}
              htmlType="submit"
              loading={loading}
              type="primary"
            >
              {t('Pay')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

interface CheckoutFormDialogProps {
  visible: boolean;
  onClose: () => void;
  onPaymentMethodCreated: (paymentMethodId: string) => void;
}
