import RequestService from './request-service';
import { AxiosResponse } from 'axios';
import { IQuery } from '../models/IQuery';

export default class AnalyticsService extends RequestService {
  public clients = (locationId: string, pagination: IQuery, queryData: any): Promise<AxiosResponse> =>
    this.instance({
      method: 'get',
      url: 'analytics/clients',
      params: { locationId, ...pagination, queryData },
    });

  public masters = (statisticsData: any): Promise<AxiosResponse> =>
    this.instance.get('analytics/masters', { params: { ...statisticsData } });

  public services = (statisticsData: any): Promise<AxiosResponse> =>
    this.instance.get('analytics/services', { params: { ...statisticsData } });

  public exportData = (params: any): Promise<AxiosResponse> =>
    this.instance.get('analytics/export', { params: { ...params } });
}
