import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { useTranslation } from 'react-i18next';

const currencySigns: Record<string, string> = {
  usd: '$',
};

export const PricingCard = (props: PricingCardProps): JSX.Element => {
  const { t } = useTranslation();
  const { subscription } = useSelector((state: IApplicationState) => ({
    user: state.auth.user,
    locationId: state.location.currentLocation?._id as string,
    subscription: state.subscriptions.subscription!,
  }));

  const isCurrentPlan = useMemo(() => !!subscription && subscription.status !== 'canceled' &&
    props.plan.name === subscription.planName && props.plan.interval === subscription.planInterval,
    [props.plan.name, props.plan.interval, subscription]);

  const handleContinue = (priceId: string) => {
    if (!isCurrentPlan) {
      props.onPay(priceId);
    }
  };

  return (
    <>
      <li className={`${props.plan.bestSeller && 'exclusive'}`}>
        <ul className="pricing-wrapper">
          <li data-type="yearly" className="is-visible">
            <div className="pricing-header">
              {props.plan.bestSeller && (
                <div className="best">
                  <span>Bestseller</span>
                </div>
              )}
              <h2>{props.plan.name}</h2>
              <div className="price">
                <span className="value">
                  {currencySigns[props.plan.currency]}
                  {props.plan.amount}
                </span>
                <span className="duration">{props.plan.interval}</span>
              </div>
            </div>
            <div className="pricing-body">
              <ul className="pricing-features">
                <li>
                  <em>{props.plan.staffMembers}</em> {t('Staff members')}
                </li>
                <li>
                  <em>{props.plan.services}</em> {t('N of Services')}
                </li>
                <li>
                  <em>{t('Full (Skeedee functionality)')}</em> {t('Skeedee functionality')}
                </li>
                <li>
                  <em>24/7</em> {t('Support')}
                </li>
              </ul>
            </div>
            <footer className="pricing-footer">
              <Button
                disabled={isCurrentPlan}
                className="select paddle_button"
                type="ghost"
                onClick={() => handleContinue(props.plan.priceId)}
              >
                {isCurrentPlan ? t('Current plan') : subscription ? t('Continue') : t('Try for free')}
              </Button>
              {!subscription && (
                <div className="free">
                  {props.plan.trialDays} {t('days trial')}
                </div>
              )}
            </footer>
          </li>
        </ul>
      </li>
    </>
  );
};

interface PricingCardProps {
  plan: PricingPlan;
  onPay: (priceId: string) => any;
}

export interface PricingPlan {
  priceId: string;
  name: string;
  interval: string;
  currency: string;
  amount: number;
  trialDays: number;
  staffMembers: number;
  services: number;
  bestSeller: boolean;
}
