import React, { useEffect } from 'react';
import { Button, Result, Spin, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { useTranslation } from 'react-i18next';
import { MainSettings } from './MainSettings';
import ClosureSettings from './ClosureSettings';
import ContactInformation from './ContactInformation';
import LocationService from '../../services/location-service';
import { selectLocationRequest } from '../../store/location/actions';
import { getSubscriptionRequest } from '../../store/subscription/actions';
import { createBreadcrumb } from '../../store/app/utils';
import { setBreadcrumbs } from '../../store/app/actions';

type Props = {};

const locationService = new LocationService();
const DeleteBranch = (): JSX.Element => {
  const { locationId, locations } = useSelector((state: IApplicationState) => ({
    locationId: state.location.currentLocation?._id,
    locations: state.location.locations,
  }));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDelete = async () => {
    if (!locationId) {
      return;
    }
    try {
      await locationService.deleteLocation(locationId);
      const locationToSwitch = locations.find((item) => item._id !== locationId);
      if (locationToSwitch) {
        dispatch(selectLocationRequest(locationToSwitch._id));
        dispatch(getSubscriptionRequest(locationToSwitch?._id));
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Result
        status="warning"
        title={t('Are you sure? This operation cannot be undone.')}
        extra={
          <Button type="primary" key="console" danger onClick={handleDelete}>
            {t('Proceed')}
          </Button>
        }
      />
    </>
  );
};

const Settings: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, locations } = useSelector((state: IApplicationState) => ({
    loading: state.location.loading,
    locations: state.location.locations,
  }));

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Branch settings'), '/settings')]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  return (
    <Spin spinning={loading} tip={t('Loading')}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('Settings')} key="1">
          <MainSettings />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Contact information')} key="2">
          <ContactInformation />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Temporary closure')} key="3">
          <ClosureSettings />
        </Tabs.TabPane>
        {locations.length > 1 && (
          <Tabs.TabPane tab={t('Delete the Branch')} key="5">
            <DeleteBranch />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Spin>
  );
};

export default Settings;
