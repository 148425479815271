import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IApplicationState } from '../../models/IApplicationState';
import { ILocation } from '../../models/ILocation';
import { hasAdministratorPermissions } from '../../services/auth-service';
import { selectLocationRequest } from '../../store/location/actions';
import { setUpDate } from '../../store/reservation/actions';
import { getSubscriptionRequest } from '../../store/subscription/actions';
import { AppSidebarCalendar } from '../AppSidebar/Calendar/AppSidebarCalendar';
import './TopMenu.css';

export const TopMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { currentLocation, hasUserAdministratorPermissions, locations } = useSelector((state: IApplicationState) => ({
    currentLocation: state.location.currentLocation,
    hasUserAdministratorPermissions: state.auth.user && hasAdministratorPermissions(state.auth.user),
    locations: state.location.locations as ILocation[],
  }));

  const handleLocationSelect = (location: ILocation) => {
    dispatch(selectLocationRequest(location._id));
    dispatch(getSubscriptionRequest(location._id));
  };

  const handleDatePick = (date: moment.Moment) => {
    const pathname = `/calendar?date=${date.toISOString()}`;
    if (location.pathname !== pathname) {
      history.push(pathname);
    }
    dispatch(setUpDate(date.toISOString()));
  };

  return (
    <div>
      {hasUserAdministratorPermissions ? (
        <Dropdown
          overlay={
            <Menu items={[
              ...locations.map(location => ({
                key: location._id,
                label: location.name,
                onClick: () => handleLocationSelect(location),
              })),
              { type: 'divider' },
              {
                key: 'create-location',
                label: <Link to="/create-location">{t('Add a new branch')}</Link>
              },
            ]} />
          }
          trigger={['click']}
        >
          <Button className="select-shop-button">
            {currentLocation?.name} <DownOutlined />
          </Button>
        </Dropdown>
      ) : (
        <Button className="select-shop-button">{currentLocation?.name}</Button>
      )}
      <div style={{ background: '#f0f2f5', marginBottom: '10px' }}>
        <AppSidebarCalendar onDatePick={handleDatePick} />
      </div>
    </div>
  );
};
