import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMasterInput } from '../../models/IUser';
import { signUpRequest } from '../../store/auth/actions';
import { Button, Card, Checkbox, Form, Input, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IApplicationState } from '../../models/IApplicationState';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CountriesFormItem } from '../../components/CountriesFormItem/CountriesFormItem';

type Props = {};

const SignUpForm: React.FC<Props> = React.memo(() => {
  const dispatch = useDispatch();

  const { executeRecaptcha }: any = useGoogleReCaptcha();

  const loading: boolean = useSelector((state: IApplicationState) => state.auth.loading);

  const { t } = useTranslation();

  const signUpHandler = (credential: IMasterInput, captcha: string) => dispatch(signUpRequest(credential, captcha));

  const onFinish = async (values: any) => {
    const reCaptcha = await executeRecaptcha('signUp_page');
    delete values.language;
    signUpHandler({ ...values, workingHours: {} }, reCaptcha);
  };

  return (
    <Card title={t('Personal information')} style={{ maxWidth: 500, margin: '0 auto' }}>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item name="name" label={t('Full name')} rules={[{ required: true, message: t('Required field') }]}>
            <Input placeholder={t('Enter your name')} autoComplete="off" />
          </Form.Item>

          <Form.Item name="role" label={t('Role')} rules={[{ required: true, message: t('Required field') }]}>
            <Select placeholder={t('Select a role')}>
              <Select.Option value="admin">{t('Administrator')}</Select.Option>
              <Select.Option value="owner">{t('Owner')}</Select.Option>
              <Select.Option value="manager">{t('Manager')}</Select.Option>
              <Select.Option value="marketer">{t('Marketing specialist')}</Select.Option>
              <Select.Option value="developer">{t('Developer')}</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="isPerformer" valuePropName="checked">
            <Checkbox>{t('I provide services to clients')}</Checkbox>
          </Form.Item>

          <CountriesFormItem label={t('Personal phone')} />

          <Form.Item
            name="email"
            label={t('Personal e-mail')}
            rules={[
              {
                required: true,
                type: 'email',
                message: t('Empty field or wrong data'),
              },
            ]}
          >
            <Input
              placeholder={t('Enter an email')}
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            label={t('Password')}
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              {
                pattern: /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,32}$/,
                message: t('The password must be 8+ characters in length. It must contain at least one letter and one number.'),
              },
            ]}
          >
            <Input.Password
              autoComplete="off"
              placeholder={t('Enter a password')}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('Create')}
            </Button>
            <Button style={{ margin: '0 8px' }} type="dashed">
              <Link to={'/auth?login'}>{t('Sign in')}</Link>
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
});

export default SignUpForm;
