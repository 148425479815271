import RequestService from './request-service';
import { IAuthCredential, ISignUpCredential } from '../models/IAuthCredential';
import { AxiosResponse } from 'axios';
import { IUserInput, IUserUpdateInput } from '../models/IUser';
import { IQuery } from '../models/IQuery';

export default class UserService extends RequestService {
  public login = (credential: IAuthCredential): Promise<AxiosResponse> => this.instance.post('user/login', credential);

  public onLoad = (token: string): Promise<AxiosResponse> => this.instance.post('user/on-load', { token });

  public userReservation = (query: IQuery): Promise<AxiosResponse> =>
    this.instance.get('user/reservations', { params: query });

  public signUp = (credential: ISignUpCredential): Promise<AxiosResponse> =>
    this.instance.post('user/create-account', credential);

  public createUser = (user: IUserInput, locationId: string): Promise<AxiosResponse> =>
    this.instance.post('user/create-user', user, { params: { locationId } });

  public updateUser = (userDataToUpdate: IUserUpdateInput, userId: string): Promise<AxiosResponse> =>
    this.instance.put('user', userDataToUpdate, { params: { userId } });

  public deleteUser = (userId?: string, locationId?: string): Promise<AxiosResponse> =>
    this.instance.delete('user', { params: { userId, locationId }});

  public requestNewPassword = (email: string): Promise<AxiosResponse> =>
    this.instance.post('user/request-password', { email });

  public getUser = (userId: string): Promise<AxiosResponse> => this.instance.get('user', { params: { userId } });

  public confirmUser = (confirmCode: string, userId: string): Promise<AxiosResponse> =>
    this.instance.post('user/verify', { verifiedCode: confirmCode, userId });

  public forgotPasswordRequest = (email: string): Promise<AxiosResponse> =>
    this.instance.post('user/request-forgot-password', { email });

  public resetPasswordRequest = (password: string, token: string): Promise<AxiosResponse> =>
    this.instance.post('user/request-reset-password', {
      password,
      token,
    });
  public contactUsRequest = (
    name: string,
    email: string,
    companyName: string,
    description: string
  ): Promise<AxiosResponse> =>
    this.instance.post('user/request-contact-us', {
      name,
      email,
      companyName,
      description,
    });
}
