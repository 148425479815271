import { BankOutlined, BarChartOutlined, BarcodeOutlined, CalendarOutlined, DashboardOutlined, DollarCircleOutlined, IdcardOutlined,
  SettingOutlined, TagOutlined, TeamOutlined, ToolOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { IApplicationState } from '../../models/IApplicationState';
import { IMaster } from '../../models/IUser';
import { hasAdministratorPermissions } from '../../services/auth-service';

export const MainMenu = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const { currentLocation, isAdmin, employeesQuantity, subscription } = useSelector((state: IApplicationState) => ({
    currentLocation: state.location.currentLocation,
    isAdmin: state.auth.user && hasAdministratorPermissions(state.auth.user),
    employeesQuantity: state.master.masters.length - (state.master.masters.some(master => master.role === 'owner') ? 1 : 0),
    subscription: state.subscriptions.subscription,
  }));

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[location.pathname]}
      defaultSelectedKeys={[location.pathname + location.search]}
      style={{ borderRight: 0, paddingBottom: 48 }}
      items={[
        ...(isAdmin ? [
          {
            icon: <DashboardOutlined />,
            key: '/dashboard',
            label: <Link to="/dashboard">{t('Dashboard')}</Link>
          },
        ] : []),
        {
          icon: <CalendarOutlined />,
          key: '/calendar',
          label: <Link to="/calendar">{t('Calendar')}</Link>,
        },
        ...(isAdmin ? [
          {
            icon: <TagOutlined />,
            key: 'services',
            label: t('Services'),
            children: [
              {
                icon: <TagOutlined />,
                key: '/services',
                label: <Link to="/services">{t('Services')}</Link>,
              },
              {
                icon: <TagOutlined />,
                key: '/services/categories',
                label: <Link to="/services/categories">{t('Service categories')}</Link>,
              },
            ],
          },
          {
            icon: <TeamOutlined />,
            key: 'employees',
            label: t('Employees'),
            children: [
              {
                icon: <TeamOutlined />,
                key: '/employees',
                label: <Link to="/employees">{t('Employees')}</Link>,
              },
              {
                icon: <UsergroupAddOutlined />,
                key: '/employees/new',
                label: <Link to="/employees/new">{t('Add new employee')}</Link>,
                disabled: !subscription || subscription.status === 'canceled' || (subscription.staffMembers !== 'Unlimited' && employeesQuantity >= subscription.staffMembers),
              },
              ...(currentLocation ? (currentLocation.masters as IMaster[]).map(master => ({
                key: `/employees/${master._id}`,
                label: <Link to={`/employees/${master._id}`}>{master.name}</Link>,
              })) : []),
            ],
          },
          {
            icon: <UserOutlined />,
            key: '/clients',
            label: <Link to="/clients">{t('Clients')}</Link>,
          },
        ] : []),
        {
          icon: <BarChartOutlined />,
          key: 'analytics',
          label: t('Analytics'),
          children: [
            {
              icon: <UserOutlined />,
              key: '/analytics/clients',
              label: <Link to="/analytics/clients">{t('Customers')}</Link>,
            },
            {
              icon: <UsergroupAddOutlined />,
              key: '/analytics/employees',
              label: <Link to="/analytics/employees">{t('Employees')}</Link>,
            },
            {
              icon: <BarcodeOutlined />,
              key: '/analytics/services',
              label: <Link to="/analytics/services">{t('Services')}</Link>,
            },
          ],
        },
        ...(isAdmin ? [
          {
            icon: <DollarCircleOutlined />,
            key: '/discounts',
            label: <Link to="/discounts">{t('Discounts')}</Link>,
          },
          {
            icon: <ToolOutlined />,
            key: '/widgets',
            label: <Link to="/widgets">{t('Booking widgets')}</Link>,
          },
          {
            icon: <SettingOutlined />,
            key: '/settings',
            label: <Link to="/settings">{t('Branch settings')}</Link>,
          },
          {
            icon: <BankOutlined />,
            key: '/billing',
            label: <Link to="/billing">{t('Subscription')}</Link>
          },
        ] : []),
        {
          icon: <IdcardOutlined />,
          key: '/profile',
          label: <Link to="/profile">{t('Profile')}</Link>,
        },
      ]}
    />
  );
};
