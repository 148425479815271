import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { minTableCellWidth, tableXScroll } from '../../const';
import { AppContext } from '../../context/app-context';
import { IApplicationState } from '../../models/IApplicationState';
import { ILocation, IWidget } from '../../models/ILocation';
import { deleteLocationWidget, getLocationWidgets } from '../../services/locations.service';
import { setBreadcrumbs } from '../../store/app/actions';
import { createBreadcrumb } from '../../store/app/utils';

export const Widgets = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { mainContainerHeight } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [widgets, setWidgets] = useState([]);

  const locationId = useSelector((state: IApplicationState) => state.location.currentLocation?._id);

  const updateData = useCallback(() => {
    setLoading(true);
    getLocationWidgets(locationId as string)
      .then(response => setWidgets(response.data))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }, [locationId, setLoading, setWidgets]);

  const handleWidgetEdit = (widget: IWidget) => history.push(`/widgets/${widget._id}`);
  const handleWidgetDelete = (widget: IWidget) => {
    confirm({
      content: t('Are you sure that you want to delete this widget?'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteLocationWidget(widget._id)
          .then(() => updateData());
      },
    });
  };

  const columns = useMemo(() => [
    {
      title: t('Widget name'),
      width: minTableCellWidth,
      dataIndex: 'name',
    },
    {
      title: t('Branches'),
      width: minTableCellWidth,
      dataIndex: 'locations',
      render: (locations: ILocation[]) => <>{locations.map(location => <div key={location._id}>{location.name}</div>)}</>,
    },
    {
      title: t('Type'),
      width: minTableCellWidth,
      dataIndex: 'type',
    },
    {
      title: t('Operations'),
      width: minTableCellWidth,
      key: 'operations',
      render: (widget: IWidget) => (
        <>
          <Tooltip placement="top" title={t('Edit')}>
            <Button
              className="mr-1"
              icon={<EditOutlined />}
              shape="circle"
              type="primary"
              onClick={() => handleWidgetEdit(widget)}
            />
          </Tooltip>
          <Tooltip placement="top" title={t('Delete')}>
            <Button
              danger
              icon={<DeleteOutlined />}
              shape="circle"
              onClick={() => handleWidgetDelete(widget)}
            />
          </Tooltip>
        </>
      ),
    },
  ], []);

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Booking widgets'))]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    if (locationId) {
      updateData();
    }
  }, [locationId, updateData]);

  const handleAddWidgetClick = () => history.push('/widgets/new');

  return (
    <Row>
      <Col span={24} className={window.innerWidth <= 800 ? 'mb-3' : ''}>
        <Button
          className="phone-button-margin-full"
          type="primary"
          onClick={handleAddWidgetClick}
        >
          {t('Add a new widget')}
        </Button>
      </Col>
      <Col className="mt-3" span={24}>
        <Table
          style={{ maxHeight: mainContainerHeight - 300 }}
          scroll={{ x: tableXScroll, y: mainContainerHeight }}
          size="small"
          loading={loading}
          rowKey={(item: any) => item._id}
          columns={columns}
          dataSource={widgets}
        />
      </Col>
    </Row>
  );
};
