import { Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { IApplicationState } from '../../models/IApplicationState';
import { getStartScreenData } from '../../services/dashboard.service';
import { StartCard } from './StartCard/StartCard';
import { Statistics, StatisticsInterface } from './Statistics/Statistics';

export const Dashboard = () => {
  const { width, ref: rowRef } = useResizeDetector();

  const [statistics, setStatistics] = useState<StatisticsInterface>({
    locationAverageRevenue: [],
    locationReservationsQuantity: [],
    locationAverageCheck: [],
    locationReservationWorkload: [],
  });

  const locationId = useSelector((state: IApplicationState) => state.location.currentLocation?._id);

  const cardsPerRow = useMemo(() => width! >= 800 ? 4 : width! >= 400 ? 2 : 1, [width]);

  useEffect(() => {
    if (locationId) {
      const humanizeDateProperty = (data: Record<string, any>[]) =>
        data.map(item => ({ value: item.value, date: moment(item.date).format('LL') }));
      getStartScreenData(locationId)
        .then(response => setStatistics({
          locationAverageRevenue: humanizeDateProperty(response.data.locationAverageRevenue),
          locationReservationsQuantity: humanizeDateProperty(response.data.locationReservationsQuantity),
          locationAverageCheck: humanizeDateProperty(response.data.locationAverageCheck),
          locationReservationWorkload: humanizeDateProperty(response.data.locationReservationWorkload),
        }))
        .catch(error => console.error(error));
    }
  }, [locationId]);

  return (
    <Row gutter={16} ref={rowRef}>
      <StartCard />
      <Statistics cardsPerRow={cardsPerRow} statistics={statistics} />
    </Row>
  );
};
