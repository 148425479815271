import { AnalyticsTypes } from './types';
import { takeEvery, put } from 'redux-saga/effects';
import { clientsFailure, clientsSuccess, mastersFailure, mastersSuccess, servicesSuccess } from './actions';
import AnalyticsService from '../../services/analytics-service';
import { IQuery } from '../../models/IQuery';
import { CsvService } from '../../services/csv-service';
import { splitObject } from '../../services/utils';
import moment from 'moment';
import i18next from 'i18next';

const analyticsService = new AnalyticsService();
const csvService = new CsvService();

function* clientWorker(action: { type: string; payload: { locationId: string; pagination: IQuery; queryData: any } }): Generator<any, any, any> {
  try {
    const response = yield analyticsService.clients(
      action.payload.locationId,
      action.payload.pagination,
      action.payload.queryData
    );

    yield put(clientsSuccess(response.data.clients, response.data.total));
  } catch (e) {
    console.log(e);
    yield put(clientsFailure((e as { message: string }).message));
  }
}

function* masterWorker(action: { type: string; payload: { from: Date; to: Date; locationId: string } }): Generator<any, any, any> {
  try {
    const response = yield analyticsService.masters(action.payload);
    yield put(
      mastersSuccess(response.data.masters, response.data.masters.length, {
        totalReservations: response.data.totalReservations,
        earnMoney: response.data.earnMoney,
        totalGroupReservations: response.data.totalGroupReservations,
        earnMoneyGroup: response.data.earnMoneyGroup,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(mastersFailure((e as { message: string }).message));
  }
}

function* serviceWorker(action: { type: string; payload: { from: Date; to: Date; locationId: string } }): Generator<any, any, any> {
  try {
    const response = yield analyticsService.services(action.payload)

    yield put(servicesSuccess(response.data.services));
  } catch (e) {
    console.log(e);
    yield put(clientsFailure((e as { message: string }).message));
  }
}

function* exportWorker(action: {
  type: string;
  payload: {
    from: Date;
    to: Date;
    table: string;
    locationId: string;
    queryData: any;
  };
}): Generator<any, any, any> {
  try {
    const response = yield analyticsService.exportData(action.payload);

    const data = response.data.data.map((item: any) => {
      const [clearItem] = splitObject(item, ['_id']);
      for (let propName in clearItem) {
        if (clearItem[propName] === null || clearItem[propName] === undefined) {
          clearItem[propName] = '';
        }

        if (
          clearItem[propName] !== '' &&
          ['firstReservation', 'lastReservation', 'firstGroupReservation', 'lastGroupReservation'].includes(propName)
        ) {
          clearItem[propName] = moment(clearItem[propName]).format('DD/MM/YYYY');
        }
      }
      return clearItem;
    });

    const headers = response.data.headers;
    for (let propName in headers) {
      headers[propName] = i18next.t(propName);
    }

    const fileName = `${i18next.t(action.payload.table)} ${moment(action.payload.from)
      .format('LL')
      .replace('.', '')} - ${moment(action.payload.to).format('LL').replace('.', '')}`;
    csvService.exportCSVFile(headers, data, fileName);
  } catch (e) {
    console.log(e);
    yield put(clientsFailure((e as { message: string }).message));
  }
}

export function* watchAuth() {
  yield takeEvery(AnalyticsTypes.CLIENTS_REQUEST, clientWorker);
  yield takeEvery(AnalyticsTypes.MASTERS_REQUEST, masterWorker);
  yield takeEvery(AnalyticsTypes.SERVICES_REQUEST, serviceWorker);
  yield takeEvery(AnalyticsTypes.EXPORT_DATA, exportWorker);
}

const analyticsWatchers: any = [watchAuth()];

export default analyticsWatchers;
