
import { minTableCellWidth } from '../../const';

export const SUBSCRIPTION_COLUMNS = (t: any) => [
  {
    title: t('Subscription Name'),
    dataIndex: 'planName',
    width: minTableCellWidth,
  },
  {
    title: t('Amount Charged'),
    dataIndex: 'lastPaymentAmount',
    width: minTableCellWidth,
  },
  {
    title: t('Status'),
    dataIndex: 'status',
    width: minTableCellWidth,
  },
  {
    title: t('Next Billing Amount'),
    dataIndex: 'nextPaymentAmount',
    width: minTableCellWidth,
  },
  {
    title: t('Next Billing Date'),
    dataIndex: 'nextBillingDate',
    width: minTableCellWidth,
  },
  {
    title: t('Actions'),
    dataIndex: 'actions',
    width: minTableCellWidth,
  },
];
