import { Button, Col, message, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetType } from '../../../../models/ILocation';
import { copyToClipboard, getWidgetLinkOrCode } from '../../../../services/utils';
import { ResultStepForm } from '../ResultStepForm/ResultStepForm';

export const ResultStep = (props: ResultStepProps) => {
  const { t } = useTranslation();

  const linkOrCode = useMemo(() => props.widgetId &&
    getWidgetLinkOrCode(props.model.type, props.widgetId), [props.model.type, props.widgetId]);

  const handleCopy = () => {
    if (linkOrCode) {
      copyToClipboard(linkOrCode);
      message.success(`${t('Copied')}`);
    }
  };

  const handleSave = () => props.onSave();
  const handleBack = () => props.onBack();

  return (
    <Row gutter={16}>
      {linkOrCode ? (
        <ResultStepForm
          linkOrCode={linkOrCode}
          widgetType={props.model.type}
        />
      ) : props.model.type === WidgetType.Website ? (
        <p>{t('Widget code will be available after you save the settings.')}</p>
      ) : (
        <p>{t('Widget link will be available after you save the settings.')}</p>
      )}
      <Col className="text-right" span={24}>
        <Button className="mr-2" type="primary" onClick={handleBack}>{t('Back')}</Button>
        <Button className="mr-2" disabled={!linkOrCode} type="primary" onClick={handleCopy}>{t('Copy')}</Button>
        <Button type="primary" onClick={handleSave}>{t('Save')}</Button>
      </Col>
    </Row>
  );
};

interface ResultStepProps {
  model: any;
  widgetId?: string;
  onBack: () => void;
  onSave: () => void;
}
