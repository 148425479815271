import axiosInstance from './axios-instance';

export const getLocation = (locationId: string) => axiosInstance.get('/location', { params: { locationId }});

export const getLocationWidgets = (locationId: string) => axiosInstance.get('/location/widgets', { params: { locationId }});

export const getLocationWidget = (widgetId: string) => axiosInstance.get(`/location/widgets/${widgetId}`);

export const createLocationWidget = (payload: any) => axiosInstance.post('/location/widgets', payload);

export const updateLocationWidget = (widgetId: string, payload: any) => axiosInstance.put(`/location/widgets/${widgetId}`, payload);

export const deleteLocationWidget = (widgetId: string) => axiosInstance.delete(`/location/widgets/${widgetId}`);
