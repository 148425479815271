import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { IApplicationState } from '../../models/IApplicationState';
import { RouteRenderComponent } from './RouteRenderComponent/RouteRenderComponent';

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const { loading, user } = useSelector((state: IApplicationState) => ({
    loading: state.auth.loading,
    user: state.auth.user,
  }));

  return (
    <Route
      path={props.path}
      render={(routeProps: RouteComponentProps) => (
        <RouteRenderComponent
          props={props}
          routeProps={routeProps}
          loading={loading}
          user={user}
        />
      )}
    />
  );
};

export interface ProtectedRouteProps {
  Component?: React.FC<RouteComponentProps>;
  Layout?: React.FC;
  captcha?: boolean;
  path: string;
  redirect?: string;
  public?: boolean;
  roles?: string[];
}
