import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Statistic, Table, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { UserSearchBar } from '../../components/UserSearchBar/UserSearchBar';
import { tableXScroll } from '../../const';
import { AppContext } from '../../context/app-context';
import { IApplicationState } from '../../models/IApplicationState';
import { IMaster, IUser } from '../../models/IUser';
import { copyToClipboard } from '../../services/utils';
import { setBreadcrumbs } from '../../store/app/actions';
import { createBreadcrumb } from '../../store/app/utils';
import { deleteMasterRequest, searchMastersRequest } from '../../store/master/actions';
import { COLUMNS } from './constants';

export const Employees = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { mainContainerHeight } = useContext(AppContext);

  const [searchField, setSearchField] = useState('name');
  const [searchQuery, setSearchQuery] = useState('');

  const [query, setQuery] = useState<Record<string, unknown>>({});
  const [sort, setSort] = useState<Record<string, number>>({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 200,
    showQuickJumper: true,
  });

  const { loading, locationId, masters, employeesQuantity, total, authenticatedUser, subscription } =
    useSelector((state: IApplicationState) => ({
      loading: state.master.loading,
      locationId: state.location.currentLocation?._id,
      masters: state.master.masters,
      employeesQuantity: state.master.masters.length - (state.master.masters.some(master => master.role === 'owner') ? 1 : 0),
      total: state.master.total,
      authenticatedUser: state.auth.user,
      subscription: state.subscriptions.subscription,
    }));

  const canAddEmployee = useMemo(() => subscription && subscription.status !== 'canceled' &&
    (subscription.staffMembers === 'Unlimited' || employeesQuantity < subscription.staffMembers), [subscription, employeesQuantity]);

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Employees'))]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    setPagination({ ...pagination, total });
    // eslint-disable-next-line
  }, [loading, total, masters]);

  useEffect(() => {
    if (locationId) {
      dispatch(
        searchMastersRequest(
          locationId as string,
          {
            offset: pagination.pageSize * (pagination.current - 1),
            limit: pagination.pageSize,
          },
          { key: searchField, value: searchQuery },
          { sort, query }
        )
      );
    }
    // eslint-disable-next-line
  }, [locationId, dispatch]);

  const handleSearchFieldChange = (field: string) => setSearchField(field);

  const handleSearchQueryChange = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    setPagination({
      current: 1,
      pageSize: 10,
      total: 200,
      showQuickJumper: true,
    });

    dispatch(
      searchMastersRequest(
        locationId as string,
        { limit: 10, offset: 0 },
        { key: searchField, value: searchQuery },
        { sort, query }
      )
    );
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setPagination(pagination);

    let sort: any;
    if (sorter.order) {
      sort = { [sorter.field]: sorter.order === 'descend' ? 1 : -1 };
    } else {
      sort = {};
    }
    setSort(sort);

    let query: any = {};
    Object.keys(filters).forEach(key => {
      if ((key === 'ban' || key === 'subscription') && filters[key] && filters[key].length === 1) {
        query[key] = filters[key][0];
      }
    });
    setQuery(query);

    dispatch(
      searchMastersRequest(
        locationId as string,
        {
          offset: pagination.pageSize * (pagination.current - 1),
          limit: pagination.pageSize,
        },
        { key: searchField, value: searchQuery },
        { sort, query }
      )
    );
  };

  const handleTableValueCopy = (value: any) => {
    copyToClipboard(value);
    message.success(`${t('Copied')}: ${value}`);
  };

  const renderTableValue = (value: any) => (
    <div onClick={() => handleTableValueCopy(value)}>
      <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
    </div>
  );

  const handleEmployeeEdit = (master: IMaster) => history.push(`/employees/${master._id}`);

  const handleEmployeeDelete = (employee: IUser) => {
    confirm({
      title: t('Are you sure you want to delete this employee?'),
      icon: <ExclamationCircleOutlined />,
      content: t('After deletion, all his reservations will be transferred to the administrator.'),
      onOk() {
        dispatch(deleteMasterRequest(employee._id, locationId!));
      },
    });
  };

  const columns: any = COLUMNS({
    tableValue: renderTableValue,
    t,
    authenticatedUser,
    handleEmployeeDelete,
    handleEmployeeEdit,
  });

  return (
    <Row>
      <Col span={window.innerWidth <= 800 ? 24 : 7} style={{ marginBottom: window.innerWidth <= 800 ? '16px' : 0 }}>
        <Button
          className="w-full"
          disabled={!canAddEmployee}
          type="primary"
        >
          <Link to="/employees/new">{t('Add new employee')}</Link>
        </Button>
      </Col>
      <Col span={window.innerWidth <= 800 ? 24 : 16} offset={window.innerWidth <= 800 ? 0 : 1}>
        <UserSearchBar
          field={searchField}
          fields={{
            name: t('Name'),
            email: t('E-mail'),
            phone: t('Phone'),
          }}
          loading={loading}
          onFieldChange={handleSearchFieldChange}
          onQueryChange={handleSearchQueryChange}
        />
      </Col>
      <Col span={24} style={{ marginTop: 16 }}>
        <Statistic className="mobile-left-padding" title={t('Total employees')} value={total} />
      </Col>
      <Col span={24} style={{ marginTop: 16 }}>
        <Table
          scroll={{ x: tableXScroll, y: mainContainerHeight }}
          size="small"
          loading={loading}
          onChange={handleTableChange}
          rowKey={(master: IMaster) => master._id}
          pagination={pagination}
          columns={columns}
          dataSource={masters}
        />
      </Col>
    </Row>
  );
};
