export interface LogsState {
  readonly logs: any[];
  readonly loading: boolean;
  readonly total: number;
}

export enum LogActionTypes {
  LOG_REQUEST = '@@log/LOG_REQUEST',
  LOG_SUCCESS = '@@log/LOG_SUCCESS',

  CLEAR_LOGS = '@@log/CLEAR_LOGS',

  ADD_LOG = '@@log/ADD_LOG',

  CLEAN_ERRORS = '@@log/CLEAN_ERRORS',
}
