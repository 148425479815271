import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Col, Row, TimePicker, FormInstance } from 'antd';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const TimeSlotsRow = (props: TimeSlotsRowProps) => {
  const { t } = useTranslation();

  const toStart = Form.useWatch(`${props.day}-to-start`, props.form);
  const toEnd = Form.useWatch(`${props.day}-to-end`, props.form);

  useEffect(() => {
    if (toStart || toEnd) {
      handleSecondSlotAdd();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toStart, toEnd]);

  const handleFromStartSelect = (value: any) => props.form.setFieldsValue({ [`${props.day}-from-start`]: value });
  const handleFromEndSelect = (value: any) => props.form.setFieldsValue({ [`${props.day}-from-end`]: value });
  const handleToStartSelect = (value: any) => props.form.setFieldsValue({ [`${props.day}-to-start`]: value });
  const handleToEndSelect = (value: any) => props.form.setFieldsValue({ [`${props.day}-to-end`]: value });

  const handleAllDaysFill = () => props.onAllDaysFill();

  const handleSecondSlotAdd = () => props.onSecondSlotAdd(props.day);

  const handleRemoveSecondSlot = () => {
    props.form.setFieldsValue({ [`${props.day}-to-start`]: '' });
    props.form.setFieldsValue({ [`${props.day}-to-end`]: '' });
    props.onSecondSlotRemove(props.day);
  };

  return (
    <Row gutter={[24, 16]} align="middle" className={`time-slots--row` + (props.secondSlotVisible ? ' time-slots--row--double' : '')}>
      <div className="time-slots--weekday">{t(capitalize(props.day))}</div>

      <div className="time-slots--from px-1">
        <div className="ant-input flex">
          <Form.Item name={[`${props.day}-from-start`]} noStyle>
            <TimePicker
              placeholder={t('From')}
              minuteStep={10}
              bordered={false}
              onSelect={handleFromStartSelect}
              format="HH:mm"
            />
          </Form.Item>
          <Form.Item name={[`${props.day}-from-end`]} noStyle>
            <TimePicker
              placeholder={t('To')}
              minuteStep={10}
              bordered={false}
              onSelect={handleFromEndSelect}
              format="HH:mm"
            />
          </Form.Item>
        </div>
      </div>

      <div className="time-slots--to px-1" style={{ display: props.secondSlotVisible ? 'block' : 'none' }}>
        <div className="ant-input flex">
          <Form.Item name={[`${props.day}-to-start`]} noStyle>
            <TimePicker
              placeholder={t('From')}
              minuteStep={10}
              bordered={false}
              onSelect={handleToStartSelect}
              format="HH:mm"
            />
          </Form.Item>
          <Form.Item name={[`${props.day}-to-end`]} noStyle>
            <TimePicker
              placeholder={t('To')}
              popupClassName="timepicker"
              minuteStep={10}
              bordered={false}
              onSelect={handleToEndSelect}
              format="HH:mm"
            />
          </Form.Item>
        </div>
      </div>

      {props.secondSlotVisible ? (
        <div className="time-slots--actions-1 flex px-1">
          <Button
            danger
            type="primary"
            icon={<DeleteOutlined />}
            onClick={handleRemoveSecondSlot}
          />
          {props.day === 'monday' && (
            <Button
              className="ml-1 fill-all-days-button"
              type="primary"
              onClick={handleAllDaysFill}
            >
              {t('Fill all days')}
            </Button>
          )}
        </div>
      ) : (
        <div className="time-slots--actions-2 flex px-1">
          <Button
            className="add-slot-button"
            icon={<PlusOutlined />}
            type="primary"
            onClick={handleSecondSlotAdd}
          />
          {props.day === 'monday' && (
            <Button
              className="ml-1 fill-all-days-button"
              type="primary"
              onClick={handleAllDaysFill}
            >
              {t('Fill all days')}
            </Button>
          )}
        </div>
      )}
    </Row>
  );
};

type TimeSlotsRowProps = {
  day: string;
  form: FormInstance;
  secondSlotVisible: boolean;
  onAllDaysFill: () => void;
  onSecondSlotAdd: (day: string) => void;
  onSecondSlotRemove: (day: string) => void;
};
