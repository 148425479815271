import { Button, Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralSettingsStepForm } from '../GeneralSettingsStepForm/GeneralSettingsStepForm';

export const GeneralSettingsStep = (props: GeneralSettingsStepProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      type: props.model.type,
      locations: props.model.locations,
    });
  }, []);

  const handleSubmit = () => props.onNext();
  const handleFormChange = (values: any) => form.setFieldsValue(values);
  const handleCancel = () => props.onCancel();

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Row gutter={16}>
        <GeneralSettingsStepForm
          model={props.model}
          onChange={props.onChange}
          onFormChange={handleFormChange}
        />
        <Col className="text-right" span={24}>
          <Button className="mr-2" type="primary" onClick={handleCancel}>{t('Cancel')}</Button>
          <Button type="primary" htmlType="submit">{t('Next')}</Button>
        </Col>
      </Row>
    </Form>
  );
};

interface GeneralSettingsStepProps {
  model: any;
  onCancel: () => void;
  onChange: (payload: any) => void;
  onNext: () => void;
}
