import { FormInstance } from 'antd';
import React, { useState } from 'react';
import { weekDays } from '../../const';
import { TimeSlotsRow } from './TimeSlotsRow';

export const TimeSlots = (props: TimeSlotsProps) => {
  const [secondSlotsVisible, setSecondSlotsVisible] = useState<Record<string, boolean>>({
    'monday': false,
    'tuesday': false,
    'wednesday': false,
    'thursday': false,
    'friday': false,
    'saturday': false,
    'sunday': false,
  });

  const handleAllDaysFill = () => {
    const values = props.form.getFieldsValue();
    for (const weekDay of weekDays) {
      if (weekDay !== 'monday') {
        props.form.setFieldsValue({
          [`${weekDay}-from-start`]: values['monday-from-start'],
          [`${weekDay}-from-end`]: values['monday-from-end'],
          [`${weekDay}-to-start`]: values['monday-to-start'],
          [`${weekDay}-to-end`]: values['monday-to-end'],
        });
      }
    }
    if (values['monday-to-start'] || values['monday-to-end']) {
      setSecondSlotsVisible({
        'monday': true,
        'tuesday': true,
        'wednesday': true,
        'thursday': true,
        'friday': true,
        'saturday': true,
        'sunday': true,
      });
    } else {
      setSecondSlotsVisible({
        'monday': false,
        'tuesday': false,
        'wednesday': false,
        'thursday': false,
        'friday': false,
        'saturday': false,
        'sunday': false,
      });
    }
  };

  const handleSecondSlotAdd = (day: string) => setSecondSlotsVisible(state => ({ ...state, [day]: true }));
  const handleSecondSlotRemove = (day: string) => setSecondSlotsVisible(state => ({ ...state, [day]: false }));

  return (
    <>
      {weekDays.map(day => (
        <TimeSlotsRow
          key={day}
          day={day}
          form={props.form}
          secondSlotVisible={secondSlotsVisible[day]}
          onAllDaysFill={handleAllDaysFill}
          onSecondSlotAdd={handleSecondSlotAdd}
          onSecondSlotRemove={handleSecondSlotRemove}
        />
      ))}
    </>
  );
};

type TimeSlotsProps = {
  form: FormInstance;
};
