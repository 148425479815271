import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IServiceCategory } from '../../../models/IService';
import { createServiceCategory, updateServiceCategory } from '../../../services/services.service';

export const ServiceCategoryDialog = (props: ServiceCategoryDialogProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.category) {
      form.setFieldsValue({ name: props.category.name });
    }
  }, [props.category]);

  useEffect(() => {
    if (!props.visible) {
      form.setFieldsValue({ name: '' });
    }
  }, [props.visible]);

  const handleCancel = () => props.onClose();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      if (props.category) {
        await updateServiceCategory(props.category._id, values);
      } else {
        await createServiceCategory(values);
      }
      props.onClose();
      props.onSave();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!props.visible && <Form form={form} />}
      <Modal
        title={props.category ? t('Edit category') : t('Add category')}
        visible={props.visible}
        onCancel={handleCancel}
        okText={props.category ? t('Save') : t('Create')}
        cancelText={t('Cancel')}
        footer={null}
      >
        <Spin spinning={loading}>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Row>
              <Col span={24}>
                <Form.Item name="name" label={t('Name')} rules={[{ required: true, message: t('Required field') }]}>
                  <Input placeholder={t('Enter category name')} />
                </Form.Item>
              </Col>
              <Col span={24} className="text-right">
                <Button loading={loading} htmlType="submit" type="primary">{t('Save')}</Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

interface ServiceCategoryDialogProps {
  category: IServiceCategory | null;
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}
