import { notification } from 'antd';
import QueryString from 'qs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkVerificationCode } from '../../../services/user.service';
import { setVerified } from '../../../store/auth/actions';

export const RegistrationConfirm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const query = QueryString.parse(history.location.search, { ignoreQueryPrefix: true });
    if (query.verificationCode && query.userId) {
      checkVerificationCode(query.verificationCode as string, query.userId as string)
        .then(() => {
          dispatch(setVerified());
          notification.success({
            message: t('Registration confirmation'),
            description: t('The account has been confirmed successfully.'),
          });
          history.push('/');
        })
        .catch(error => console.error(error));
    }
  }, [history, history.location.search]);

  return <></>;
};
