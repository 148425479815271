import { Col, Form, Input, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from '../../../../const';
import { ColorBox, ColorBoxContainer } from '../../../../styled';

export const WidgetSettingsStepForm = (props: WidgetSettingsStepFormProps) => {
  const { t } = useTranslation();

  const handleWidgetNameChange = (event: any) => props.onChange((state: any) => ({ ...state, name: event.target.value }));
  const handleWidgetStyleChange = (style: string) => props.onChange((state: any) => ({ ...state, style }));
  const handleDescriptionChange = (event: any) => props.onChange((state: any) => ({ ...state, about: event.target.value }));

  return (
    <Col span={24}>
      <Form.Item
        label={t('Widget name')}
        name="name"
        rules={[{ required: true, message: t('Required field') }]}
      >
        <Input autoComplete="off" placeholder={t('Enter widget name')} onChange={handleWidgetNameChange} />
      </Form.Item>
      <Form.Item name="style" rules={[{ required: true, message: t('Required field') }]} label={t('Widget style')}>
        <Select className="align-items-center" placeholder={t('Select widget style')} onChange={handleWidgetStyleChange}>
          {/* <Select.OptGroup label={t('Light theme')}> */}
            <Select.Option style={{ alignItems: 'center' }} value="light">
              <ColorBoxContainer>
                {t('Light')}{' '}
                {themes.light.map((color: string) => (
                  <ColorBox key={color} color={color} />
                ))}
              </ColorBoxContainer>
            </Select.Option>
          {/* </Select.OptGroup>
          <Select.OptGroup label={t('Dark theme')}> */}
            <Select.Option style={{ alignItems: 'center' }} value="dark">
              <ColorBoxContainer>
                {t('Dark')}{' '}
                {themes.dark.map((color: string) => (
                  <ColorBox key={color} color={color} />
                ))}
              </ColorBoxContainer>
            </Select.Option>
          {/* </Select.OptGroup> */}
        </Select>
      </Form.Item>
      <Form.Item rules={[{ required: true, message: t('Required field') }]} name="about" label={t('Description')}>
        <Input.TextArea rows={3} onChange={handleDescriptionChange} />
      </Form.Item>
    </Col>
  );
};

interface WidgetSettingsStepFormProps {
  onChange: (state: any) => void;
}
