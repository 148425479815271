import { takeEvery, put } from 'redux-saga/effects';
import { SubscriptionTypes } from './types';
import {
  getSubscriptionRequestSuccess,
  updateUserSubscriptionRequestFail,
  updateUserSubscriptionRequestSuccess,
} from './actions';

import { AxiosResponse } from 'axios';
import { onServiceRequestFailure } from '../service/actions';
import { push } from 'react-router-redux';
import { notification } from 'antd';
import i18n from '../../i18n';
import { getCurrentSubscription, createSubscription } from '../../services/subscriptions.service';

function* getSubscriptionWorker(action: {
  type: string;
  payload: {
    locationId: string;
  };
}): Generator<any, any, any> {
  try {
    const response: AxiosResponse = yield getCurrentSubscription(action.payload.locationId);
    yield put(getSubscriptionRequestSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onServiceRequestFailure((e as { message: string }).message));
  }
}

function* updateUserSubscriptionWorker(action: {
  type: string;
  payload: {
    locationId: string;
    priceId: string;
  };
}): Generator<any, any, any> {
  try {
    const response: AxiosResponse = yield createSubscription(action.payload.locationId, action.payload.priceId);
    yield put(push(`/billing`));
    notification.success({
      message: i18n.t('Subscription has updated successfully.'),
    });
    yield put(updateUserSubscriptionRequestSuccess(response));
  } catch (e) {
    yield put(updateUserSubscriptionRequestFail());
    console.log(e);
    yield put(onServiceRequestFailure((e as { message: string }).message));
  }
}

export function* watchSubcription() {
  yield takeEvery(SubscriptionTypes.FETCH_USER_SUBSCRIPTION, getSubscriptionWorker);
  yield takeEvery(SubscriptionTypes.UPDATE_USER_SUBSCRIPTION, updateUserSubscriptionWorker);
}

const subscriptionWatchers: any = [watchSubcription()];

export default subscriptionWatchers;
