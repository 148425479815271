import { Card, Col, Steps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../../models/IApplicationState';
import { ILocation } from '../../../models/ILocation';
import { getLocation } from '../../../services/locations.service';
import { setBreadcrumbs } from '../../../store/app/actions';
import { createBreadcrumb } from '../../../store/app/utils';

export const StartCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState<ILocation | null>(null);

  const { locationId, userName } = useSelector((state: IApplicationState) => ({
    locationId: state.location.currentLocation?._id,
    userName: state.auth.user?.name,
  }));

  const currentStep = useMemo(() => {
    if (location) {
      if (location.services.length) {
        if (location.masters.length) {
          if (location.widget) {
            return 3;
          }
          return 2;
        }
        return 1;
      }
      return 0;
    } else {
      return null;
    }
  }, [location]);

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Dashboard'))]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    if (locationId) {
      setLoading(true);
      getLocation(locationId)
        .then(response => setLocation(response.data))
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [locationId]);

  return currentStep !== null && currentStep < 3 ? (
    <Col span={24} className="mb-3">
      <Card loading={loading} title={t('Welcome, {{userName}}! Easy onboarding steps', { userName })}>
        <Steps current={currentStep} progressDot>
          <Steps.Step title={t('Sign up')} subTitle={t('Welcome!')} />
          <Steps.Step title={t('Service')} subTitle={t('Create the first service')} />
          <Steps.Step title={t('Employee')} subTitle={t('Create the first employee')} />
          <Steps.Step title={t('Widget')} subTitle={t('Сonfigure the booking widget')} />
        </Steps>
      </Card>
    </Col>
  ) : <></>;
};
