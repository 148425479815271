import { Select } from 'antd';
import * as moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';
import { APP_LANG, flags, languages } from '../../const';
import { momentLangSetup } from '../../i18n';
import ForgotPassword from './ForgotPassword';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import { loginRequest } from '../../store/auth/actions';
import { useDispatch } from 'react-redux';

const Auth: React.FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { t, i18n } = useTranslation();

  const lang = useMemo(() => localStorage.getItem(APP_LANG) || 'en', []);
  const queryParams = useMemo(() => qs.parse(search.charAt(0) === '?' ? search.slice(1) : search), [search]);

  useEffect(() => {
    if (queryParams.login !== undefined && queryParams.masterId && queryParams.token) {
      dispatch(loginRequest({
        masterId: queryParams.masterId as string,
        token: queryParams.token as string,
      }));
    }
  }, [queryParams]);

  const handleLanguageChange = (lang: any) => {
    localStorage.setItem(APP_LANG, lang as string);
    //@ts-ignore
    moment.locale(lang, momentLangSetup[lang]);
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  return (
    <Wrapper>
      <Select
        defaultValue={lang}
        bordered={false}
        style={{
          alignItems: 'center',
          float: 'right',
          marginRight: '30px',
          width: 140,
        }}
        onChange={handleLanguageChange}
      >
        {languages.map((language) => (
          <Select.Option key={language} value={language} label={t(`[Language] ${language}`)}>
            {t(`[Language] ${language}`)}{' '}
            {
              //@ts-ignore
              flags[language as any]
            }
          </Select.Option>
        ))}
      </Select>

      <FormWrapper>
        {queryParams.login !== undefined ? (
          <LoginForm />
        ) : queryParams['forgot-password'] !== undefined ? (
          <ForgotPassword />
        ) : (
          <SignUpForm />
        )}
      </FormWrapper>
    </Wrapper>
  );
};

export default Auth;

const Wrapper = styled.div`
  padding-top: 5em;
`;

const FormWrapper = styled.div`
  padding-top: 50px;
`;
