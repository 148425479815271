import { Button, Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSettingsStepForm } from '../ButtonSettingsStepForm/ButtonSettingsStepForm';

export const ButtonSettingsStep = (props: ButtonSettingsStepProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      buttonColors: props.model.buttonColors,
      buttonStyle: props.model.buttonStyle,
      buttonText: props.model.buttonText,
    });
  }, []);

  const handleSubmit = () => props.onNext();
  const handleBack = () => props.onBack();

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Row gutter={16}>
        <ButtonSettingsStepForm
          model={props.model}
          onChange={props.onChange}
        />
        <Col className="text-right" span={24}>
          <Button className="mr-2" type="primary" onClick={handleBack}>{t('Back')}</Button>
          <Button type="primary" htmlType="submit">{t('Next')}</Button>
        </Col>
      </Row>
    </Form>
  );
};

interface ButtonSettingsStepProps {
  model: any;
  onBack: () => void;
  onChange: (state: any) => void;
  onNext: () => void;
}
