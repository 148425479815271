import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Layout, Button, notification, Alert, Row, message } from 'antd';
import { FullLayout, MainContent, MainContentLayout } from '../styled';
import { useTranslation } from 'react-i18next';
import { IApplicationState } from '../models/IApplicationState';
import { IMaster, IUser } from '../models/IUser';
import moment from 'moment';
import {
  setUpDate,
  wssReservationPush,
  wssReservationRemove,
  wssReservationUpdate,
} from '../store/reservation/actions';
import { env } from '../const/env';
import { AppContext } from '../context/app-context';
import { IWssMessage } from '../models/IWss';
import { IService } from '../models/IService';
import { IFeedback } from '../models/IFeedback';
import { addLog } from '../store/log/actions';
import { getSubscriptionRequest } from '../store/subscription/actions';
import { AppHeader } from '../components/AppHeader/AppHeader';
import { AppSidebar } from '../components/AppSidebar/AppSidebar';
import { resendRegistrationConfirmation } from '../services/master.service';
import { getTimeFormat } from '../services/utils';

export const MainLayout: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { pathname, search } = useLocation();

  const appContext = useContext(AppContext);

  const upperCaseFirstLetter = (str: string): string => {
    var firstLetter = str.substr(0, 1);
    return firstLetter.toUpperCase() + str.substr(1);
  };

  useEffect(() => {
    const [, _path] = window.location.pathname.split('/');
    document.title = `Skeedee ${upperCaseFirstLetter(_path)}`;
    const timeout = setTimeout(() => {
      try {
        const e: any = document.getElementsByClassName('ant-layout-content');
        const mainContainerHeight = e[0].clientHeight - 96;
        const mainContainerWidth: number = e[0].clientWidth;
        document.documentElement.style.setProperty(
          '--main-wrapper-heigth',
          mainContainerHeight - (window.innerWidth <= 800 ? 33 : 0) + 'px'
        );
        appContext.setContext({
          mainContainerHeight: mainContainerHeight - window.innerWidth <= 800 ? 33 : 0,
          mainContainerWidth,
        });
      } catch (e) {
        console.log(e);
      }
    }, 0);

    const handleResize = () => {
      setTimeout(() => {
        try {
          const e: any = document.getElementsByClassName('ant-layout-content');
          const mainContainerHeight = e[0].clientHeight - 96;
          const mainContainerWidth: number = e[0].clientWidth;
          document.documentElement.style.setProperty(
            '--main-wrapper-heigth',
            mainContainerHeight - (window.innerWidth <= 800 ? 33 : 0) + 'px'
          );
          appContext.setContext({
            mainContainerHeight: mainContainerHeight - (window.innerWidth <= 800 ? 33 : 0),
            mainContainerWidth,
          });
        } catch (e) {
          console.log(e);
        }
      }, 0);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeout);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { locationId, location, userId, verified, is12H } = useSelector((state: IApplicationState) => ({
    locationId: state.auth.user?.primaryLocation || state.location.currentLocation?._id,
    userId: state.auth.user?._id as string,
    location: state.location.currentLocation,
    verified: state.auth.user?.verified,
    is12H: state.location.currentLocation?.is12H,
  }));

  const timeFormat = useMemo(() => getTimeFormat(is12H), [is12H]);

  useEffect(() => {
    if (location?._id) {
      dispatch(getSubscriptionRequest(location?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (pathname.includes('/calendar')) {
      if (search.includes('date')) {
        dispatch(setUpDate(search.replace('?date=', '')));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!appContext.ws && userId) {
      const connect = () => {
        const ws: WebSocket = new WebSocket(env.wssUrl + `?userId=${userId}`);

        ws.onclose = () => setTimeout(() => connect(), 1e3);

        ws.onerror = () => ws.close();

        ws.onopen = () => {
          appContext.setContext({ ws });

          setInterval(() => ws.send('ping'), 3e4);

          // @ts-ignore
          ws.onmessage = (event: MessageEvent) => {
            const data: IWssMessage = JSON.parse(event.data);

            if (data.log) dispatch(addLog(data.log));

            switch (data.message) {
              case 'newReservation':
                notification.info({
                  message: t('New reservation'),
                  description: (
                    <div>
                      <p style={{ margin: 0 }}>
                        <b>{t('Service')}</b>: {(data.data.service as IService).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Customer')}</b>: {(data.data.client as IUser).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{moment(data.data.reservedAt).format(`dddd, LL ${timeFormat}`)}</b>
                      </p>
                    </div>
                  ),
                });
                dispatch(wssReservationPush(data.data));
                break;
              case 'newGroupReservation':
                notification.info({
                  message: t('New group reservation'),
                  description: (
                    <div>
                      <p style={{ margin: 0 }}>
                        <b>{t('Service')}</b>: {(data.data.service as IService).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Master')}</b>: {(data.data.master as IMaster).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{moment(data.data.reservedAt).format(`dddd, LL ${timeFormat}`)}</b>
                      </p>
                    </div>
                  ),
                });
                dispatch(wssReservationPush(data.data));
                break;
              case 'updateReservation':
                notification.info({
                  message: t('Reservation update'),
                  description: (
                    <div>
                      <p style={{ margin: 0 }}>
                        <b>{t('Service')}</b>: {(data.data.reservation.service as IService).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Customer')}</b>: {(data.data.reservation.client as IUser).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{moment(data.data.reservation.reservedAt).format(`dddd, LL ${timeFormat}`)}</b>
                      </p>
                    </div>
                  ),
                });
                dispatch(wssReservationUpdate(data.data.reservation, data.data.masterData, userId));
                break;
              case 'updateStatus':
                notification.info({
                  message: t('Update status'),
                  description: (
                    <div>
                      <p style={{ margin: 0 }}>
                        <b>{t('Status')}</b>: {t(data.data.reservation.status)}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Service')}</b>: {(data.data.reservation.service as IService).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Master')}</b>: {(data.data.reservation.master as IService).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Customer')}</b>: {(data.data.reservation.client as IUser).name}
                      </p>
                    </div>
                  ),
                });
                dispatch(wssReservationUpdate(data.data.reservation, data.data.masterData, userId));
                break;
              case 'updateGroupReservationStatus':
                notification.info({
                  message: t('Update status'),
                  description: (
                    <div>
                      <p style={{ margin: 0 }}>
                        <b>{t('Status')}</b>: {t(data.data.reservation.status)}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Service')}</b>: {(data.data.reservation.service as IService).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Master')}</b>: {(data.data.reservation.master as IService).name}
                      </p>
                    </div>
                  ),
                });
                dispatch(wssReservationUpdate(data.data.reservation, data.data.masterData, userId));
                break;
              case 'updateGroupReservation':
                notification.info({
                  message: t('Group reservation update'),
                  description: (
                    <div>
                      <p style={{ margin: 0 }}>
                        <b>{t('Status')}</b>: {t(data.data.reservation.status)}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Service')}</b>: {(data.data.reservation.service as IService).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Master')}</b>: {(data.data.reservation.master as IService).name}
                      </p>
                    </div>
                  ),
                });
                dispatch(wssReservationUpdate(data.data.reservation, data.data.masterData, userId));
                break;

              case 'newFeedback':
                notification.info({
                  message: t('New review'),
                  description: (
                    <div>
                      <p style={{ margin: 0 }}>
                        <b>{t('Master')}</b>: {(data.data.reservation.master as IMaster).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Comment or additional information')}</b>: {(data.data.feedback as IFeedback).comment}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Rating')}</b>: {(data.data.feedback as IFeedback).rating}
                      </p>
                    </div>
                  ),
                });
                break;
              case 'deleteReservation':
                notification.warn({
                  message: t('One of your reservations has been deleted or transferred to another employee.'),
                  description: (
                    <div>
                      <p style={{ margin: 0 }}>
                        <b>{t('Service')}</b>: {(data.data.reservation.service as IService).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{t('Customer')}</b>: {(data.data.reservation.client as IUser).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{moment(data.data.reservation.reservedAt).format(`dddd, LL ${timeFormat}`)}</b>
                      </p>
                    </div>
                  ),
                });
                dispatch(wssReservationRemove(data.data.reservation._id, data.data.reservation.master));
                break;
              case 'deleteGroupReservation':
                notification.warn({
                  message: t('One of your reservations has been deleted or transferred to another employee.'),
                  description: (
                    <div>
                      <p style={{ margin: 0 }}>
                        <b>{t('Service')}</b>: {(data.data.reservation.service as IService).name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <b>{moment(data.data.reservation.reservedAt).format(`dddd, LL ${timeFormat}`)}</b>
                      </p>
                    </div>
                  ),
                });
                dispatch(wssReservationRemove(data.data.reservation._id, data.data.reservation.master));
                break;
              default:
                break;
            }
          };
        };
      };
      connect();
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (!locationId) {
      history.push('/create-location');
    }
  }, [history, locationId]);

  const handleResend = () => {
    resendRegistrationConfirmation()
      .then(() => message.success(t('The registration confirmation email has been resent successfully.')))
      .catch(console.error);
  };

  return (
    <FullLayout>
      <Layout>
        <AppSidebar collapsed={collapsed} onToggle={() => setCollapsed(!collapsed)} />
        <MainContentLayout style={{ marginLeft: collapsed || window.innerWidth <= 800 ? 0 : 260 }}>
          <AppHeader onBurgerClick={() => setCollapsed(true)} />
          <MainContent style={collapsed ? { borderTopLeftRadius: '4px' } : {}}>
            {!verified && (
              <Alert
                className="mb-4"
                description={t('Your account is not confirmed. Please check your email.')}
                action={<Button size="small" type="primary" onClick={handleResend}>{t('Resend')}</Button>}
              />
            )}
            {/* {!loading &&
              !subscriptions?.filter(
                (item: any) =>
                  item?.subscriptionFromPaddle.state === 'trialing' || item?.subscriptionFromPaddle.state === 'active'
              )?.length && (
                <Alert
                  style={{
                    marginBottom: '24px',
                    //   // padding: "24px",
                    minHeight: 'auto',
                    //   background: "white",
                  }}
                  message={t('No active subscription')}
                  description={
                    <Row justify="space-between">
                      {t('You have no active subscription plan. Please choose any plan.')}.
                      {!window.location.pathname.includes('/billing') && (
                        <Link to="/billing">
                          <Button type="primary">{t('Activate the account')}</Button>
                        </Link>
                      )}
                    </Row>
                  }
                  type="warning"
                  showIcon
                />
              )} */}

            {children}
          </MainContent>
        </MainContentLayout>
      </Layout>
    </FullLayout>
  );
};
