import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row, Statistic } from 'antd';

type Props = {};
const Analytics: React.FC<Props> = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/analytics') history.push('/analytics/clients');
    // eslint-disable-next-line
  }, []);

  return <div />;
};

export default Analytics;

export const FlexedCol = styled(Col)`
  display: flex;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;

export const StyledStatistic = styled(Statistic)`
  margin-right: 16px;
`;
