import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Skeleton, Spin, Table } from 'antd';
import RangePickerAnalytics from './components/RangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/app-context';
import { exportAnalyticsData, servicesRequest } from '../../store/analytics/actions';
import { clearStatistics } from '../../store/location/actions';
import { tableXScroll } from '../../const';
import { FlexedCol, StyledRow } from './Analytics';
import { ANALYTICS_SERVICE_COLUMNS } from './constants';
import { createBreadcrumb } from '../../store/app/utils';
import { setBreadcrumbs } from '../../store/app/actions';

type Props = {};
const ServiceAnalytics: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mainContainerHeight } = useContext(AppContext);

  const { loading, records, locationId } = useSelector((state: IApplicationState) => ({
    records: state.analytics.records,
    loading: state.analytics.loading,
    locationId: state.location.currentLocation?._id,
  }));

  const [state, setState] = useState({
    rangeDates: [moment().startOf('month'), moment(new Date())] as any,
  });

  useEffect(() => {
    dispatch(setBreadcrumbs([
      createBreadcrumb(t('Analytics'), '/analytics'),
      createBreadcrumb(t('Services')),
    ]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    if (!(state.rangeDates && state.rangeDates.length === 2)) {
      dispatch(clearStatistics());
      return;
    }
    if (!locationId) {
      return;
    }
    dispatch(
      servicesRequest(
        {
          from: state.rangeDates[0].toString(),
          to: state.rangeDates[1].toString(),
        },
        locationId
      )
    );
    // eslint-disable-next-line
  }, [state.rangeDates, locationId]);

  const mostPopular = useMemo(() => records.sort((a: any, b: any) => b.count - a.count).filter((record: any) => record.service), [records]);

  const serviceColumns = useMemo(() => ANALYTICS_SERVICE_COLUMNS(t), [t]);

  const handleExport = () => {
    if (state.rangeDates && state.rangeDates.length === 2 && locationId) {
      dispatch(
        exportAnalyticsData(
          state.rangeDates[0].toString(),
          state.rangeDates[1].toString(),
          'services',
          locationId as string
        )
      );
    }
  };

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <RangePickerAnalytics onDataChange={(dates: any) => setState({ ...state, rangeDates: dates })} />
        <StyledRow gutter={16}>
          <FlexedCol span={24}>
            {loading ? (
              <Skeleton />
            ) : (
              <React.Fragment>
                {/*<StyledStatistic title={t('The most popular service')} value={mostPopular && mostPopular[0] ? mostPopular[0].name : '-'} />*/}
                {/*<Statistic title={t('Number of orders')} value={mostPopular && mostPopular[0] ? mostPopular[0].count : '-'} />*/}
              </React.Fragment>
            )}
          </FlexedCol>
          <Col span={24}>
            <Table
              scroll={{ x: tableXScroll, y: mainContainerHeight }}
              size={'small'}
              rowKey={(record: any) => record.service._id}
              columns={serviceColumns}
              dataSource={mostPopular}
            />
          </Col>
        </StyledRow>

        <Button className={'mobile-left-margin'} onClick={handleExport}>
          {t('Export filtered data')}
        </Button>
      </Spin>
    </Wrapper>
  );
};

export default ServiceAnalytics;

const Wrapper = styled.div`
  width: fit-content;
  margin: var(--large);
  display: flex;
  flex-direction: column;
  width: auto;
  padding: var(--small);
`;
