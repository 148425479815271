import { Col } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StatisticsCard, StatisticsElementInterface } from './StatisticsCard/StatisticsCard';

export const Statistics = (props: StatisticsProps) => {
  const { t } = useTranslation();

  const colSpan = useMemo(() => 24 / props.cardsPerRow, [props.cardsPerRow]);

  return (
    <>
      <Col span={colSpan}>
        <StatisticsCard title={t('Average revenue')} data={props.statistics.locationAverageRevenue} />
      </Col>
      <Col span={colSpan}>
        <StatisticsCard title={t('Bookings on average')} data={props.statistics.locationReservationsQuantity} />
      </Col>
      <Col span={colSpan}>
        <StatisticsCard title={t('Average bill')} data={props.statistics.locationAverageCheck} />
      </Col>
      <Col span={colSpan}>
        <StatisticsCard title={t('Average occupancy')} unit="%" data={props.statistics.locationReservationWorkload} />
      </Col>
    </>
  );
};

interface StatisticsProps {
  cardsPerRow: number;
  statistics: StatisticsInterface;
}

export interface StatisticsInterface {
  locationAverageRevenue: StatisticsElementInterface[];
  locationReservationsQuantity: StatisticsElementInterface[];
  locationAverageCheck: StatisticsElementInterface[];
  locationReservationWorkload: StatisticsElementInterface[];
};
