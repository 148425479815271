import RequestService from './request-service';
import { AxiosResponse } from 'axios';
import { IMaster } from '../models/IUser';

export default class AuthService extends RequestService {
  public logout = (token: string): Promise<AxiosResponse> => this.instance.post('logout', { token });
}

export const administratorPermissionsRoles = ['admin', 'owner', 'manager', 'developer', 'marketer'];

export const hasAdministratorPermissions = (user: IMaster): boolean =>
  user ? administratorPermissionsRoles.includes(user.role) : false;
