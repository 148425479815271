import { Checkbox, Col, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../../models/IApplicationState';

export const GeneralSettingsStepForm = (props: GeneralSettingsStepFormProps) => {
  const { t } = useTranslation();

  const locations = useSelector((state: IApplicationState) => state.location.locations);
  const [allLocationsSelected, setAllLocationsSelected] = useState(false);

  useEffect(() => void setAllLocationsSelected(props.model.locations.length === locations.length), [props.model.locations, locations]);

  const handleWidgetTypeChange = (type: string) => props.onChange((state: any) => ({ ...state, type }));

  const handleLocationsChange = (locationIds: any) => {
    setAllLocationsSelected(locationIds.length === locations.length);
    props.onChange((state: any) => ({ ...state, locations: locationIds }));
  };

  const handleAddAllSelected = () => {
    setAllLocationsSelected(selected => !selected);
    const locationIds = locations.map(location => location._id);
    props.onChange((state: any) => ({ ...state, locations: locationIds }))
    props.onFormChange({ locations: locationIds });
  };

  return (
    <Col span={24}>
      <Form.Item
        label={t('Where do you want to connect your widget?')}
        name="type"
        rules={[{ required: true, message: t('Required field') }]}
      >
        <Select className="w-full" placeholder={t('Select a widget type')} onChange={handleWidgetTypeChange}>
          <Select.Option value="website">{t('Website (Personal, Corporate, Tilda, Wix and etc.)')}</Select.Option>
          <Select.Option value="social-media">{t('Social media or messenger (Instagram, Facebook, Telegram and etc.)')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Required field') }]}
        name="locations"
        label={t('Branches')}
      >
        <Select
          className="w-full"
          mode="multiple"
          placeholder={t('Select branches')}
          onChange={handleLocationsChange}
        >
          {locations.map((location) => (
            <Select.Option key={location._id} value={location._id}>
              {location.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Checkbox checked={allLocationsSelected} onChange={handleAddAllSelected}>{t('Add all')}</Checkbox>
      </Form.Item>
    </Col>
  );
};

interface GeneralSettingsStepFormProps {
  model: any;
  onChange: (state: any) => void;
  onFormChange: (values: any) => void;
}
