import { BookOutlined, HomeOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import { CreateLocationContext } from './create-location-context';
import { useDispatch, useSelector } from 'react-redux';
import { createLocationRequest } from '../../store/location/actions';
import { ILocationInput } from '../../models/ILocation';
import { NEW_LOCATION_DATA } from '../../const';
import { IApplicationState } from '../../models/IApplicationState';
import { updateMasterRequest } from '../../store/master/actions';
import { IMasterUpdateInput } from '../../models/IUser';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
const { Title } = Typography;

const Wrapper = styled.div``;

type Props = {
  prev: () => void;
};
const DoneStep: React.FC<Props> = ({ prev }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const createLocationContext = useContext(CreateLocationContext);

  const { isLocationPresent, userId } = useSelector((state: IApplicationState) => ({
    isLocationPresent: state.location.currentLocation !== null,
    userId: state.auth.user?._id,
  }));

  const createLocation = () => {
    if (!isLocationPresent) {
      dispatch(
        updateMasterRequest(
          {
            workingHours: createLocationContext.secondStep.workingHours,
          } as IMasterUpdateInput,
          userId as string
        )
      );
    }

    const location: ILocationInput = {
      ...createLocationContext.firstStep,
      coordinate: createLocationContext.coordinate,
      ...createLocationContext.secondStep,
      utcOffset: moment().utcOffset(),
    } as ILocationInput;
    localStorage.removeItem(NEW_LOCATION_DATA);
    dispatch(createLocationRequest(location));
  };

  const handleSetAsStartPage = () => {
    if ((document.body as unknown as any).setHomePage) {
      (document.body.style as unknown as any).behavior = 'url(#default#homepage)';
      (document.body as unknown as any).setHomePage('https://app.skeedee.com');
    }
  };

  const handleAddToBookmarks = () => (window.external as any)?.addFavorite('https://app.skeedee.com', 'Skeedee');

  return (
    <Wrapper>
      <Title>h1. Ant Design</Title>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam esse, illum magnam obcaecati quisquam sunt
        temporibus voluptatibus. Adipisci commodi, deserunt dolor doloremque eos est eveniet maiores, nisi quisquam
        ullam vel!
      </p>
{/* 
      <div className="text-right mb-3">
        <Button
          className="mr-2"
          icon={<HomeOutlined />}
          shape="round"
          type="primary"
          onClick={handleSetAsStartPage}
        >
          {t('Set as start page')}
        </Button>
        <a href="https://app.skeedee.com" rel="sidebar">
          <Button
            icon={<BookOutlined />}
            shape="round"
            type="primary"
            onClick={handleAddToBookmarks}
          >
            {t('Add to bookmarks')}
          </Button>
        </a>
      </div> */}

      <div className="text-right">
        <Button className="mr-2" onClick={prev}>{t('Back')}</Button>
        <Button type="primary" onClick={createLocation}>{t('Done')}</Button>
      </div>
    </Wrapper>
  );
};

export default DoneStep;
