import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { AuthReducer } from './auth/reducer';
import { LocationReducer } from './location/reducer';
import { MasterReducer } from './master/reducer';
import { ServiceReducer } from './service/reducer';
import { DiscountReducer } from './discount/reducer';
import { UserReducer } from './user/reducer';
import { ReservationReducer } from './reservation/reducer';
import { LogsReducer } from './log/reducer';
import { AnalyticsReducer } from './analytics/reducer';
import { SubscriptionReducer } from './subscription/reducer';
import { AppReducer } from './app/reducer';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: AppReducer,
    auth: AuthReducer,
    location: LocationReducer,
    master: MasterReducer,
    service: ServiceReducer,
    discount: DiscountReducer,
    user: UserReducer,
    reservation: ReservationReducer,
    log: LogsReducer,
    analytics: AnalyticsReducer,
    subscriptions: SubscriptionReducer,
  });

export default rootReducer;
