import { IMaster } from '../../models/IUser';
import { IFeedback } from '../../models/IFeedback';

export interface MasterState {
  readonly loading: boolean;
  readonly feedbacks: IFeedback[];
  readonly currentMaster: IMaster | null;
  readonly error: string | null;
  readonly totalFeedback: number;
  readonly masters: IMaster[];
  readonly total: number;
}

export enum MasterActionTypes {
  SEARCH_MASTERS_REQUEST = '@@master/SEARCH_MASTERS_REQUEST',
  SEARCH_MASTERS_SUCCESS = '@@master/SEARCH_MASTERS_SUCCESS',

  MASTER_INFO_REQUEST = '@@master/MASTER_INFO_REQUEST',
  MASTER_INFO_SUCCESS = '@@master/MASTER_INFO_SUCCESS',

  DELETE_MASTER_REQUEST = '@@master/DELETE_MASTER_REQUEST',
  DELETE_MASTER_SUCCESS = '@@master/DELETE_MASTER_SUCCESS',

  DELETE_FEEDBACK_REQUEST = '@@master/DELETE_FEEDBACK_REQUEST',
  DELETE_FEEDBACK_SUCCESS = '@@master/DELETE_FEEDBACK_SUCCESS',

  UPDATE_MASTER_REQUEST = '@@master/UPDATE_MASTER_REQUEST',

  MASTER_REQUEST_FAILURE = '@@master/MASTER_REQUEST_FAILURE',

  CLEAN_ERRORS = '@@master/CLEAN_ERRORS',
}
