import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeForm from '../../components/EmployeeForm/EmployeeForm';
import MasterFeedback from '../../components/MasterFeedback/MasterFeedback';
import { IApplicationState } from '../../models/IApplicationState';
import { IMaster } from '../../models/IUser';
import { setBreadcrumbs } from '../../store/app/actions';
import { createBreadcrumb } from '../../store/app/utils';
import { logoutRequest } from '../../store/auth/actions';
import { masterInfoRequest } from '../../store/master/actions';
import { NotificationSettings } from './NotificationSettings/NotificationSettings';
import { PasswordChange } from './PasswordChange/PasswordChange';

export const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { master, userId } = useSelector((state: IApplicationState) => ({
    master: state.master.currentMaster as IMaster,
    userId: state.auth.user?._id as string,
  }));

  useEffect(() => {
    dispatch(setBreadcrumbs([createBreadcrumb(t('Profile'))]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(masterInfoRequest(userId));
  }, [userId, dispatch]);

  const handleTabClick = (key: string, event: any) => {
    if (key === 'logout') {
      dispatch(logoutRequest());
    }
  };

  return (
    <div>
      <Tabs defaultActiveKey="profile" onTabClick={handleTabClick}>
        <Tabs.TabPane tab={t('Profile')} key="profile">
          <EmployeeForm master={master} profile />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Notifications')} key="notifications">
          <NotificationSettings />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Reviews')} key="reviews">
          <MasterFeedback />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Change password')} key="change-password">
          <PasswordChange />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Logout')} key="logout" />
      </Tabs>
    </div>
  );
};
