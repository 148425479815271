import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { Button, Col, Popconfirm, Rate, Row, Statistic, Table } from 'antd';
import { minTableCellWidth, tableXScroll } from '../../const';
import { AppContext } from '../../context/app-context';
import { IFeedback } from '../../models/IFeedback';
import { useTranslation } from 'react-i18next';
import { deleteFeedbackRequest } from '../../store/master/actions';
import { hasAdministratorPermissions } from '../../services/auth-service';

type Props = {};
const MasterFeedback: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
  });
  const { mainContainerHeight } = useContext(AppContext);

  const { total, feedbacks, loading, authenticatedUser } = useSelector((state: IApplicationState) => ({
    total: state.master.currentMaster?.total as number,
    currentMaster: state.master.currentMaster,
    authenticatedUser: state.auth.user,
    feedbacks: state.master.feedbacks,
    loading: state.master.loading,
  }));

  useEffect(() => {
    if (total) {
      setPagination({ ...pagination, total });
    }
    // eslint-disable-next-line
  }, [total]);

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const columns: any = FEEDBACK_COLUMNS(t).map(column => ({ ...column, title: t(column.title) }));

  if (authenticatedUser && hasAdministratorPermissions(authenticatedUser)) {
    columns.push({
      title: t('Delete'),
      width: minTableCellWidth,
      render: (item: IFeedback) => (
        <Popconfirm
          title={t('Are you sure you want to delete this review?')}
          onConfirm={() => dispatch(deleteFeedbackRequest(item._id))}
          okText={t('Yes')}
          cancelText={t('No')}
        >
          <Button danger>{t('Delete')}</Button>
        </Popconfirm>
      ),
    });
  }

  const avrVote =
    feedbacks.length !== 0 ? feedbacks.map((feedback) => feedback.rating).reduce((total, num: any) => total + num) : 0;

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col className={'mobile-left-padding'} span={12}>
          <Statistic className={'mobile-left-padding'} title={t('Number of reviews')} value={feedbacks.length} />
        </Col>
        <Col span={12}>
          <Statistic title={t('Average rating')} value={avrVote !== 0 ? avrVote / feedbacks.length : 0} precision={1} />
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ marginTop: 16 }}>
          <Table
            scroll={{ x: tableXScroll, y: mainContainerHeight }}
            size="small"
            loading={loading}
            onChange={handleTableChange}
            rowKey={(item: IFeedback) => item._id}
            pagination={pagination}
            columns={columns}
            dataSource={feedbacks}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MasterFeedback;

const FEEDBACK_COLUMNS = (t: any) => [
  {
    title: t('Rating'),
    dataIndex: 'rating',
    width: minTableCellWidth,
    render: (rating: number) => {
      return (
        <>
          <Rate disabled defaultValue={rating} /> {rating}
        </>
      );
    },
  },
  {
    title: t('Review'),
    dataIndex: 'comment',
    width: minTableCellWidth,
  },
];