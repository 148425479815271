import { AnalyticsTypes } from './types';
import { IQuery } from '../../models/IQuery';

type Error = string;
type Period = { from: Date; to: Date };

export const clientsRequest = (locationId: string, pagination: IQuery, queryData: any) => ({
  type: AnalyticsTypes.CLIENTS_REQUEST,
  payload: {
    locationId,
    pagination,
    queryData,
  },
});
export const clientsSuccess = (records: any, total: number) => ({
  type: AnalyticsTypes.CLIENTS_SUCCESS,
  payload: { records, total },
});
export const clientsFailure = (data: Error) => ({
  type: AnalyticsTypes.CLIENTS_FAILURE,
  payload: data,
});

export const exportAnalyticsData = (from: string, to: string, table: string, locationId: string, queryData?: any) => ({
  type: AnalyticsTypes.EXPORT_DATA,
  payload: { from, to, table, locationId, queryData },
});

export const mastersRequest = (period: Period, locationId: string) => ({
  type: AnalyticsTypes.MASTERS_REQUEST,
  payload: { ...period, locationId },
});

export const mastersSuccess = (records: any, total: number, moreData: any) => ({
  type: AnalyticsTypes.MASTERS_SUCCESS,
  payload: { records, total, moreData },
});

export const mastersFailure = (e: any) => ({
  type: AnalyticsTypes.MASTERS_FAILURE,
});

export const servicesRequest = (period: Period, locationId: string) => ({
  type: AnalyticsTypes.SERVICES_REQUEST,
  payload: { ...period, locationId },
});

export const servicesSuccess = (records: any) => ({
  type: AnalyticsTypes.SERVICES_SUCCESS,
  payload: records,
});

export const servicesFailure = (e: any) => ({
  type: AnalyticsTypes.SERVICES_FAILURE,
});

export const cleanAnalytics = () => ({
  type: AnalyticsTypes.CLEAN,
});
