import { Col, Form, Input, Popover, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { SwatchesPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { BUTTON_STYLES } from '../../constants';

export const ButtonSettingsStepForm = (props: ButtonSettingsStepFormProps) => {
  const { t } = useTranslation();

  const [buttonColors, setButtonColors] = useState<string[]>(props.model.buttonColors);
  const [buttonStyle, setButtonStyle] = useState(props.model.buttonStyle);
  const [buttonText, setButtonText] = useState<string>(props.model.buttonText);

  const changeButtonColorSchema = (buttonStyle: string, buttonColors: string[]) => {
    const buttonIndex = BUTTON_STYLES.findIndex(({ name }) => name === buttonStyle);
    for (const colorIndex in buttonColors) {
      const rootVar = +colorIndex ? `--swb${buttonIndex + 1}-bgcolor${colorIndex}` : `--swb${buttonIndex + 1}-font-color`;
      document.documentElement.style.setProperty(rootVar, buttonColors[colorIndex]);
    }
  };

  useEffect(() => void (buttonStyle && buttonColors.length &&
    changeButtonColorSchema(buttonStyle, buttonColors)), [buttonStyle, buttonColors]);

  useEffect(() => void props.onChange((state: any) => ({ ...state, buttonColors })), [buttonColors]);

  const handleButtonStyleChange = (buttonStyle: string) => {
    setButtonColors(BUTTON_STYLES.find(style => style.name === buttonStyle)!.defaultColors);
    setButtonStyle(buttonStyle);
    props.onChange((state: any) => ({ ...state, buttonStyle }));
  };

  const handleButtonColorSelect = (color: string, index: number) => {
    setButtonColors(buttonColors => {
      const colors = [...buttonColors];
      colors.splice(index, 1, color);
      return colors;
    });
  };

  const handleButtonTextChange = (event: any) => {
    const buttonText = event.target.value;
    setButtonText(buttonText);
    props.onChange((state: any) => ({ ...state, buttonText }));
  };

  return (
    <Col span={24}>
      <Form.Item label={t('Button style')} name="buttonStyle" rules={[{ required: true, message: t('Required field') }]}>
        <Select placeholder={t('Select button type')} onChange={handleButtonStyleChange}>
          {BUTTON_STYLES.map(buttonStyle => (
            <Select.Option key={buttonStyle.name} value={buttonStyle.name}>
              {buttonStyle.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {buttonStyle && (
        <Form.Item label={t('Button color scheme')}>
          <div className="flex">
            {buttonColors.map((color, index) => (
              <Popover
                key={index}
                overlayClassName="button-color-popover"
                content={<SwatchesPicker color={color} onChange={color => handleButtonColorSelect(color.hex, index)} />}
                trigger="click"
              >
                <button className="button-color" style={{ background: color }}></button>
              </Popover>
            ))}
          </div>
        </Form.Item>
      )}
      <Form.Item
        label={t('Button text')}
        name="buttonText"
        rules={[
          { required: true, message: t('Required field') },
          { max: 28, message: t('The maximum length of the button text is {{quantity}} characters.', { quantity: 28 }) },
        ]}
      >
        <Input
          autoComplete="off"
          placeholder={t('Enter button text')}
          onChange={handleButtonTextChange}
        />
      </Form.Item>
      {buttonStyle && (
        <Col className="widget-button-slot" span={24}>
          <button className={`skeedee--widget-button--${buttonStyle}`}>
            <span>{buttonText}</span>
          </button>
        </Col>
      )}
    </Col>
  );
};

interface ButtonSettingsStepFormProps {
  model: any;
  onChange: (state: any) => void;
}
