import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Table,
  Tooltip,
  DatePicker,
  Card,
  Select,
  Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import moment from 'moment';
import { minTableCellWidth, tableXScroll } from '../../const';
import { IUser } from '../../models/IUser';
import { AppContext } from '../../context/app-context';
import { useTranslation } from 'react-i18next';
import { clientsRequest, exportAnalyticsData } from '../../store/analytics/actions';
import { UsergroupAddOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { cleanObject, copyToClipboard, getTimeFormat } from '../../services/utils';
import { setBreadcrumbs } from '../../store/app/actions';
import { createBreadcrumb } from '../../store/app/utils';

const SearchBottomRow = styled(Col)`
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const Wrapper = styled.div``;

type Props = {};
const ClientAnalytics: React.FC<Props> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { mainContainerHeight } = useContext(AppContext);
  const dispatch = useDispatch();

  const [queryData, setQueryData] = useState({
    query: {} as any,
    sort: {} as any,
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
  });

  const { loading, records, total, locationId, is12H } = useSelector((state: IApplicationState) => ({
    locationId: state.location.currentLocation?._id,
    loading: state.analytics.loading,
    records: state.analytics.records,
    total: state.analytics.total,
    is12H: state.location.currentLocation?.is12H,
  }));

  const timeFormat = useMemo(() => getTimeFormat(is12H), [is12H]);

  const filteredRecords = useMemo(() => records.filter((record: any) => record._id), [records]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      createBreadcrumb(t('Analytics'), '/analytics'),
      createBreadcrumb(t('Clients')),
    ]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  useEffect(() => {
    form.setFieldsValue({
      filterBy: 'bout',
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setPagination({ ...pagination, total });
    // eslint-disable-next-line
  }, [loading, total, records]);

  useEffect(() => {
    if (locationId) {
      dispatch(
        clientsRequest(
          locationId as string,
          {
            offset: pagination.pageSize * (pagination.current - 1),
            limit: pagination.pageSize,
          },
          queryData
        )
      );
    }
    // eslint-disable-next-line
  }, [locationId]);

  const tableValue = (val: any) => (
    <div
      onClick={() => {
        copyToClipboard(val);
        message.success(`${t('Copied')}: ${val}`);
      }}
    >
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    </div>
  );
  const navigationHandler = (path: string) => history.push(path);

  const selectUser = (user: IUser) => {
    navigationHandler(`/clients/client?userId=${user._id}`);
  };

  const columns: any = [
    {
      title: t('Operations'),
      key: 'operation',
      width: minTableCellWidth,
      render: (user: IUser) => (
        <React.Fragment>
          <Button onClick={() => selectUser(user)} type={'link'}>
            {t('Profile')}
          </Button>
        </React.Fragment>
      ),
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      width: minTableCellWidth,
      render: tableValue,
    },
    {
      title: t('Phone'),
      width: minTableCellWidth,
      render: (item: any) => tableValue(`${item.countryCode} ${item.phone}`),
    },
    {
      title: t('Count'),
      width: minTableCellWidth,
      dataIndex: 'totalReservations',
      sorter: (a: any, b: any) => a.totalReservations - b.totalReservations,
      render: (totalReservations: number) => (totalReservations === 0 ? '-' : totalReservations),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('The average amount'),
      width: minTableCellWidth,
      dataIndex: 'average',
      sorter: (a: any, b: any) => a.average - b.average,
      sortDirections: ['descend', 'ascend'],
      render: (average: number) => (average === null ? '-' : Number(average).toFixed(2)),
    },
    {
      title: t('Total spent'),
      width: minTableCellWidth,
      dataIndex: 'totalSpend',
      sorter: (a: any, b: any) => a.totalSpend - b.totalSpend,
      sortDirections: ['descend', 'ascend'],
      render: (totalSpend: number) => (totalSpend === 0 ? '-' : Number(totalSpend).toFixed(2)),
    },
    {
      title: t('Last reservation'),
      width: minTableCellWidth,
      dataIndex: 'lastReservation',
      sorter: (a: any, b: any) =>
        a.lastReservation ? moment(a.lastReservation).isAfter(moment(b.lastReservation)) : false,
      render: (date: Date) => (date ? moment(date).format(`LL ${timeFormat}`) : '-'),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('First reservation'),
      width: minTableCellWidth,
      dataIndex: 'firstReservation',
      sorter: (a: any, b: any) =>
        a.lastReservation ? moment(a.firstReservation).isAfter(moment(b.firstReservation)) : false,
      render: (date: Date) => (date ? moment(date).format(`LL ${timeFormat}`) : '-'),
      sortDirections: ['descend', 'ascend'],
    },

    // {
    //   title: (
    //     <span>
    //       {t('Total reservations')} <UsergroupAddOutlined />
    //     </span>
    //   ),
    //   width: minTableCellWidth,
    //   dataIndex: 'totalGroupReservations',
    //   sorter: (a: any, b: any) => a.totalReservations - b.totalReservations,
    //   render: (totalReservations: number) => (totalReservations === 0 ? '-' : totalReservations),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: (
    //     <span>
    //       {t('The average amount')} <UsergroupAddOutlined />
    //     </span>
    //   ),
    //   width: minTableCellWidth,
    //   dataIndex: 'averageGroup',
    //   sorter: (a: any, b: any) => a.average - b.average,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (average: number) => (average === null ? '-' : Number(average).toFixed(2)),
    // },
    // {
    //   title: (
    //     <span>
    //       {t('Total spent')} <UsergroupAddOutlined />
    //     </span>
    //   ),
    //   width: minTableCellWidth,
    //   dataIndex: 'totalGroupSpend',
    //   sorter: (a: any, b: any) => a.totalSpend - b.totalSpend,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (totalSpend: number) => (totalSpend === 0 ? '-' : Number(totalSpend).toFixed(2)),
    // },
    // {
    //   title: (
    //     <span>
    //       {t('Last reservation')} <UsergroupAddOutlined />
    //     </span>
    //   ),
    //   width: minTableCellWidth,
    //   dataIndex: 'lastGroupReservation',
    //   sorter: (a: any, b: any) =>
    //     a.lastReservation ? moment(a.lastReservation).isAfter(moment(b.lastReservation)) : false,
    //   render: (date: Date) => (date ? moment(date).format(`LL ${timeFormat}`) : '-'),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: (
    //     <span>
    //       {t('First reservation')} <UsergroupAddOutlined />
    //     </span>
    //   ),
    //   width: minTableCellWidth,
    //   dataIndex: 'firstGroupReservation',
    //   sorter: (a: any, b: any) =>
    //     a.lastReservation ? moment(a.firstReservation).isAfter(moment(b.firstReservation)) : false,
    //   render: (date: Date) => (date ? moment(date).format(`LL ${timeFormat}`) : '-'),
    //   sortDirections: ['descend', 'ascend'],
    // },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let query: any = {};

    let sort: any = {
      [sorter.field]: sorter.order === 'descend' ? -1 : 1,
    };

    if (!sorter.order) sort = {};

    setQueryData({ sort, query });
    setPagination(pagination);
    dispatch(
      clientsRequest(
        locationId as string,
        {
          offset: pagination.pageSize * (pagination.current - 1),
          limit: pagination.pageSize,
        },
        { sort, query }
      )
    );
  };

  const handleExport = () => {
    if (locationId) {
      dispatch(
        exportAnalyticsData(new Date().toString(), new Date().toString(), 'clients', locationId as string, queryData)
      );
    }
  };

  const onFinish = (values: any) => {
    values.reservations = cleanObject(values.reservations);
    values.totalSpend = cleanObject(values.totalSpend);
    cleanObject(values);

    if (values.range) {
      values.range = {
        $gte: values.range[0].toISOString(),
        $lte: values.range[1].toISOString(),
      };
    }

    setQueryData({ ...queryData, query: values });

    dispatch(
      clientsRequest(
        locationId as string,
        {
          offset: 0,
          limit: pagination.pageSize,
        },
        { ...queryData, query: values }
      )
    );
  };
  return (
    <Wrapper>
      <Card>
        <Form form={form} layout={'vertical'} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={window.innerWidth <= 800 ? 24 : 8}>
              <Form.Item name={'range'} label={t('Statistics period')}>
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  ranges={{
                    [t('This month')]: [moment().startOf('month'), moment().endOf('month')],
                    [t('This year')]: [moment().startOf('year'), moment().endOf('year')],
                  } as any}
                ></DatePicker.RangePicker>
              </Form.Item>
            </Col>

            <Col span={window.innerWidth <= 800 ? 12 : 8}>
              <Form.Item label={t('Number of reservations')}>
                <Input.Group compact>
                  <Form.Item name={['reservations', '$gte']} noStyle>
                    <InputNumber style={{ width: '50%' }} className="no-arrows-input" placeholder="Min" />
                  </Form.Item>
                  <Form.Item name={['reservations', '$lte']} noStyle>
                    <InputNumber style={{ width: '50%' }} className="no-arrows-input" placeholder="Max" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col span={window.innerWidth <= 800 ? 12 : 8}>
              <Form.Item label={t('Total spent')}>
                <Input.Group compact>
                  <Form.Item name={['totalSpend', '$gte']} noStyle>
                    <InputNumber style={{ width: '50%' }} className="no-arrows-input" placeholder="Min" />
                  </Form.Item>
                  <Form.Item name={['totalSpend', '$lte']} noStyle>
                    <InputNumber style={{ width: '50%' }} className="no-arrows-input" placeholder="Max" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            {/* <Col span={window.innerWidth <= 800 ? 12 : 8}>
              <Form.Item name={'filterBy'} label={t('Filter by')}>
                <Select>
                  <Select.Option value="single">
                    <UserOutlined /> {t('Single reservations')}
                  </Select.Option>
                  <Select.Option value="group">
                    <UsergroupAddOutlined /> {t('Group reservations')}
                  </Select.Option>
                  <Select.Option value="bout">
                    <UserOutlined /> + <UsergroupAddOutlined /> {t('Both')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col> */}

            <Col span={window.innerWidth <= 800 ? 12 : 8}>
              <Form.Item label={t('Without reservations')} name="noReservations" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>

            <SearchBottomRow span={window.innerWidth <= 800 ? 24 : 8}>
              <div>
                {t('Total customers')} : {total}
              </div>

              <Button loading={loading} type="primary" htmlType="submit">
                <SearchOutlined />
                {t('Search')}
              </Button>
            </SearchBottomRow>
          </Row>
          <Row gutter={16} style={{ marginTop: 24 }}>
            <Col span={24}>
              <Button onClick={handleExport}>{t('Export filtered data')}</Button>
            </Col>
          </Row>
        </Form>
      </Card>

      <Row>
        <Col span={24} style={{ marginTop: 16 }}>
          <Table
            scroll={{ x: tableXScroll, y: mainContainerHeight }}
            size="small"
            loading={loading}
            onChange={handleTableChange}
            rowKey={(item: IUser) => item._id}
            pagination={pagination}
            columns={columns}
            dataSource={filteredRecords}
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ClientAnalytics;
