import styled from 'styled-components';
import { Layout } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export * from './FormElements';

export const Title = styled.h1`
  margin-top: var(--small);
`;
export const FullLayout = styled(Layout)`
  min-height: 100vh;
`;

export const MainContent = styled(Layout.Content)`
  background: white;
  margin: 0;
  min-height: auto;
  padding: 24px;
  transition: border-radius 0.2s;
`;

export const MainContentLayout = styled(Layout)`
  background: transparent;
  height: auto;
  transition: margin-left 0.2s;
`;

export const FlexCentered = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 64px;
  margin: 10% auto 50px auto;
  display: block;
`;
