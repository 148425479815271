import React, { useState } from 'react';
import styled from 'styled-components';
import { Steps, Card, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import DoneStep from './DoneStep';
import { CreateLocationContextProvider } from './create-location-context';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { Link } from 'react-router-dom';

const { Step } = Steps;

type Props = {};
const CreateLocation: React.FC<Props> = () => {
  const { t } = useTranslation();

  const [current, setCurrent] = useState(0);

  const [firstStepForm] = Form.useForm();
  const [secondStepForm] = Form.useForm();

  const isLocationPresent = useSelector((state: IApplicationState) => state.location.currentLocation !== null);

  const next = () => {
    const nextStep = current <= 2 ? current + 1 : 2;
    setCurrent(nextStep);
  };
  const prev = () => {
    setCurrent(current >= 0 ? current - 1 : 0);
  };

  const steps = [
    {
      Component: FirstStep,
      props: {
        form: firstStepForm,
        next: next,
      },
    },
    {
      Component: SecondStep,
      props: {
        form: secondStepForm,
        next: next,
        prev: prev,
      },
    },
    {
      Component: DoneStep,
      props: {
        prev: prev,
      },
    },
  ];

  const StepComponent = steps[current].Component;
  const props = steps[current].props;
  // @ts-ignore
  const step = <StepComponent {...props} />;
  return (
    <>
      <Wrapper>
        {isLocationPresent && (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button style={{ margin: '0 8px' }} onClick={prev}>
              <Link to={'/dashboard'}>{t('Dashboard')}</Link>
            </Button>
          </div>
        )}
        <Steps type="navigation" current={current}>
          <Step title={t('Main settings')} />
          <Step title={t('Branch settings')} />
          <Step title={t('Done')} />
        </Steps>
        <StyledCard>
          <CreateLocationContextProvider>{step}</CreateLocationContextProvider>
        </StyledCard>
      </Wrapper>
    </>
  );
};

export default CreateLocation;

const Wrapper = styled.div`
  padding-top: 5em;
  margin: 0 auto;
  max-width: 800px;
`;

const StyledCard = styled(Card)`
  margin: 16px 0;
  background-color: #fafafa;
`;
