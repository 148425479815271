import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Col, Form, Input, Rate, Result, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/sharedComponents/Loader';
import { checkFeedback, createFeedback } from '../../services/feedback.service';

type Props = {};

const Feedback: React.FC<Props> = () => {
  const { search } = useLocation();
  const uniqueId = search.replace('?uniqueId=', '');
  const [loading, setLoading] = useState(true);
  const [isPosted, setIsPosted] = useState(false);
  const history = useHistory();

  if (uniqueId === '') {
    history.push('/');
  }

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    checkFeedback(uniqueId).then((res) => {
      if (res && res.data) {
        setLoading(false);
      } else {
        history.push('/');
      }
    });
    // eslint-disable-next-line
  }, []);

  const createComment = (values: any) => {
    setLoading(true);
    createFeedback(uniqueId, values).then((res) => {
      setIsPosted(true);
      setLoading(false);
    });
  };

  if (loading) return <Loader />;

  if (isPosted) {
    return (
      <Wrapper>
        <Result status="success" title={t('Thanks for your review!')} />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Card style={{ width: '450px' }}>
        <Form layout="vertical" onFinish={createComment}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="rating"
                label={t('Rating')}
                rules={[{ required: true, message: t('Required field') }]}
              >
                <Rate allowHalf value={5} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="comment" label={t('Comment or additional information')}>
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button type="primary" htmlType="submit">
              {t('Create')}
            </Button>
          </div>
        </Form>
      </Card>
    </Wrapper>
  );
};

export default Feedback;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 20px;
`;
