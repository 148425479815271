import { Col, Form, Input } from 'antd';
import React from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { WidgetType } from '../../../../models/ILocation';
require('codemirror/mode/htmlmixed/htmlmixed');

export const ResultStepForm = (props: ResultStepFormProps) => (
  <Col span={24}>
    <Form.Item>
      {props.widgetType === WidgetType.Website ? (
        <CodeMirror
          value={props.linkOrCode}
          options={{
            mode: 'htmlmixed',
            lineNumbers: true,
            readOnly: true,
          }}
        />
      ) : (
        <Input value={props.linkOrCode} />
      )}
    </Form.Item>
  </Col>
);

interface ResultStepFormProps {
  linkOrCode: string;
  widgetType: string;
}
