import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import EmployeeForm from '../../../components/EmployeeForm/EmployeeForm';
import { setBreadcrumbs } from '../../../store/app/actions';
import { createBreadcrumb } from '../../../store/app/utils';

export const NewEmployee = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setBreadcrumbs([
      createBreadcrumb(t('Employees'), '/employees'),
      createBreadcrumb(t('Add new employee')),
    ]));
    return () => void dispatch(setBreadcrumbs([]));
  }, [dispatch, t]);

  return <EmployeeForm />;
};
