import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Spin, TimePicker, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import styled from 'styled-components';
import { addServiceRequest, updateServiceRequest } from '../../store/service/actions';
import { IService, IServiceInput, IServiceUpdateInput } from '../../models/IService';
import { IMaster } from '../../models/IUser';
import { splitObject } from '../../services/utils';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { APP_LANG } from '../../const';
import { fetchCategories } from '../../services/categories.service';
import { getServiceCategories } from '../../services/services.service';

type Props = {
  service: IService | null;
  onCloseHandler: () => void;
};
const NewService: React.FC<Props> = ({ service, onCloseHandler }) => {
  const dispatch = useDispatch();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [viewAddMasterInput, setViewAddMasterInput] = useState(true);
  const [isFixedPrice, setIsFixedPrice] = useState(true);
  const [isGroupService, setIsGroupService] = useState(false);
  const [cancelIfNoRequiredClients, setCancelIfNoRequiredClients] = useState(false);
  const [categories, setCategories] = useState<any[]>();
  const [form] = Form.useForm();
  const selectedLang: any = localStorage.getItem(APP_LANG) || 'en';
  const { t } = useTranslation();

  const { loading, locationId, services, masters, currency, subscription } = useSelector((state: IApplicationState) => ({
    loading: state.service.loading,
    services: state.service.services,
    currency: state.location.currentLocation?.currency as string,
    masters: state.location.currentLocation?.masters as IMaster[],
    locationId: state.location.currentLocation?._id as string,
    subscription: state.subscriptions.subscription,
  }));

  const canCreateService = useMemo(() => subscription && subscription.status !== 'canceled' &&
    (subscription.services === 'Unlimited' || services.length < subscription.services), [subscription, services.length]);

  const getCategories = async () => {
    try {
      const categories: any = await getServiceCategories();
      setCategories(categories.data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) onCancelHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (modalVisibility && !service) {
      form.setFieldsValue({ assignToAll: true, active: true });
      setViewAddMasterInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, modalVisibility]);

  useEffect(() => {
    if (service) {
      const formStatement: Record<string, any> = {
        name: service.name,
        duration: moment(new Date().setHours(0, 0)).add(service.duration, 'minutes'),
        // pauseAfter: moment(new Date().setHours(0, 0)).add(service.pauseAfter, 'minutes'),
        description: service.description,
        category: [service.category],
        cancelIfNoRequiredClients: service.cancelIfNoRequiredClients,
        checkBeforeInitIfGroupIsPresentTime: service.checkBeforeInitIfGroupIsPresentTime,
        maxPerson: service.maxPerson,
        minPerson: service.minPerson,
        active: service.active,
      };
      if (service.price.includes('-')) {
        setIsFixedPrice(false);
        const [priceFrom, priceTo] = service.price.split('-');
        formStatement['price-from'] = Number(priceFrom);
        formStatement['price-to'] = Number(priceTo);
      } else {
        formStatement.price = Number(service.price);
      }
      form.setFieldsValue(formStatement);

      if (service.maxPerson !== undefined && 1 < service.maxPerson) {
        setIsGroupService(true);
      } else {
        setIsGroupService(false);
      }
      setModalVisibility(true);
    } else {
      setIsGroupService(false);
    }
    // eslint-disable-next-line
  }, [service]);

  useEffect(() => {
    setModalVisibility(false);
    setIsGroupService(false);
  }, [services.length]);

  const addServiceModalHandler = () => setModalVisibility(true);
  const onCancelHandler = () => {
    if (!loading) {
      setModalVisibility(false);
      setTimeout(() => {
        form.resetFields();
        onCloseHandler();
        setIsGroupService(false);
      }, 500);
    }
  };

  const onFinish = () => {
    const formService = form.getFieldsValue();
    formService.duration = formService.duration.minutes() + formService.duration.hours() * 60;
    // formService.pauseAfter = formService.pauseAfter.minutes() + formService.pauseAfter.hours() * 60;
    formService.checkBeforeInitIfGroupIsPresentTime = formService.checkBeforeInitIfGroupIsPresentTime || 0;

    const [serviceObject, moreField] = splitObject(formService, ['masters', 'assignToAll']);

    if (!isFixedPrice) {
      serviceObject.price = `${serviceObject['price-from']}-${serviceObject['price-to']}`;
      delete serviceObject['price-from'];
      delete serviceObject['price-to'];
    } else {
      serviceObject.price = String(serviceObject.price);
    }

    if (formService.category) {
      serviceObject.category = formService.category[0];
    }

    if (service) {
      dispatch(updateServiceRequest(serviceObject as IServiceUpdateInput, service._id));
    } else {
      dispatch(addServiceRequest(serviceObject as IServiceInput, locationId, moreField));
    }
  };

  const categoriesNames: any[] = services
    .map((s) => (s.category ? s.category : null))
    .filter((s) => s !== null)
    .filter((i, p, s) => s.indexOf(i) === p);

  return (
    <>
      <Button
        className="phone-button-margin-full w-full"
        disabled={!canCreateService}
        type="primary"
        onClick={addServiceModalHandler}
      >
        {t('Add new service')}
      </Button>

      {!modalVisibility && <Form form={form} />}

      <Modal
        title={service ? service.name : t('New service')}
        visible={modalVisibility}
        onCancel={onCancelHandler}
        okText={t('Create')}
        cancelText={t('Cancel')}
        footer={null}
      >
        <Spin spinning={loading}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item name="name" label={t('[Thing] Name')} rules={[{ required: true, message: t('Required field') }]}>
              <Input placeholder={t('Enter service name')} />
            </Form.Item>

            {/* <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={16}>
                <Checkbox
                  checked={isGroupService}
                  defaultChecked={isGroupService}
                  onChange={(event) => setIsGroupService(event.target.checked)}
                >
                  {t('Group reservation')}
                </Checkbox>
              </Col>
            </Row> */}

            {isGroupService && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="minPerson"
                    label={
                      <Tooltip placement="topLeft" title={t('If the group is not collected, the reservation will be canceled.')}>
                        <span>
                          {t('Minimum number')} <InfoCircleOutlined style={{ color: '#1890ff' }} />
                        </span>
                      </Tooltip>
                    }
                    rules={[{ required: true, message: t('Required field') }]}
                  >
                    <InputNumber
                      min={1}
                      placeholder={t('Enter a minimum number of customers')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="maxPerson"
                    label={t('Maximum number')}
                    rules={[{ required: true, message: t('Required field') }]}
                  >
                    <InputNumber
                      min={1}
                      placeholder={t('Enter a maximum number of customers')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item valuePropName="checked" name="cancelIfNoRequiredClients">
                    <Checkbox onChange={(val) => setCancelIfNoRequiredClients(val.target.checked)}>
                      {t('Cancel if the group is not collected')}
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="checkBeforeInitIfGroupIsPresentTime"
                    label={
                      <Tooltip placement="topLeft" title={t('If the group is not collected N days before the start, the reservation will be canceled.')}>
                        <span>
                          {t('When to check the reservation')} <InfoCircleOutlined style={{ color: '#1890ff' }} />
                        </span>
                      </Tooltip>
                    }
                    rules={[
                      {
                        required: cancelIfNoRequiredClients,
                        message: t('Required field'),
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      max={30}
                      placeholder={t('Enter a number of days before the start')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Form.Item valuePropName="checked">
              <Checkbox defaultChecked={isFixedPrice} onChange={(event) => setIsFixedPrice(event.target.checked)}>
                {t('Fixed price')}
              </Checkbox>
            </Form.Item>

            <Row gutter={16}>
              <Col span={24}>
                {!isFixedPrice && (
                  <Form.Item rules={[{ required: true, message: t('Required field') }]}>
                    <Input.Group compact>
                      <RowLabel className="ant-form-item-required">
                        <RowLabelDot>*</RowLabelDot>
                        {t('Price')}
                      </RowLabel>
                      <Row gutter={16} style={{ display: 'flex' }}>
                        <Col span={12}>
                          <Form.Item
                            name="price-from"
                            noStyle
                            label={t('Price')}
                            rules={[{ required: true, message: t('Required field') }]}
                          >
                            <InputNumber
                              // @ts-ignore
                              formatter={(value) => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              // @ts-ignore
                              parser={(value) => value.replace(`${currency} `, '')}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            noStyle
                            name="price-to"
                            label={t('Price')}
                            rules={[{ required: true, message: t('Required field') }]}
                          >
                            <InputNumber
                              // @ts-ignore
                              formatter={(value) => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              // @ts-ignore
                              parser={(value) => value.replace(`${currency} `, '')}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                )}
              </Col>
              <Col span={isFixedPrice ? 12 : 24}>
                <Form.Item
                  name="duration"
                  label={t('Duration')}
                  rules={[{ required: true, message: t('Required field') }]}
                >
                  <TimePicker
                    style={{ width: '100%' }}
                    minuteStep={10}
                    format={'HH:mm'}
                    onSelect={(value) => {
                      form.setFieldsValue({
                        [`duration`]: value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              {isFixedPrice && (
                <Col span={12}>
                  <Form.Item name="price" label={t('Price')} rules={[{ required: true, message: t('Required field') }]}>
                    <InputNumber
                      // @ts-ignore
                      formatter={(value) => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      // @ts-ignore
                      parser={(value) => value.replace(`${currency} `, '')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              )}
              {/* <Col span={12}>
                <Form.Item
                  name="pauseAfter"
                  rules={[{ required: true, message: t('Required field') }]}
                  label={t('Pause after')}
                >
                  <TimePicker
                    style={{ width: '100%' }}
                    minuteStep={10}
                    format={'HH:mm'}
                    onSelect={(value) => {
                      form.setFieldsValue({
                        [`pauseAfter`]: value,
                      });
                    }}
                  />
                </Form.Item>
              </Col> */}
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="category"
                  label={
                    <Tooltip placement="topLeft" title={t('You can choose or add your own.')}>
                      <span>
                        {t('Category')} <InfoCircleOutlined style={{ color: '#1890ff' }} />
                      </span>
                    </Tooltip>
                  }
                >
                  <Select
                    placeholder={t('Select category')}
                    onChange={(val: string[]) =>
                      form.setFieldsValue({
                        category: val.length > 0 ? [val[val.length - 1]] : undefined,
                      })
                    }
                    mode="tags"
                    maxTagCount={1}
                    style={{ width: '100%' }}
                  >
                    {categories?.length
                      ? categories.map((category, index) => (
                          <Select.Option key={index} value={category.name}>
                            {category.name}
                          </Select.Option>
                        ))
                      : categoriesNames.map((category) => (
                          <Select.Option key={category} value={category}>
                            {t(category)}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="description" label={t('Description')}>
              <Input.TextArea rows={3} />
            </Form.Item>

            {!service ? (
              <div>
                <Form.Item name="assignToAll" valuePropName="checked">
                  <Checkbox onChange={(event) => setViewAddMasterInput(!event.target.checked)}>
                    {t('Link to all employees')}
                  </Checkbox>
                </Form.Item>

                {viewAddMasterInput && (
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item name="masters" label={t('Select employees')}>
                        <Select
                          mode="multiple"
                          optionLabelProp="label"
                          placeholder={t('Select employees')}
                          style={{ width: '100%' }}
                        >
                          {masters?.map((master) => (
                            <Select.Option key={master._id} value={master._id} label={master.name}>
                              {master.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </div>
            ) : null}

            <Form.Item name="active" valuePropName="checked">
              <Checkbox>{t('Active')}</Checkbox>
            </Form.Item>

            <RightRow>
              <Button loading={loading} htmlType="submit" type="primary">
                {t(service ? 'Update' : 'Create')}
              </Button>
            </RightRow>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default NewService;

const RightRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const RowLabel = styled.label`
  margin-bottom: 9px;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
`;

export const RowLabelDot = styled.span`
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
`;
