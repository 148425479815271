import { DiscountActionTypes } from './types';
import { takeEvery, put } from 'redux-saga/effects';
import {
  createDiscountSuccess,
  deleteDiscountSuccess,
  getDiscountsSuccess,
  onDiscountRequestFailure,
  updateDiscountSuccess,
} from './actions';
import DiscountService from '../../services/discount-service';
import { IDiscountInput, IDiscountUpdateInput } from '../../models/IDiscount';
import { IQuery } from '../../models/IQuery';

const discountService = new DiscountService();

//TODO catch response fail
function* createDiscountWorker(action: { type: string; payload: { discount: IDiscountInput; locationId: string } }): Generator<any, any, any> {
  try {
    const response = yield discountService.createDiscount(action.payload.discount, action.payload.locationId);
    yield put(createDiscountSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onDiscountRequestFailure((e as { message: string }).message));
  }
}

function* getDiscountsWorker(action: {
  type: string;
  payload: { locationId: string; pagination: IQuery; filterBy?: any };
}): Generator<any, any, any> {
  try {
    const response = yield discountService.getDiscounts(
      action.payload.locationId,
      action.payload.pagination,
      action.payload.filterBy
    );
    yield put(getDiscountsSuccess(response.data.discounts, response.data.total));
  } catch (e) {
    console.log(e);
    yield put(onDiscountRequestFailure((e as { message: string }).message));
  }
}

function* updateDiscountsWorker(action: {
  type: string;
  payload: { discount: IDiscountUpdateInput; discountId: string };
}): Generator<any, any, any> {
  try {
    const response = yield discountService.updateDiscount(action.payload.discount, action.payload.discountId);
    yield put(updateDiscountSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onDiscountRequestFailure((e as { message: string }).message));
  }
}

function* deleteDiscountsWorker(action: { type: string; payload: { discountId: string; locationId: string } }): Generator<any, any, any> {
  try {
    yield discountService.deleteDiscount(action.payload.discountId, action.payload.discountId);
    yield put(deleteDiscountSuccess(action.payload.discountId));
  } catch (e) {
    console.log(e);
    yield put(onDiscountRequestFailure((e as { message: string }).message));
  }
}

export function* watchDiscount() {
  yield takeEvery(DiscountActionTypes.CREATE_DISCOUNT_REQUEST, createDiscountWorker);
  yield takeEvery(DiscountActionTypes.GET_DISCOUNT_REQUEST, getDiscountsWorker);
  yield takeEvery(DiscountActionTypes.UPDATE_DISCOUNT_REQUEST, updateDiscountsWorker);
  yield takeEvery(DiscountActionTypes.DELETE_DISCOUNT_REQUEST, deleteDiscountsWorker);
}

const discountWatchers: any = [watchDiscount()];

export default discountWatchers;
