import { Avatar, Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { ResourceHeaderProps } from 'react-big-calendar';
import { Link } from 'react-router-dom';
import { IMaster } from '../../../models/IUser';
import './CalendarResourceHeader.css';

export const ResourceCalendarHeader: React.FC<ResourceHeaderProps> = props => {
  const master = useMemo(() => props.resource as Pick<IMaster, '_id' | 'name' | 'specialization' | 'avatarUrl'>, [props.resource]);

  return (
    <Row className="calendar-resource-header">
      <Col span={24}>
        <Link to={`/employees/${master._id}`}>
          <Avatar src={master.avatarUrl} size={36} />
        </Link>
      </Col>
      <Col span={24} className="master-name">{master.name}</Col>
      <Col span={24} className="master-specialization">{master.specialization}</Col>
    </Row>
  );
};
