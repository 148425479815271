export const BUTTON_STYLES = [
  {
    label: 'Shining',
    name: 'shining',
    defaultColors: ['#fff', '#03a9f4', '#8341f4', '#673ab7'],
  },
  {
    label: 'Pulsing',
    name: 'pulsing',
    defaultColors: ['#fff', '#ff3466'],
  },
  {
    label: 'Sliding',
    name: 'sliding',
    defaultColors: ['#fff', '#fac302', '#ff8722'],
  },
];
