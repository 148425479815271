import React, { useEffect, useMemo, useState } from 'react';
import {
  Form,
  Button,
  Col,
  Row,
  Card,
  Input,
  Select,
  Upload,
  message,
  Divider,
  InputNumber,
  Popover,
  Tooltip,
} from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { weekDays } from '../../const';
import { formWithWorkingDaysToCleanObject, workingHoursToFormData, compactObject } from '../../services/utils';
import { ILocationUpdateInput } from '../../models/ILocation';
import { useTranslation } from 'react-i18next';
import { updateLocationRequest } from '../../store/location/actions';
import ImgCrop from 'antd-img-crop';
import { InfoCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import currencies from '../../const/currencies';
import { Autocomplete, GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { env } from '../../const/env';
import { APP_LANG } from '../../const';
import { TimeSlots } from '../../components/TimeSlots/TimeSlots';
import countriesEn from '../../const/countries';
import countriesRu from '../../const/countries.ru';
import countriesUk from '../../const/countries.uk';
import { capitalize } from 'lodash';
import { fetchCategories } from '../../services/categories.service';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { timezones } from '../../const/timezones';
import moment from 'moment';

const containerStyle = {
  width: '100%',
  height: '300px',
};

let center = {
  lat: 52,
  lng: 24,
};

let mapZoom = 4;

type Props = {};

export const MainSettings: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [coordinate, setCoordinate] = useState({} as any);

  const [addressAutocomplete, setAddressAutoComplete] = useState<any>(null);
  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const { isLoaded: isGoogleMapsScriptLoaded } = useLoadScript({
    googleMapsApiKey: env.googleMapKey,
    libraries: googleMapsScriptLibraries,
  });

  const [currenciesArr, setCurrenciesArr] = useState(currencies);
  const [categories, setCategories] = useState<any[]>();

  const [state, setState] = useState({
    loading: false,
    avatarUrl: '',
  } as any);

  const selectedLang: any = localStorage.getItem(APP_LANG) || 'en';

  const countries: any[] = useMemo(() =>
    !selectedLang || selectedLang === 'en' ? countriesEn : selectedLang === 'ru' ? countriesRu : countriesUk, [selectedLang]);

  const { location, userId } = useSelector((state: IApplicationState) => ({
    location: state.location.currentLocation,
    userId: state.auth.user?._id,
  }));

  const callCategoriesApi = async () => {
    try {
      const categories: any = await fetchCategories(userId!);
      setCategories(categories.data.categories);
    } catch (error) {
      throw error;
    }
  };
  const getCategories = () => {
    callCategoriesApi();
  };
  useEffect(() => {
    getCategories();
    if (location) {
      form.setFieldsValue({
        ...workingHoursToFormData(location.workingHours),
        widgetStyle: location.widgetStyle,
        name: location.name,
        currency: location.currency,
        category: location.category,
        notAfter: location.notAfter,
        first_day_of_week: location.first_day_of_week,
        address: location.address,
        country: location.country,
        city: location.city,
        utcOffset: location.utcOffset || moment().utcOffset(),
        timeFormat: location.is12H ? 12 : 24,
      });

      setSelectedAddress(location.address);

      if (location.coordinate) {
        setCoordinate(location.coordinate);
      } else {
        setCoordinate({});
      }

      setState({ ...state, avatarUrl: location.avatarUrl });
    }
    // eslint-disable-next-line
  }, [location]);

  const submitForm = () => {
    const cleanData = { coordinate };

    const _values: any = compactObject({ ...form.getFieldsValue() });
    const _formatedWorkingHours: any = {};
    for (const day of weekDays) {
      if (_values[`${day}-from-start`] && _values[`${day}-from-end`]) {
        const _fromStart = _values[`${day}-from-start`];
        const _fromEnd = _values[`${day}-from-end`];
        _formatedWorkingHours[`${day}-from`] = [_fromStart, _fromEnd];
      }
      if (_values[`${day}-to-start`] && _values[`${day}-to-end`]) {
        const toStart = _values[`${day}-to-start`];
        const toEnd = _values[`${day}-to-end`];
        _formatedWorkingHours[`${day}-to`] = [toStart, toEnd];
      }
    }

    const format_first_day_of_week = _values.first_day_of_week ? { first_day_of_week: _values.first_day_of_week } : {};

    formWithWorkingDaysToCleanObject(cleanData, {
      name: _values.name,
      notAfter: _values.notAfter,
      category: _values.category,
      currency: _values.currency,
      country: _values.country,
      city: _values.city,
      address: _values.address,
      ...format_first_day_of_week,
      ..._formatedWorkingHours,
      utcOffset: _values.utcOffset,
      is12H: _values.timeFormat === 12,
    });

    dispatch(updateLocationRequest(cleanData as ILocationUpdateInput, location?._id as string));
  };

  const handleAvatarChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setState({ ...state, loading: true, avatarUrl: '' });
      return;
    }
    if (info.file.status === 'done') {
      dispatch(
        updateLocationRequest(
          { avatarUrl: info.file.response.avatarUrl } as ILocationUpdateInput,
          location?._id as string
        )
      );
      setState({
        avatarUrl: info.file.response.avatarUrl,
        loading: false,
      });
    }
    if (info.file.status === 'error') {
      message.error('Cant upload avatar image');
      setState({ ...state, avatarUrl: '', loading: false });
    }
  };

  const uploadButton = (
    <div>
      {state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">{t('Upload')}</div>
    </div>
  );

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  if (typeof coordinate.lat === 'number' && typeof coordinate.lng === 'number') {
    center = {
      lat: coordinate.lat,
      lng: coordinate.lng,
    };
    mapZoom = 10;
  } else {
    center = {
      lat: 52,
      lng: 24,
    };
    mapZoom = 4;
  }

  const onSearchCurrencies = (ev: any) => {
    if (ev === null || ev === '') {
      setCurrenciesArr(currencies);
    } else {
      setCurrenciesArr(currencies.filter((c) => c.name.toLowerCase().includes(ev.toLowerCase())));
    }
  };

  const handlePlaceChange = () => {
    if (addressAutocomplete === null) {
      console.log('Autocomplete is not loaded yet!');
      return;
    }
    const place = addressAutocomplete.getPlace();
    form.setFieldsValue({ address: place.formatted_address });
    setSelectedAddress(place.formatted_address);
    setCoordinate({
      lat: place.geometry?.location?.lat(),
      lng: place.geometry?.location?.lng(),
    });
  };

  return (
    <Wrapper>
      <Form layout="vertical" hideRequiredMark form={form} onFinish={submitForm}>
        <Row gutter={16}>
          <ImgCrop rotate modalTitle={t('Edit image')}>
            <Upload
              name="location"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${env.uploadUrl}/upload-location`}
              beforeUpload={beforeUpload}
              onChange={handleAvatarChange}
            >
              {state.avatarUrl !== '' ? (
                <img src={state.avatarUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </ImgCrop>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="name" label={t('Company name')}>
              <Input placeholder={t('Enter company name')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="notAfter"
              label={
                <Popover placement="topLeft" content={<div>{t('Disable making reservations after N days from today.')}</div>} trigger="click">
                  <p style={{ margin: '0px 0px 10px 10px' }}>
                    {t('Booking no further than')} <InfoCircleOutlined style={{ color: '#1890ff' }} />
                  </p>
                </Popover>
              }
            >
              <InputNumber min={1} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="category"
              label={
                <Tooltip placement="topLeft" title={t('You can choose or add your own.')}>
                  <span>
                    {t('Category')} <InfoCircleOutlined style={{ color: '#1890ff' }} />
                  </span>
                </Tooltip>
              }
              rules={[{ required: true, message: t('Required field') }]}
            >
              <Select
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                mode="tags"
                placeholder={t('Select categories')}
                style={{ width: '100%' }}
              >
                {categories?.length ? (
                  categories.map((category: any, index: any) => (
                    <Select.Option key={index} value={category.value}>
                      {!selectedLang || selectedLang === 'en'
                        ? category.en
                        : selectedLang === 'ru'
                        ? category.ru
                        : selectedLang === 'uk'
                        ? category.uk
                        : category.en}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option value="">
                    No Option
                  </Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="currency" label={t('Currency')}>
              <Select
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        padding: 8,
                      }}
                    >
                      <Input
                        className={'no-arrows-input'}
                        style={{ flex: 'auto' }}
                        onChange={(e) => onSearchCurrencies(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                style={{ alignItems: 'center' }}
              >
                {currenciesArr.map((currency, index) => (
                  <Select.Option key={index} value={currency.symbol}>
                    {currency.name} ( {currency.symbol} )
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="first_day_of_week"
              rules={[{ required: true, message: t('Required field') }]}
              label={t('First Day of Week')}
            >
              <Select style={{ width: '100%' }}>
                {weekDays.map((day, index) => (
                  <Select.Option key={index} value={day}>
                    {t(capitalize(day))}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t('Time zone')}
              name="utcOffset"
              rules={[{ required: true, message: t('Required field') }]}
            >
              <Select placeholder={t('Select a time zone')} showSearch className="w-full">
                {timezones.map(timezone => (
                  <Select.Option value={timezone.offset} key={timezone.offset}>
                    {timezone.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('Time format')}
              name="timeFormat"
              rules={[{ required: true, message: t('Required field') }]}
            >
              <Select className="w-full">
                <Select.Option value={12}>{t('12 hours')}</Select.Option>
                <Select.Option value={24}>{t('24 hours')}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className={'ant-col ant-form-item-label'}>
          <label>{t('Working hours')}</label>
        </div>
        <Col span={24} style={{ marginBottom: 24 }}>
          <TimeSlots form={form} />
        </Col>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              id="country_select_ant"
              label={t('Country')}
              name="country"
              rules={[{ required: true, message: t('Required field') }]}
            >
              <Select placeholder={t('Select a country')} showSearch style={{ width: '100%' }}>
                {countries.map(country => (
                  <Select.Option value={country.name} key={country.name}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('City')} name="city" rules={[{ required: true, message: t('Required field') }]}>
              <Input placeholder={t('Enter a city')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('Address')} name="address" rules={[{ required: true, message: t('Required field') }]}>
              <>
                {isGoogleMapsScriptLoaded && (
                  <Autocomplete
                    onLoad={(autocomplete: any) => setAddressAutoComplete(autocomplete)}
                    onPlaceChanged={handlePlaceChange}
                  >
                    <Input
                      type="text"
                      value={selectedAddress}
                      onChange={event => setSelectedAddress(event.target.value)}
                    />
                  </Autocomplete>
                )}
              </>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            {isGoogleMapsScriptLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                onClick={(ev: any) => {
                  setCoordinate({
                    lat: ev.latLng.lat(),
                    lng: ev.latLng.lng(),
                  });
                }}
                center={center}
                zoom={mapZoom}
              >
                {typeof coordinate.lat === 'number' && typeof coordinate.lng === 'number' && (
                  <Marker position={coordinate} />
                )}
                <></>
              </GoogleMap>
            )}
          </Col>
        </Row>

        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button type="primary" htmlType="submit">
            {t('Save')}
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled(Card)`
  margin: 0 auto;
  display: block;
  max-width: 800px;
`;

const googleMapsScriptLibraries: Libraries = ['places'];
