import React from 'react';
import { minTableCellWidth } from '../../const';
import { IService } from '../../models/IService';
import { UsergroupAddOutlined } from '@ant-design/icons';

export const ANALYTICS_EMPLOYEE_COLUMNS = (t: any): any => [
  {
    title: t('Name'),
    dataIndex: 'name',
    width: minTableCellWidth,
  },
  {
    title: t('Total reservations'),
    dataIndex: 'totalReservations',
    width: minTableCellWidth,
    sorter: (a: any, b: any) => a.totalReservations - b.totalReservations,
  },
  {
    title: t('Total money earned'),
    dataIndex: 'earnMoney',
    width: minTableCellWidth,
    render: (money: number) => parseFloat(`${money}`).toFixed(2),
    sorter: (a: any, b: any) => a.earnMoney - b.earnMoney,
  },

  // {
  //   title: (
  //     <span>
  //       {t('Total reservations')}
  //       <UsergroupAddOutlined />
  //     </span>
  //   ),
  //   dataIndex: 'totalGroupReservations',
  //   width: minTableCellWidth,
  //   sorter: (a: any, b: any) => a.totalReservations - b.totalReservations,
  // },
  // {
  //   title: (
  //     <span>
  //       {t('Total money earned')} <UsergroupAddOutlined />
  //     </span>
  //   ),
  //   dataIndex: 'earnMoneyGroup',
  //   width: minTableCellWidth,
  //   render: (money: number) => parseFloat(`${money}`).toFixed(2),
  //   sorter: (a: any, b: any) => a.earnMoney - b.earnMoney,
  // },
];

export const ANALYTICS_MAIN_COLUMNS = (t: any): any => [
  {
    title: t('Name'),
    dataIndex: 'name',
    width: minTableCellWidth,
  },
  {
    title: t('Total reservations'),
    dataIndex: 'totalReservations',
    width: minTableCellWidth,
  },
  {
    title: t('Total money earned'),
    dataIndex: 'earnMoney',
    width: minTableCellWidth,
    render: (money: number) => parseFloat(`${money}`).toFixed(2),
  },
];

export const ANALYTICS_MAIN_SERVICE_COLUMNS = (t: any): any => [
  {
    title: t('Name'),
    dataIndex: 'service',
    width: minTableCellWidth,
    render: (service: IService) => service.name,
  },
  {
    title: t('Total reservations'),
    dataIndex: 'count',
    width: minTableCellWidth,
  },
];

export const ANALYTICS_SERVICE_COLUMNS = (t: any): any => [
  {
    title: t('Name'),
    dataIndex: 'service',
    width: minTableCellWidth,
    render: (service: any) => (service && service.name ? service.name : ''),
  },
  {
    title: t('Total reservations'),
    dataIndex: 'count',
    width: minTableCellWidth,
    sorter: (a: any, b: any) => a.count - b.count,
  },
];
